export default {  'a-b-2': () => import('./icons/IconAB2'),
  'a-b-off': () => import('./icons/IconABOff'),
  'a-b': () => import('./icons/IconAB'),
  'abacus-off': () => import('./icons/IconAbacusOff'),
  'abacus': () => import('./icons/IconAbacus'),
  'abc': () => import('./icons/IconAbc'),
  'access-point-off': () => import('./icons/IconAccessPointOff'),
  'access-point': () => import('./icons/IconAccessPoint'),
  'accessible-off': () => import('./icons/IconAccessibleOff'),
  'accessible': () => import('./icons/IconAccessible'),
  'activity-heartbeat': () => import('./icons/IconActivityHeartbeat'),
  'activity': () => import('./icons/IconActivity'),
  'ad-2': () => import('./icons/IconAd2'),
  'ad-circle-off': () => import('./icons/IconAdCircleOff'),
  'ad-circle': () => import('./icons/IconAdCircle'),
  'ad-off': () => import('./icons/IconAdOff'),
  'ad': () => import('./icons/IconAd'),
  'address-book-off': () => import('./icons/IconAddressBookOff'),
  'address-book': () => import('./icons/IconAddressBook'),
  'adjustments-alt': () => import('./icons/IconAdjustmentsAlt'),
  'adjustments-bolt': () => import('./icons/IconAdjustmentsBolt'),
  'adjustments-cancel': () => import('./icons/IconAdjustmentsCancel'),
  'adjustments-check': () => import('./icons/IconAdjustmentsCheck'),
  'adjustments-code': () => import('./icons/IconAdjustmentsCode'),
  'adjustments-cog': () => import('./icons/IconAdjustmentsCog'),
  'adjustments-dollar': () => import('./icons/IconAdjustmentsDollar'),
  'adjustments-down': () => import('./icons/IconAdjustmentsDown'),
  'adjustments-exclamation': () => import('./icons/IconAdjustmentsExclamation'),
  'adjustments-heart': () => import('./icons/IconAdjustmentsHeart'),
  'adjustments-horizontal': () => import('./icons/IconAdjustmentsHorizontal'),
  'adjustments-minus': () => import('./icons/IconAdjustmentsMinus'),
  'adjustments-off': () => import('./icons/IconAdjustmentsOff'),
  'adjustments-pause': () => import('./icons/IconAdjustmentsPause'),
  'adjustments-pin': () => import('./icons/IconAdjustmentsPin'),
  'adjustments-plus': () => import('./icons/IconAdjustmentsPlus'),
  'adjustments-question': () => import('./icons/IconAdjustmentsQuestion'),
  'adjustments-search': () => import('./icons/IconAdjustmentsSearch'),
  'adjustments-share': () => import('./icons/IconAdjustmentsShare'),
  'adjustments-spark': () => import('./icons/IconAdjustmentsSpark'),
  'adjustments-star': () => import('./icons/IconAdjustmentsStar'),
  'adjustments-up': () => import('./icons/IconAdjustmentsUp'),
  'adjustments-x': () => import('./icons/IconAdjustmentsX'),
  'adjustments': () => import('./icons/IconAdjustments'),
  'aerial-lift': () => import('./icons/IconAerialLift'),
  'affiliate': () => import('./icons/IconAffiliate'),
  'ai': () => import('./icons/IconAi'),
  'air-balloon': () => import('./icons/IconAirBalloon'),
  'air-conditioning-disabled': () => import('./icons/IconAirConditioningDisabled'),
  'air-conditioning': () => import('./icons/IconAirConditioning'),
  'air-traffic-control': () => import('./icons/IconAirTrafficControl'),
  'alarm-average': () => import('./icons/IconAlarmAverage'),
  'alarm-minus': () => import('./icons/IconAlarmMinus'),
  'alarm-off': () => import('./icons/IconAlarmOff'),
  'alarm-plus': () => import('./icons/IconAlarmPlus'),
  'alarm-snooze': () => import('./icons/IconAlarmSnooze'),
  'alarm': () => import('./icons/IconAlarm'),
  'album-off': () => import('./icons/IconAlbumOff'),
  'album': () => import('./icons/IconAlbum'),
  'alert-circle-off': () => import('./icons/IconAlertCircleOff'),
  'alert-circle': () => import('./icons/IconAlertCircle'),
  'alert-hexagon-off': () => import('./icons/IconAlertHexagonOff'),
  'alert-hexagon': () => import('./icons/IconAlertHexagon'),
  'alert-octagon': () => import('./icons/IconAlertOctagon'),
  'alert-small-off': () => import('./icons/IconAlertSmallOff'),
  'alert-small': () => import('./icons/IconAlertSmall'),
  'alert-square-rounded-off': () => import('./icons/IconAlertSquareRoundedOff'),
  'alert-square-rounded': () => import('./icons/IconAlertSquareRounded'),
  'alert-square': () => import('./icons/IconAlertSquare'),
  'alert-triangle-off': () => import('./icons/IconAlertTriangleOff'),
  'alert-triangle': () => import('./icons/IconAlertTriangle'),
  'alien': () => import('./icons/IconAlien'),
  'align-box-bottom-center': () => import('./icons/IconAlignBoxBottomCenter'),
  'align-box-bottom-left': () => import('./icons/IconAlignBoxBottomLeft'),
  'align-box-bottom-right': () => import('./icons/IconAlignBoxBottomRight'),
  'align-box-center-bottom': () => import('./icons/IconAlignBoxCenterBottom'),
  'align-box-center-middle': () => import('./icons/IconAlignBoxCenterMiddle'),
  'align-box-center-stretch': () => import('./icons/IconAlignBoxCenterStretch'),
  'align-box-center-top': () => import('./icons/IconAlignBoxCenterTop'),
  'align-box-left-bottom': () => import('./icons/IconAlignBoxLeftBottom'),
  'align-box-left-middle': () => import('./icons/IconAlignBoxLeftMiddle'),
  'align-box-left-stretch': () => import('./icons/IconAlignBoxLeftStretch'),
  'align-box-left-top': () => import('./icons/IconAlignBoxLeftTop'),
  'align-box-right-bottom': () => import('./icons/IconAlignBoxRightBottom'),
  'align-box-right-middle': () => import('./icons/IconAlignBoxRightMiddle'),
  'align-box-right-stretch': () => import('./icons/IconAlignBoxRightStretch'),
  'align-box-right-top': () => import('./icons/IconAlignBoxRightTop'),
  'align-box-top-center': () => import('./icons/IconAlignBoxTopCenter'),
  'align-box-top-left': () => import('./icons/IconAlignBoxTopLeft'),
  'align-box-top-right': () => import('./icons/IconAlignBoxTopRight'),
  'align-center': () => import('./icons/IconAlignCenter'),
  'align-justified': () => import('./icons/IconAlignJustified'),
  'align-left-2': () => import('./icons/IconAlignLeft2'),
  'align-left': () => import('./icons/IconAlignLeft'),
  'align-right-2': () => import('./icons/IconAlignRight2'),
  'align-right': () => import('./icons/IconAlignRight'),
  'alpha': () => import('./icons/IconAlpha'),
  'alphabet-arabic': () => import('./icons/IconAlphabetArabic'),
  'alphabet-bangla': () => import('./icons/IconAlphabetBangla'),
  'alphabet-cyrillic': () => import('./icons/IconAlphabetCyrillic'),
  'alphabet-greek': () => import('./icons/IconAlphabetGreek'),
  'alphabet-hebrew': () => import('./icons/IconAlphabetHebrew'),
  'alphabet-korean': () => import('./icons/IconAlphabetKorean'),
  'alphabet-latin': () => import('./icons/IconAlphabetLatin'),
  'alphabet-thai': () => import('./icons/IconAlphabetThai'),
  'alt': () => import('./icons/IconAlt'),
  'ambulance': () => import('./icons/IconAmbulance'),
  'ampersand': () => import('./icons/IconAmpersand'),
  'analyze-off': () => import('./icons/IconAnalyzeOff'),
  'analyze': () => import('./icons/IconAnalyze'),
  'anchor-off': () => import('./icons/IconAnchorOff'),
  'anchor': () => import('./icons/IconAnchor'),
  'angle': () => import('./icons/IconAngle'),
  'ankh': () => import('./icons/IconAnkh'),
  'antenna-bars-1': () => import('./icons/IconAntennaBars1'),
  'antenna-bars-2': () => import('./icons/IconAntennaBars2'),
  'antenna-bars-3': () => import('./icons/IconAntennaBars3'),
  'antenna-bars-4': () => import('./icons/IconAntennaBars4'),
  'antenna-bars-5': () => import('./icons/IconAntennaBars5'),
  'antenna-bars-off': () => import('./icons/IconAntennaBarsOff'),
  'antenna-off': () => import('./icons/IconAntennaOff'),
  'antenna': () => import('./icons/IconAntenna'),
  'aperture-off': () => import('./icons/IconApertureOff'),
  'aperture': () => import('./icons/IconAperture'),
  'api-app-off': () => import('./icons/IconApiAppOff'),
  'api-app': () => import('./icons/IconApiApp'),
  'api-off': () => import('./icons/IconApiOff'),
  'api': () => import('./icons/IconApi'),
  'app-window': () => import('./icons/IconAppWindow'),
  'apple': () => import('./icons/IconApple'),
  'apps-off': () => import('./icons/IconAppsOff'),
  'apps': () => import('./icons/IconApps'),
  'archery-arrow': () => import('./icons/IconArcheryArrow'),
  'archive-off': () => import('./icons/IconArchiveOff'),
  'archive': () => import('./icons/IconArchive'),
  'armchair-2-off': () => import('./icons/IconArmchair2Off'),
  'armchair-2': () => import('./icons/IconArmchair2'),
  'armchair-off': () => import('./icons/IconArmchairOff'),
  'armchair': () => import('./icons/IconArmchair'),
  'arrow-autofit-content': () => import('./icons/IconArrowAutofitContent'),
  'arrow-autofit-down': () => import('./icons/IconArrowAutofitDown'),
  'arrow-autofit-height': () => import('./icons/IconArrowAutofitHeight'),
  'arrow-autofit-left': () => import('./icons/IconArrowAutofitLeft'),
  'arrow-autofit-right': () => import('./icons/IconArrowAutofitRight'),
  'arrow-autofit-up': () => import('./icons/IconArrowAutofitUp'),
  'arrow-autofit-width': () => import('./icons/IconArrowAutofitWidth'),
  'arrow-back-up-double': () => import('./icons/IconArrowBackUpDouble'),
  'arrow-back-up': () => import('./icons/IconArrowBackUp'),
  'arrow-back': () => import('./icons/IconArrowBack'),
  'arrow-badge-down': () => import('./icons/IconArrowBadgeDown'),
  'arrow-badge-left': () => import('./icons/IconArrowBadgeLeft'),
  'arrow-badge-right': () => import('./icons/IconArrowBadgeRight'),
  'arrow-badge-up': () => import('./icons/IconArrowBadgeUp'),
  'arrow-bar-both': () => import('./icons/IconArrowBarBoth'),
  'arrow-bar-down': () => import('./icons/IconArrowBarDown'),
  'arrow-bar-left': () => import('./icons/IconArrowBarLeft'),
  'arrow-bar-right': () => import('./icons/IconArrowBarRight'),
  'arrow-bar-to-down': () => import('./icons/IconArrowBarToDown'),
  'arrow-bar-to-left': () => import('./icons/IconArrowBarToLeft'),
  'arrow-bar-to-right': () => import('./icons/IconArrowBarToRight'),
  'arrow-bar-to-up': () => import('./icons/IconArrowBarToUp'),
  'arrow-bar-up': () => import('./icons/IconArrowBarUp'),
  'arrow-bear-left-2': () => import('./icons/IconArrowBearLeft2'),
  'arrow-bear-left': () => import('./icons/IconArrowBearLeft'),
  'arrow-bear-right-2': () => import('./icons/IconArrowBearRight2'),
  'arrow-bear-right': () => import('./icons/IconArrowBearRight'),
  'arrow-big-down-line': () => import('./icons/IconArrowBigDownLine'),
  'arrow-big-down-lines': () => import('./icons/IconArrowBigDownLines'),
  'arrow-big-down': () => import('./icons/IconArrowBigDown'),
  'arrow-big-left-line': () => import('./icons/IconArrowBigLeftLine'),
  'arrow-big-left-lines': () => import('./icons/IconArrowBigLeftLines'),
  'arrow-big-left': () => import('./icons/IconArrowBigLeft'),
  'arrow-big-right-line': () => import('./icons/IconArrowBigRightLine'),
  'arrow-big-right-lines': () => import('./icons/IconArrowBigRightLines'),
  'arrow-big-right': () => import('./icons/IconArrowBigRight'),
  'arrow-big-up-line': () => import('./icons/IconArrowBigUpLine'),
  'arrow-big-up-lines': () => import('./icons/IconArrowBigUpLines'),
  'arrow-big-up': () => import('./icons/IconArrowBigUp'),
  'arrow-bounce': () => import('./icons/IconArrowBounce'),
  'arrow-capsule': () => import('./icons/IconArrowCapsule'),
  'arrow-curve-left': () => import('./icons/IconArrowCurveLeft'),
  'arrow-curve-right': () => import('./icons/IconArrowCurveRight'),
  'arrow-down-bar': () => import('./icons/IconArrowDownBar'),
  'arrow-down-circle': () => import('./icons/IconArrowDownCircle'),
  'arrow-down-dashed': () => import('./icons/IconArrowDownDashed'),
  'arrow-down-from-arc': () => import('./icons/IconArrowDownFromArc'),
  'arrow-down-left-circle': () => import('./icons/IconArrowDownLeftCircle'),
  'arrow-down-left': () => import('./icons/IconArrowDownLeft'),
  'arrow-down-rhombus': () => import('./icons/IconArrowDownRhombus'),
  'arrow-down-right-circle': () => import('./icons/IconArrowDownRightCircle'),
  'arrow-down-right': () => import('./icons/IconArrowDownRight'),
  'arrow-down-square': () => import('./icons/IconArrowDownSquare'),
  'arrow-down-tail': () => import('./icons/IconArrowDownTail'),
  'arrow-down-to-arc': () => import('./icons/IconArrowDownToArc'),
  'arrow-down': () => import('./icons/IconArrowDown'),
  'arrow-elbow-left': () => import('./icons/IconArrowElbowLeft'),
  'arrow-elbow-right': () => import('./icons/IconArrowElbowRight'),
  'arrow-fork': () => import('./icons/IconArrowFork'),
  'arrow-forward-up-double': () => import('./icons/IconArrowForwardUpDouble'),
  'arrow-forward-up': () => import('./icons/IconArrowForwardUp'),
  'arrow-forward': () => import('./icons/IconArrowForward'),
  'arrow-guide': () => import('./icons/IconArrowGuide'),
  'arrow-iteration': () => import('./icons/IconArrowIteration'),
  'arrow-left-bar': () => import('./icons/IconArrowLeftBar'),
  'arrow-left-circle': () => import('./icons/IconArrowLeftCircle'),
  'arrow-left-dashed': () => import('./icons/IconArrowLeftDashed'),
  'arrow-left-from-arc': () => import('./icons/IconArrowLeftFromArc'),
  'arrow-left-rhombus': () => import('./icons/IconArrowLeftRhombus'),
  'arrow-left-right': () => import('./icons/IconArrowLeftRight'),
  'arrow-left-square': () => import('./icons/IconArrowLeftSquare'),
  'arrow-left-tail': () => import('./icons/IconArrowLeftTail'),
  'arrow-left-to-arc': () => import('./icons/IconArrowLeftToArc'),
  'arrow-left': () => import('./icons/IconArrowLeft'),
  'arrow-loop-left-2': () => import('./icons/IconArrowLoopLeft2'),
  'arrow-loop-left': () => import('./icons/IconArrowLoopLeft'),
  'arrow-loop-right-2': () => import('./icons/IconArrowLoopRight2'),
  'arrow-loop-right': () => import('./icons/IconArrowLoopRight'),
  'arrow-merge-alt-left': () => import('./icons/IconArrowMergeAltLeft'),
  'arrow-merge-alt-right': () => import('./icons/IconArrowMergeAltRight'),
  'arrow-merge-both': () => import('./icons/IconArrowMergeBoth'),
  'arrow-merge-left': () => import('./icons/IconArrowMergeLeft'),
  'arrow-merge-right': () => import('./icons/IconArrowMergeRight'),
  'arrow-merge': () => import('./icons/IconArrowMerge'),
  'arrow-move-down': () => import('./icons/IconArrowMoveDown'),
  'arrow-move-left': () => import('./icons/IconArrowMoveLeft'),
  'arrow-move-right': () => import('./icons/IconArrowMoveRight'),
  'arrow-move-up': () => import('./icons/IconArrowMoveUp'),
  'arrow-narrow-down-dashed': () => import('./icons/IconArrowNarrowDownDashed'),
  'arrow-narrow-down': () => import('./icons/IconArrowNarrowDown'),
  'arrow-narrow-left-dashed': () => import('./icons/IconArrowNarrowLeftDashed'),
  'arrow-narrow-left': () => import('./icons/IconArrowNarrowLeft'),
  'arrow-narrow-right-dashed': () => import('./icons/IconArrowNarrowRightDashed'),
  'arrow-narrow-right': () => import('./icons/IconArrowNarrowRight'),
  'arrow-narrow-up-dashed': () => import('./icons/IconArrowNarrowUpDashed'),
  'arrow-narrow-up': () => import('./icons/IconArrowNarrowUp'),
  'arrow-ramp-left-2': () => import('./icons/IconArrowRampLeft2'),
  'arrow-ramp-left-3': () => import('./icons/IconArrowRampLeft3'),
  'arrow-ramp-left': () => import('./icons/IconArrowRampLeft'),
  'arrow-ramp-right-2': () => import('./icons/IconArrowRampRight2'),
  'arrow-ramp-right-3': () => import('./icons/IconArrowRampRight3'),
  'arrow-ramp-right': () => import('./icons/IconArrowRampRight'),
  'arrow-right-bar': () => import('./icons/IconArrowRightBar'),
  'arrow-right-circle': () => import('./icons/IconArrowRightCircle'),
  'arrow-right-dashed': () => import('./icons/IconArrowRightDashed'),
  'arrow-right-from-arc': () => import('./icons/IconArrowRightFromArc'),
  'arrow-right-rhombus': () => import('./icons/IconArrowRightRhombus'),
  'arrow-right-square': () => import('./icons/IconArrowRightSquare'),
  'arrow-right-tail': () => import('./icons/IconArrowRightTail'),
  'arrow-right-to-arc': () => import('./icons/IconArrowRightToArc'),
  'arrow-right': () => import('./icons/IconArrowRight'),
  'arrow-rotary-first-left': () => import('./icons/IconArrowRotaryFirstLeft'),
  'arrow-rotary-first-right': () => import('./icons/IconArrowRotaryFirstRight'),
  'arrow-rotary-last-left': () => import('./icons/IconArrowRotaryLastLeft'),
  'arrow-rotary-last-right': () => import('./icons/IconArrowRotaryLastRight'),
  'arrow-rotary-left': () => import('./icons/IconArrowRotaryLeft'),
  'arrow-rotary-right': () => import('./icons/IconArrowRotaryRight'),
  'arrow-rotary-straight': () => import('./icons/IconArrowRotaryStraight'),
  'arrow-roundabout-left': () => import('./icons/IconArrowRoundaboutLeft'),
  'arrow-roundabout-right': () => import('./icons/IconArrowRoundaboutRight'),
  'arrow-sharp-turn-left': () => import('./icons/IconArrowSharpTurnLeft'),
  'arrow-sharp-turn-right': () => import('./icons/IconArrowSharpTurnRight'),
  'arrow-up-bar': () => import('./icons/IconArrowUpBar'),
  'arrow-up-circle': () => import('./icons/IconArrowUpCircle'),
  'arrow-up-dashed': () => import('./icons/IconArrowUpDashed'),
  'arrow-up-from-arc': () => import('./icons/IconArrowUpFromArc'),
  'arrow-up-left-circle': () => import('./icons/IconArrowUpLeftCircle'),
  'arrow-up-left': () => import('./icons/IconArrowUpLeft'),
  'arrow-up-rhombus': () => import('./icons/IconArrowUpRhombus'),
  'arrow-up-right-circle': () => import('./icons/IconArrowUpRightCircle'),
  'arrow-up-right': () => import('./icons/IconArrowUpRight'),
  'arrow-up-square': () => import('./icons/IconArrowUpSquare'),
  'arrow-up-tail': () => import('./icons/IconArrowUpTail'),
  'arrow-up-to-arc': () => import('./icons/IconArrowUpToArc'),
  'arrow-up': () => import('./icons/IconArrowUp'),
  'arrow-wave-left-down': () => import('./icons/IconArrowWaveLeftDown'),
  'arrow-wave-left-up': () => import('./icons/IconArrowWaveLeftUp'),
  'arrow-wave-right-down': () => import('./icons/IconArrowWaveRightDown'),
  'arrow-wave-right-up': () => import('./icons/IconArrowWaveRightUp'),
  'arrow-zig-zag': () => import('./icons/IconArrowZigZag'),
  'arrows-cross': () => import('./icons/IconArrowsCross'),
  'arrows-diagonal-2': () => import('./icons/IconArrowsDiagonal2'),
  'arrows-diagonal-minimize-2': () => import('./icons/IconArrowsDiagonalMinimize2'),
  'arrows-diagonal-minimize': () => import('./icons/IconArrowsDiagonalMinimize'),
  'arrows-diagonal': () => import('./icons/IconArrowsDiagonal'),
  'arrows-diff': () => import('./icons/IconArrowsDiff'),
  'arrows-double-ne-sw': () => import('./icons/IconArrowsDoubleNeSw'),
  'arrows-double-nw-se': () => import('./icons/IconArrowsDoubleNwSe'),
  'arrows-double-se-nw': () => import('./icons/IconArrowsDoubleSeNw'),
  'arrows-double-sw-ne': () => import('./icons/IconArrowsDoubleSwNe'),
  'arrows-down-up': () => import('./icons/IconArrowsDownUp'),
  'arrows-down': () => import('./icons/IconArrowsDown'),
  'arrows-exchange-2': () => import('./icons/IconArrowsExchange2'),
  'arrows-exchange': () => import('./icons/IconArrowsExchange'),
  'arrows-horizontal': () => import('./icons/IconArrowsHorizontal'),
  'arrows-join-2': () => import('./icons/IconArrowsJoin2'),
  'arrows-join': () => import('./icons/IconArrowsJoin'),
  'arrows-left-down': () => import('./icons/IconArrowsLeftDown'),
  'arrows-left-right': () => import('./icons/IconArrowsLeftRight'),
  'arrows-left': () => import('./icons/IconArrowsLeft'),
  'arrows-maximize': () => import('./icons/IconArrowsMaximize'),
  'arrows-minimize': () => import('./icons/IconArrowsMinimize'),
  'arrows-move-horizontal': () => import('./icons/IconArrowsMoveHorizontal'),
  'arrows-move-vertical': () => import('./icons/IconArrowsMoveVertical'),
  'arrows-move': () => import('./icons/IconArrowsMove'),
  'arrows-random': () => import('./icons/IconArrowsRandom'),
  'arrows-right-down': () => import('./icons/IconArrowsRightDown'),
  'arrows-right-left': () => import('./icons/IconArrowsRightLeft'),
  'arrows-right': () => import('./icons/IconArrowsRight'),
  'arrows-shuffle-2': () => import('./icons/IconArrowsShuffle2'),
  'arrows-shuffle': () => import('./icons/IconArrowsShuffle'),
  'arrows-sort': () => import('./icons/IconArrowsSort'),
  'arrows-split-2': () => import('./icons/IconArrowsSplit2'),
  'arrows-split': () => import('./icons/IconArrowsSplit'),
  'arrows-transfer-down': () => import('./icons/IconArrowsTransferDown'),
  'arrows-transfer-up-down': () => import('./icons/IconArrowsTransferUpDown'),
  'arrows-transfer-up': () => import('./icons/IconArrowsTransferUp'),
  'arrows-up-down': () => import('./icons/IconArrowsUpDown'),
  'arrows-up-left': () => import('./icons/IconArrowsUpLeft'),
  'arrows-up-right': () => import('./icons/IconArrowsUpRight'),
  'arrows-up': () => import('./icons/IconArrowsUp'),
  'arrows-vertical': () => import('./icons/IconArrowsVertical'),
  'artboard-off': () => import('./icons/IconArtboardOff'),
  'artboard': () => import('./icons/IconArtboard'),
  'article-off': () => import('./icons/IconArticleOff'),
  'article': () => import('./icons/IconArticle'),
  'aspect-ratio-off': () => import('./icons/IconAspectRatioOff'),
  'aspect-ratio': () => import('./icons/IconAspectRatio'),
  'assembly-off': () => import('./icons/IconAssemblyOff'),
  'assembly': () => import('./icons/IconAssembly'),
  'asset': () => import('./icons/IconAsset'),
  'asterisk-simple': () => import('./icons/IconAsteriskSimple'),
  'asterisk': () => import('./icons/IconAsterisk'),
  'at-off': () => import('./icons/IconAtOff'),
  'at': () => import('./icons/IconAt'),
  'atom-2': () => import('./icons/IconAtom2'),
  'atom-off': () => import('./icons/IconAtomOff'),
  'atom': () => import('./icons/IconAtom'),
  'augmented-reality-2': () => import('./icons/IconAugmentedReality2'),
  'augmented-reality-off': () => import('./icons/IconAugmentedRealityOff'),
  'augmented-reality': () => import('./icons/IconAugmentedReality'),
  'auth-2fa': () => import('./icons/IconAuth2fa'),
  'automatic-gearbox': () => import('./icons/IconAutomaticGearbox'),
  'automation': () => import('./icons/IconAutomation'),
  'avocado': () => import('./icons/IconAvocado'),
  'award-off': () => import('./icons/IconAwardOff'),
  'award': () => import('./icons/IconAward'),
  'axe': () => import('./icons/IconAxe'),
  'axis-x': () => import('./icons/IconAxisX'),
  'axis-y': () => import('./icons/IconAxisY'),
  'baby-bottle': () => import('./icons/IconBabyBottle'),
  'baby-carriage': () => import('./icons/IconBabyCarriage'),
  'background': () => import('./icons/IconBackground'),
  'backhoe': () => import('./icons/IconBackhoe'),
  'backpack-off': () => import('./icons/IconBackpackOff'),
  'backpack': () => import('./icons/IconBackpack'),
  'backslash': () => import('./icons/IconBackslash'),
  'backspace': () => import('./icons/IconBackspace'),
  'badge-3d': () => import('./icons/IconBadge3d'),
  'badge-4k': () => import('./icons/IconBadge4k'),
  'badge-8k': () => import('./icons/IconBadge8k'),
  'badge-ad-off': () => import('./icons/IconBadgeAdOff'),
  'badge-ad': () => import('./icons/IconBadgeAd'),
  'badge-ar': () => import('./icons/IconBadgeAr'),
  'badge-cc': () => import('./icons/IconBadgeCc'),
  'badge-hd': () => import('./icons/IconBadgeHd'),
  'badge-off': () => import('./icons/IconBadgeOff'),
  'badge-sd': () => import('./icons/IconBadgeSd'),
  'badge-tm': () => import('./icons/IconBadgeTm'),
  'badge-vo': () => import('./icons/IconBadgeVo'),
  'badge-vr': () => import('./icons/IconBadgeVr'),
  'badge-wc': () => import('./icons/IconBadgeWc'),
  'badge': () => import('./icons/IconBadge'),
  'badges-off': () => import('./icons/IconBadgesOff'),
  'badges': () => import('./icons/IconBadges'),
  'baguette': () => import('./icons/IconBaguette'),
  'ball-american-football-off': () => import('./icons/IconBallAmericanFootballOff'),
  'ball-american-football': () => import('./icons/IconBallAmericanFootball'),
  'ball-baseball': () => import('./icons/IconBallBaseball'),
  'ball-basketball': () => import('./icons/IconBallBasketball'),
  'ball-bowling': () => import('./icons/IconBallBowling'),
  'ball-football-off': () => import('./icons/IconBallFootballOff'),
  'ball-football': () => import('./icons/IconBallFootball'),
  'ball-tennis': () => import('./icons/IconBallTennis'),
  'ball-volleyball': () => import('./icons/IconBallVolleyball'),
  'balloon-off': () => import('./icons/IconBalloonOff'),
  'balloon': () => import('./icons/IconBalloon'),
  'ballpen-off': () => import('./icons/IconBallpenOff'),
  'ballpen': () => import('./icons/IconBallpen'),
  'ban': () => import('./icons/IconBan'),
  'bandage-off': () => import('./icons/IconBandageOff'),
  'bandage': () => import('./icons/IconBandage'),
  'barbell-off': () => import('./icons/IconBarbellOff'),
  'barbell': () => import('./icons/IconBarbell'),
  'barcode-off': () => import('./icons/IconBarcodeOff'),
  'barcode': () => import('./icons/IconBarcode'),
  'barrel-off': () => import('./icons/IconBarrelOff'),
  'barrel': () => import('./icons/IconBarrel'),
  'barrier-block-off': () => import('./icons/IconBarrierBlockOff'),
  'barrier-block': () => import('./icons/IconBarrierBlock'),
  'baseline-density-large': () => import('./icons/IconBaselineDensityLarge'),
  'baseline-density-medium': () => import('./icons/IconBaselineDensityMedium'),
  'baseline-density-small': () => import('./icons/IconBaselineDensitySmall'),
  'baseline': () => import('./icons/IconBaseline'),
  'basket-bolt': () => import('./icons/IconBasketBolt'),
  'basket-cancel': () => import('./icons/IconBasketCancel'),
  'basket-check': () => import('./icons/IconBasketCheck'),
  'basket-code': () => import('./icons/IconBasketCode'),
  'basket-cog': () => import('./icons/IconBasketCog'),
  'basket-discount': () => import('./icons/IconBasketDiscount'),
  'basket-dollar': () => import('./icons/IconBasketDollar'),
  'basket-down': () => import('./icons/IconBasketDown'),
  'basket-exclamation': () => import('./icons/IconBasketExclamation'),
  'basket-heart': () => import('./icons/IconBasketHeart'),
  'basket-minus': () => import('./icons/IconBasketMinus'),
  'basket-off': () => import('./icons/IconBasketOff'),
  'basket-pause': () => import('./icons/IconBasketPause'),
  'basket-pin': () => import('./icons/IconBasketPin'),
  'basket-plus': () => import('./icons/IconBasketPlus'),
  'basket-question': () => import('./icons/IconBasketQuestion'),
  'basket-search': () => import('./icons/IconBasketSearch'),
  'basket-share': () => import('./icons/IconBasketShare'),
  'basket-star': () => import('./icons/IconBasketStar'),
  'basket-up': () => import('./icons/IconBasketUp'),
  'basket-x': () => import('./icons/IconBasketX'),
  'basket': () => import('./icons/IconBasket'),
  'bat': () => import('./icons/IconBat'),
  'bath-off': () => import('./icons/IconBathOff'),
  'bath': () => import('./icons/IconBath'),
  'battery-1': () => import('./icons/IconBattery1'),
  'battery-2': () => import('./icons/IconBattery2'),
  'battery-3': () => import('./icons/IconBattery3'),
  'battery-4': () => import('./icons/IconBattery4'),
  'battery-automotive': () => import('./icons/IconBatteryAutomotive'),
  'battery-charging-2': () => import('./icons/IconBatteryCharging2'),
  'battery-charging': () => import('./icons/IconBatteryCharging'),
  'battery-eco': () => import('./icons/IconBatteryEco'),
  'battery-exclamation': () => import('./icons/IconBatteryExclamation'),
  'battery-off': () => import('./icons/IconBatteryOff'),
  'battery-spark': () => import('./icons/IconBatterySpark'),
  'battery-vertical-1': () => import('./icons/IconBatteryVertical1'),
  'battery-vertical-2': () => import('./icons/IconBatteryVertical2'),
  'battery-vertical-3': () => import('./icons/IconBatteryVertical3'),
  'battery-vertical-4': () => import('./icons/IconBatteryVertical4'),
  'battery-vertical-charging-2': () => import('./icons/IconBatteryVerticalCharging2'),
  'battery-vertical-charging': () => import('./icons/IconBatteryVerticalCharging'),
  'battery-vertical-eco': () => import('./icons/IconBatteryVerticalEco'),
  'battery-vertical-exclamation': () => import('./icons/IconBatteryVerticalExclamation'),
  'battery-vertical-off': () => import('./icons/IconBatteryVerticalOff'),
  'battery-vertical': () => import('./icons/IconBatteryVertical'),
  'battery': () => import('./icons/IconBattery'),
  'beach-off': () => import('./icons/IconBeachOff'),
  'beach': () => import('./icons/IconBeach'),
  'bed-flat': () => import('./icons/IconBedFlat'),
  'bed-off': () => import('./icons/IconBedOff'),
  'bed': () => import('./icons/IconBed'),
  'beer-off': () => import('./icons/IconBeerOff'),
  'beer': () => import('./icons/IconBeer'),
  'bell-bolt': () => import('./icons/IconBellBolt'),
  'bell-cancel': () => import('./icons/IconBellCancel'),
  'bell-check': () => import('./icons/IconBellCheck'),
  'bell-code': () => import('./icons/IconBellCode'),
  'bell-cog': () => import('./icons/IconBellCog'),
  'bell-dollar': () => import('./icons/IconBellDollar'),
  'bell-down': () => import('./icons/IconBellDown'),
  'bell-exclamation': () => import('./icons/IconBellExclamation'),
  'bell-heart': () => import('./icons/IconBellHeart'),
  'bell-minus': () => import('./icons/IconBellMinus'),
  'bell-off': () => import('./icons/IconBellOff'),
  'bell-pause': () => import('./icons/IconBellPause'),
  'bell-pin': () => import('./icons/IconBellPin'),
  'bell-plus': () => import('./icons/IconBellPlus'),
  'bell-question': () => import('./icons/IconBellQuestion'),
  'bell-ringing-2': () => import('./icons/IconBellRinging2'),
  'bell-ringing': () => import('./icons/IconBellRinging'),
  'bell-school': () => import('./icons/IconBellSchool'),
  'bell-search': () => import('./icons/IconBellSearch'),
  'bell-share': () => import('./icons/IconBellShare'),
  'bell-star': () => import('./icons/IconBellStar'),
  'bell-up': () => import('./icons/IconBellUp'),
  'bell-x': () => import('./icons/IconBellX'),
  'bell-z': () => import('./icons/IconBellZ'),
  'bell': () => import('./icons/IconBell'),
  'beta': () => import('./icons/IconBeta'),
  'bible': () => import('./icons/IconBible'),
  'bike-off': () => import('./icons/IconBikeOff'),
  'bike': () => import('./icons/IconBike'),
  'binary-off': () => import('./icons/IconBinaryOff'),
  'binary-tree-2': () => import('./icons/IconBinaryTree2'),
  'binary-tree': () => import('./icons/IconBinaryTree'),
  'binary': () => import('./icons/IconBinary'),
  'binoculars': () => import('./icons/IconBinoculars'),
  'biohazard-off': () => import('./icons/IconBiohazardOff'),
  'biohazard': () => import('./icons/IconBiohazard'),
  'blade': () => import('./icons/IconBlade'),
  'bleach-chlorine': () => import('./icons/IconBleachChlorine'),
  'bleach-no-chlorine': () => import('./icons/IconBleachNoChlorine'),
  'bleach-off': () => import('./icons/IconBleachOff'),
  'bleach': () => import('./icons/IconBleach'),
  'blend-mode': () => import('./icons/IconBlendMode'),
  'blender': () => import('./icons/IconBlender'),
  'blob': () => import('./icons/IconBlob'),
  'blockquote': () => import('./icons/IconBlockquote'),
  'bluetooth-connected': () => import('./icons/IconBluetoothConnected'),
  'bluetooth-off': () => import('./icons/IconBluetoothOff'),
  'bluetooth-x': () => import('./icons/IconBluetoothX'),
  'bluetooth': () => import('./icons/IconBluetooth'),
  'blur-off': () => import('./icons/IconBlurOff'),
  'blur': () => import('./icons/IconBlur'),
  'bmp': () => import('./icons/IconBmp'),
  'body-scan': () => import('./icons/IconBodyScan'),
  'bold-off': () => import('./icons/IconBoldOff'),
  'bold': () => import('./icons/IconBold'),
  'bolt-off': () => import('./icons/IconBoltOff'),
  'bolt': () => import('./icons/IconBolt'),
  'bomb': () => import('./icons/IconBomb'),
  'bone-off': () => import('./icons/IconBoneOff'),
  'bone': () => import('./icons/IconBone'),
  'bong-off': () => import('./icons/IconBongOff'),
  'bong': () => import('./icons/IconBong'),
  'book-2': () => import('./icons/IconBook2'),
  'book-download': () => import('./icons/IconBookDownload'),
  'book-off': () => import('./icons/IconBookOff'),
  'book-upload': () => import('./icons/IconBookUpload'),
  'book': () => import('./icons/IconBook'),
  'bookmark-ai': () => import('./icons/IconBookmarkAi'),
  'bookmark-edit': () => import('./icons/IconBookmarkEdit'),
  'bookmark-minus': () => import('./icons/IconBookmarkMinus'),
  'bookmark-off': () => import('./icons/IconBookmarkOff'),
  'bookmark-plus': () => import('./icons/IconBookmarkPlus'),
  'bookmark-question': () => import('./icons/IconBookmarkQuestion'),
  'bookmark': () => import('./icons/IconBookmark'),
  'bookmarks-off': () => import('./icons/IconBookmarksOff'),
  'bookmarks': () => import('./icons/IconBookmarks'),
  'books-off': () => import('./icons/IconBooksOff'),
  'books': () => import('./icons/IconBooks'),
  'boom': () => import('./icons/IconBoom'),
  'border-all': () => import('./icons/IconBorderAll'),
  'border-bottom-plus': () => import('./icons/IconBorderBottomPlus'),
  'border-bottom': () => import('./icons/IconBorderBottom'),
  'border-corner-ios': () => import('./icons/IconBorderCornerIos'),
  'border-corner-pill': () => import('./icons/IconBorderCornerPill'),
  'border-corner-rounded': () => import('./icons/IconBorderCornerRounded'),
  'border-corner-square': () => import('./icons/IconBorderCornerSquare'),
  'border-corners': () => import('./icons/IconBorderCorners'),
  'border-horizontal': () => import('./icons/IconBorderHorizontal'),
  'border-inner': () => import('./icons/IconBorderInner'),
  'border-left-plus': () => import('./icons/IconBorderLeftPlus'),
  'border-left': () => import('./icons/IconBorderLeft'),
  'border-none': () => import('./icons/IconBorderNone'),
  'border-outer': () => import('./icons/IconBorderOuter'),
  'border-radius': () => import('./icons/IconBorderRadius'),
  'border-right-plus': () => import('./icons/IconBorderRightPlus'),
  'border-right': () => import('./icons/IconBorderRight'),
  'border-sides': () => import('./icons/IconBorderSides'),
  'border-style-2': () => import('./icons/IconBorderStyle2'),
  'border-style': () => import('./icons/IconBorderStyle'),
  'border-top-plus': () => import('./icons/IconBorderTopPlus'),
  'border-top': () => import('./icons/IconBorderTop'),
  'border-vertical': () => import('./icons/IconBorderVertical'),
  'bottle-off': () => import('./icons/IconBottleOff'),
  'bottle': () => import('./icons/IconBottle'),
  'bounce-left': () => import('./icons/IconBounceLeft'),
  'bounce-right': () => import('./icons/IconBounceRight'),
  'bow': () => import('./icons/IconBow'),
  'bowl-chopsticks': () => import('./icons/IconBowlChopsticks'),
  'bowl-spoon': () => import('./icons/IconBowlSpoon'),
  'bowl': () => import('./icons/IconBowl'),
  'box-align-bottom-left': () => import('./icons/IconBoxAlignBottomLeft'),
  'box-align-bottom-right': () => import('./icons/IconBoxAlignBottomRight'),
  'box-align-bottom': () => import('./icons/IconBoxAlignBottom'),
  'box-align-left': () => import('./icons/IconBoxAlignLeft'),
  'box-align-right': () => import('./icons/IconBoxAlignRight'),
  'box-align-top-left': () => import('./icons/IconBoxAlignTopLeft'),
  'box-align-top-right': () => import('./icons/IconBoxAlignTopRight'),
  'box-align-top': () => import('./icons/IconBoxAlignTop'),
  'box-margin': () => import('./icons/IconBoxMargin'),
  'box-model-2-off': () => import('./icons/IconBoxModel2Off'),
  'box-model-2': () => import('./icons/IconBoxModel2'),
  'box-model-off': () => import('./icons/IconBoxModelOff'),
  'box-model': () => import('./icons/IconBoxModel'),
  'box-multiple-0': () => import('./icons/IconBoxMultiple0'),
  'box-multiple-1': () => import('./icons/IconBoxMultiple1'),
  'box-multiple-2': () => import('./icons/IconBoxMultiple2'),
  'box-multiple-3': () => import('./icons/IconBoxMultiple3'),
  'box-multiple-4': () => import('./icons/IconBoxMultiple4'),
  'box-multiple-5': () => import('./icons/IconBoxMultiple5'),
  'box-multiple-6': () => import('./icons/IconBoxMultiple6'),
  'box-multiple-7': () => import('./icons/IconBoxMultiple7'),
  'box-multiple-8': () => import('./icons/IconBoxMultiple8'),
  'box-multiple-9': () => import('./icons/IconBoxMultiple9'),
  'box-multiple': () => import('./icons/IconBoxMultiple'),
  'box-off': () => import('./icons/IconBoxOff'),
  'box-padding': () => import('./icons/IconBoxPadding'),
  'box': () => import('./icons/IconBox'),
  'braces-off': () => import('./icons/IconBracesOff'),
  'braces': () => import('./icons/IconBraces'),
  'brackets-angle-off': () => import('./icons/IconBracketsAngleOff'),
  'brackets-angle': () => import('./icons/IconBracketsAngle'),
  'brackets-contain-end': () => import('./icons/IconBracketsContainEnd'),
  'brackets-contain-start': () => import('./icons/IconBracketsContainStart'),
  'brackets-contain': () => import('./icons/IconBracketsContain'),
  'brackets-off': () => import('./icons/IconBracketsOff'),
  'brackets': () => import('./icons/IconBrackets'),
  'braille': () => import('./icons/IconBraille'),
  'brain': () => import('./icons/IconBrain'),
  'brand-4chan': () => import('./icons/IconBrand4chan'),
  'brand-abstract': () => import('./icons/IconBrandAbstract'),
  'brand-adobe-after-effect': () => import('./icons/IconBrandAdobeAfterEffect'),
  'brand-adobe-illustrator': () => import('./icons/IconBrandAdobeIllustrator'),
  'brand-adobe-indesign': () => import('./icons/IconBrandAdobeIndesign'),
  'brand-adobe-photoshop': () => import('./icons/IconBrandAdobePhotoshop'),
  'brand-adobe-premier': () => import('./icons/IconBrandAdobePremier'),
  'brand-adobe-xd': () => import('./icons/IconBrandAdobeXd'),
  'brand-adobe': () => import('./icons/IconBrandAdobe'),
  'brand-adonis-js': () => import('./icons/IconBrandAdonisJs'),
  'brand-airbnb': () => import('./icons/IconBrandAirbnb'),
  'brand-airtable': () => import('./icons/IconBrandAirtable'),
  'brand-algolia': () => import('./icons/IconBrandAlgolia'),
  'brand-alipay': () => import('./icons/IconBrandAlipay'),
  'brand-alpine-js': () => import('./icons/IconBrandAlpineJs'),
  'brand-amazon': () => import('./icons/IconBrandAmazon'),
  'brand-amd': () => import('./icons/IconBrandAmd'),
  'brand-amie': () => import('./icons/IconBrandAmie'),
  'brand-amigo': () => import('./icons/IconBrandAmigo'),
  'brand-among-us': () => import('./icons/IconBrandAmongUs'),
  'brand-android': () => import('./icons/IconBrandAndroid'),
  'brand-angular': () => import('./icons/IconBrandAngular'),
  'brand-ansible': () => import('./icons/IconBrandAnsible'),
  'brand-ao3': () => import('./icons/IconBrandAo3'),
  'brand-appgallery': () => import('./icons/IconBrandAppgallery'),
  'brand-apple-arcade': () => import('./icons/IconBrandAppleArcade'),
  'brand-apple-news': () => import('./icons/IconBrandAppleNews'),
  'brand-apple-podcast': () => import('./icons/IconBrandApplePodcast'),
  'brand-apple': () => import('./icons/IconBrandApple'),
  'brand-appstore': () => import('./icons/IconBrandAppstore'),
  'brand-arc': () => import('./icons/IconBrandArc'),
  'brand-asana': () => import('./icons/IconBrandAsana'),
  'brand-astro': () => import('./icons/IconBrandAstro'),
  'brand-auth0': () => import('./icons/IconBrandAuth0'),
  'brand-aws': () => import('./icons/IconBrandAws'),
  'brand-azure': () => import('./icons/IconBrandAzure'),
  'brand-backbone': () => import('./icons/IconBrandBackbone'),
  'brand-badoo': () => import('./icons/IconBrandBadoo'),
  'brand-baidu': () => import('./icons/IconBrandBaidu'),
  'brand-bandcamp': () => import('./icons/IconBrandBandcamp'),
  'brand-bandlab': () => import('./icons/IconBrandBandlab'),
  'brand-beats': () => import('./icons/IconBrandBeats'),
  'brand-bebo': () => import('./icons/IconBrandBebo'),
  'brand-behance': () => import('./icons/IconBrandBehance'),
  'brand-bilibili': () => import('./icons/IconBrandBilibili'),
  'brand-binance': () => import('./icons/IconBrandBinance'),
  'brand-bing': () => import('./icons/IconBrandBing'),
  'brand-bitbucket': () => import('./icons/IconBrandBitbucket'),
  'brand-blackberry': () => import('./icons/IconBrandBlackberry'),
  'brand-blender': () => import('./icons/IconBrandBlender'),
  'brand-blogger': () => import('./icons/IconBrandBlogger'),
  'brand-bluesky': () => import('./icons/IconBrandBluesky'),
  'brand-booking': () => import('./icons/IconBrandBooking'),
  'brand-bootstrap': () => import('./icons/IconBrandBootstrap'),
  'brand-bulma': () => import('./icons/IconBrandBulma'),
  'brand-bumble': () => import('./icons/IconBrandBumble'),
  'brand-bunpo': () => import('./icons/IconBrandBunpo'),
  'brand-c-sharp': () => import('./icons/IconBrandCSharp'),
  'brand-cake': () => import('./icons/IconBrandCake'),
  'brand-cakephp': () => import('./icons/IconBrandCakephp'),
  'brand-campaignmonitor': () => import('./icons/IconBrandCampaignmonitor'),
  'brand-carbon': () => import('./icons/IconBrandCarbon'),
  'brand-cashapp': () => import('./icons/IconBrandCashapp'),
  'brand-chrome': () => import('./icons/IconBrandChrome'),
  'brand-cinema-4d': () => import('./icons/IconBrandCinema4d'),
  'brand-citymapper': () => import('./icons/IconBrandCitymapper'),
  'brand-cloudflare': () => import('./icons/IconBrandCloudflare'),
  'brand-codecov': () => import('./icons/IconBrandCodecov'),
  'brand-codepen': () => import('./icons/IconBrandCodepen'),
  'brand-codesandbox': () => import('./icons/IconBrandCodesandbox'),
  'brand-cohost': () => import('./icons/IconBrandCohost'),
  'brand-coinbase': () => import('./icons/IconBrandCoinbase'),
  'brand-comedy-central': () => import('./icons/IconBrandComedyCentral'),
  'brand-coreos': () => import('./icons/IconBrandCoreos'),
  'brand-couchdb': () => import('./icons/IconBrandCouchdb'),
  'brand-couchsurfing': () => import('./icons/IconBrandCouchsurfing'),
  'brand-cpp': () => import('./icons/IconBrandCpp'),
  'brand-craft': () => import('./icons/IconBrandCraft'),
  'brand-crunchbase': () => import('./icons/IconBrandCrunchbase'),
  'brand-css3': () => import('./icons/IconBrandCss3'),
  'brand-ctemplar': () => import('./icons/IconBrandCtemplar'),
  'brand-cucumber': () => import('./icons/IconBrandCucumber'),
  'brand-cupra': () => import('./icons/IconBrandCupra'),
  'brand-cypress': () => import('./icons/IconBrandCypress'),
  'brand-d3': () => import('./icons/IconBrandD3'),
  'brand-databricks': () => import('./icons/IconBrandDatabricks'),
  'brand-days-counter': () => import('./icons/IconBrandDaysCounter'),
  'brand-dcos': () => import('./icons/IconBrandDcos'),
  'brand-debian': () => import('./icons/IconBrandDebian'),
  'brand-deezer': () => import('./icons/IconBrandDeezer'),
  'brand-deliveroo': () => import('./icons/IconBrandDeliveroo'),
  'brand-deno': () => import('./icons/IconBrandDeno'),
  'brand-denodo': () => import('./icons/IconBrandDenodo'),
  'brand-deviantart': () => import('./icons/IconBrandDeviantart'),
  'brand-digg': () => import('./icons/IconBrandDigg'),
  'brand-dingtalk': () => import('./icons/IconBrandDingtalk'),
  'brand-discord': () => import('./icons/IconBrandDiscord'),
  'brand-disney': () => import('./icons/IconBrandDisney'),
  'brand-disqus': () => import('./icons/IconBrandDisqus'),
  'brand-django': () => import('./icons/IconBrandDjango'),
  'brand-docker': () => import('./icons/IconBrandDocker'),
  'brand-doctrine': () => import('./icons/IconBrandDoctrine'),
  'brand-dolby-digital': () => import('./icons/IconBrandDolbyDigital'),
  'brand-douban': () => import('./icons/IconBrandDouban'),
  'brand-dribbble': () => import('./icons/IconBrandDribbble'),
  'brand-drops': () => import('./icons/IconBrandDrops'),
  'brand-drupal': () => import('./icons/IconBrandDrupal'),
  'brand-edge': () => import('./icons/IconBrandEdge'),
  'brand-elastic': () => import('./icons/IconBrandElastic'),
  'brand-electronic-arts': () => import('./icons/IconBrandElectronicArts'),
  'brand-ember': () => import('./icons/IconBrandEmber'),
  'brand-envato': () => import('./icons/IconBrandEnvato'),
  'brand-etsy': () => import('./icons/IconBrandEtsy'),
  'brand-evernote': () => import('./icons/IconBrandEvernote'),
  'brand-facebook': () => import('./icons/IconBrandFacebook'),
  'brand-feedly': () => import('./icons/IconBrandFeedly'),
  'brand-figma': () => import('./icons/IconBrandFigma'),
  'brand-filezilla': () => import('./icons/IconBrandFilezilla'),
  'brand-finder': () => import('./icons/IconBrandFinder'),
  'brand-firebase': () => import('./icons/IconBrandFirebase'),
  'brand-firefox': () => import('./icons/IconBrandFirefox'),
  'brand-fiverr': () => import('./icons/IconBrandFiverr'),
  'brand-flickr': () => import('./icons/IconBrandFlickr'),
  'brand-flightradar24': () => import('./icons/IconBrandFlightradar24'),
  'brand-flipboard': () => import('./icons/IconBrandFlipboard'),
  'brand-flutter': () => import('./icons/IconBrandFlutter'),
  'brand-fortnite': () => import('./icons/IconBrandFortnite'),
  'brand-foursquare': () => import('./icons/IconBrandFoursquare'),
  'brand-framer-motion': () => import('./icons/IconBrandFramerMotion'),
  'brand-framer': () => import('./icons/IconBrandFramer'),
  'brand-funimation': () => import('./icons/IconBrandFunimation'),
  'brand-gatsby': () => import('./icons/IconBrandGatsby'),
  'brand-git': () => import('./icons/IconBrandGit'),
  'brand-github-copilot': () => import('./icons/IconBrandGithubCopilot'),
  'brand-github': () => import('./icons/IconBrandGithub'),
  'brand-gitlab': () => import('./icons/IconBrandGitlab'),
  'brand-gmail': () => import('./icons/IconBrandGmail'),
  'brand-golang': () => import('./icons/IconBrandGolang'),
  'brand-google-analytics': () => import('./icons/IconBrandGoogleAnalytics'),
  'brand-google-big-query': () => import('./icons/IconBrandGoogleBigQuery'),
  'brand-google-drive': () => import('./icons/IconBrandGoogleDrive'),
  'brand-google-fit': () => import('./icons/IconBrandGoogleFit'),
  'brand-google-home': () => import('./icons/IconBrandGoogleHome'),
  'brand-google-maps': () => import('./icons/IconBrandGoogleMaps'),
  'brand-google-one': () => import('./icons/IconBrandGoogleOne'),
  'brand-google-photos': () => import('./icons/IconBrandGooglePhotos'),
  'brand-google-play': () => import('./icons/IconBrandGooglePlay'),
  'brand-google-podcasts': () => import('./icons/IconBrandGooglePodcasts'),
  'brand-google': () => import('./icons/IconBrandGoogle'),
  'brand-grammarly': () => import('./icons/IconBrandGrammarly'),
  'brand-graphql': () => import('./icons/IconBrandGraphql'),
  'brand-gravatar': () => import('./icons/IconBrandGravatar'),
  'brand-grindr': () => import('./icons/IconBrandGrindr'),
  'brand-guardian': () => import('./icons/IconBrandGuardian'),
  'brand-gumroad': () => import('./icons/IconBrandGumroad'),
  'brand-hackerrank': () => import('./icons/IconBrandHackerrank'),
  'brand-hbo': () => import('./icons/IconBrandHbo'),
  'brand-headlessui': () => import('./icons/IconBrandHeadlessui'),
  'brand-hexo': () => import('./icons/IconBrandHexo'),
  'brand-hipchat': () => import('./icons/IconBrandHipchat'),
  'brand-html5': () => import('./icons/IconBrandHtml5'),
  'brand-inertia': () => import('./icons/IconBrandInertia'),
  'brand-instagram': () => import('./icons/IconBrandInstagram'),
  'brand-intercom': () => import('./icons/IconBrandIntercom'),
  'brand-itch': () => import('./icons/IconBrandItch'),
  'brand-javascript': () => import('./icons/IconBrandJavascript'),
  'brand-juejin': () => import('./icons/IconBrandJuejin'),
  'brand-kako-talk': () => import('./icons/IconBrandKakoTalk'),
  'brand-kbin': () => import('./icons/IconBrandKbin'),
  'brand-kick': () => import('./icons/IconBrandKick'),
  'brand-kickstarter': () => import('./icons/IconBrandKickstarter'),
  'brand-kotlin': () => import('./icons/IconBrandKotlin'),
  'brand-laravel': () => import('./icons/IconBrandLaravel'),
  'brand-lastfm': () => import('./icons/IconBrandLastfm'),
  'brand-leetcode': () => import('./icons/IconBrandLeetcode'),
  'brand-letterboxd': () => import('./icons/IconBrandLetterboxd'),
  'brand-line': () => import('./icons/IconBrandLine'),
  'brand-linkedin': () => import('./icons/IconBrandLinkedin'),
  'brand-linktree': () => import('./icons/IconBrandLinktree'),
  'brand-linqpad': () => import('./icons/IconBrandLinqpad'),
  'brand-livewire': () => import('./icons/IconBrandLivewire'),
  'brand-loom': () => import('./icons/IconBrandLoom'),
  'brand-mailgun': () => import('./icons/IconBrandMailgun'),
  'brand-mantine': () => import('./icons/IconBrandMantine'),
  'brand-mastercard': () => import('./icons/IconBrandMastercard'),
  'brand-mastodon': () => import('./icons/IconBrandMastodon'),
  'brand-matrix': () => import('./icons/IconBrandMatrix'),
  'brand-mcdonalds': () => import('./icons/IconBrandMcdonalds'),
  'brand-medium': () => import('./icons/IconBrandMedium'),
  'brand-meetup': () => import('./icons/IconBrandMeetup'),
  'brand-mercedes': () => import('./icons/IconBrandMercedes'),
  'brand-messenger': () => import('./icons/IconBrandMessenger'),
  'brand-meta': () => import('./icons/IconBrandMeta'),
  'brand-metabrainz': () => import('./icons/IconBrandMetabrainz'),
  'brand-minecraft': () => import('./icons/IconBrandMinecraft'),
  'brand-miniprogram': () => import('./icons/IconBrandMiniprogram'),
  'brand-mixpanel': () => import('./icons/IconBrandMixpanel'),
  'brand-monday': () => import('./icons/IconBrandMonday'),
  'brand-mongodb': () => import('./icons/IconBrandMongodb'),
  'brand-my-oppo': () => import('./icons/IconBrandMyOppo'),
  'brand-mysql': () => import('./icons/IconBrandMysql'),
  'brand-national-geographic': () => import('./icons/IconBrandNationalGeographic'),
  'brand-nem': () => import('./icons/IconBrandNem'),
  'brand-netbeans': () => import('./icons/IconBrandNetbeans'),
  'brand-netease-music': () => import('./icons/IconBrandNeteaseMusic'),
  'brand-netflix': () => import('./icons/IconBrandNetflix'),
  'brand-nexo': () => import('./icons/IconBrandNexo'),
  'brand-nextcloud': () => import('./icons/IconBrandNextcloud'),
  'brand-nextjs': () => import('./icons/IconBrandNextjs'),
  'brand-nodejs': () => import('./icons/IconBrandNodejs'),
  'brand-nord-vpn': () => import('./icons/IconBrandNordVpn'),
  'brand-notion': () => import('./icons/IconBrandNotion'),
  'brand-npm': () => import('./icons/IconBrandNpm'),
  'brand-nuxt': () => import('./icons/IconBrandNuxt'),
  'brand-nytimes': () => import('./icons/IconBrandNytimes'),
  'brand-oauth': () => import('./icons/IconBrandOauth'),
  'brand-office': () => import('./icons/IconBrandOffice'),
  'brand-ok-ru': () => import('./icons/IconBrandOkRu'),
  'brand-onedrive': () => import('./icons/IconBrandOnedrive'),
  'brand-onlyfans': () => import('./icons/IconBrandOnlyfans'),
  'brand-open-source': () => import('./icons/IconBrandOpenSource'),
  'brand-openai': () => import('./icons/IconBrandOpenai'),
  'brand-openvpn': () => import('./icons/IconBrandOpenvpn'),
  'brand-opera': () => import('./icons/IconBrandOpera'),
  'brand-pagekit': () => import('./icons/IconBrandPagekit'),
  'brand-parsinta': () => import('./icons/IconBrandParsinta'),
  'brand-patreon': () => import('./icons/IconBrandPatreon'),
  'brand-paypal': () => import('./icons/IconBrandPaypal'),
  'brand-paypay': () => import('./icons/IconBrandPaypay'),
  'brand-peanut': () => import('./icons/IconBrandPeanut'),
  'brand-pepsi': () => import('./icons/IconBrandPepsi'),
  'brand-php': () => import('./icons/IconBrandPhp'),
  'brand-picsart': () => import('./icons/IconBrandPicsart'),
  'brand-pinterest': () => import('./icons/IconBrandPinterest'),
  'brand-planetscale': () => import('./icons/IconBrandPlanetscale'),
  'brand-pnpm': () => import('./icons/IconBrandPnpm'),
  'brand-pocket': () => import('./icons/IconBrandPocket'),
  'brand-polymer': () => import('./icons/IconBrandPolymer'),
  'brand-powershell': () => import('./icons/IconBrandPowershell'),
  'brand-printables': () => import('./icons/IconBrandPrintables'),
  'brand-prisma': () => import('./icons/IconBrandPrisma'),
  'brand-producthunt': () => import('./icons/IconBrandProducthunt'),
  'brand-pushbullet': () => import('./icons/IconBrandPushbullet'),
  'brand-pushover': () => import('./icons/IconBrandPushover'),
  'brand-python': () => import('./icons/IconBrandPython'),
  'brand-qq': () => import('./icons/IconBrandQq'),
  'brand-radix-ui': () => import('./icons/IconBrandRadixUi'),
  'brand-react-native': () => import('./icons/IconBrandReactNative'),
  'brand-react': () => import('./icons/IconBrandReact'),
  'brand-reason': () => import('./icons/IconBrandReason'),
  'brand-reddit': () => import('./icons/IconBrandReddit'),
  'brand-redhat': () => import('./icons/IconBrandRedhat'),
  'brand-redux': () => import('./icons/IconBrandRedux'),
  'brand-revolut': () => import('./icons/IconBrandRevolut'),
  'brand-rumble': () => import('./icons/IconBrandRumble'),
  'brand-rust': () => import('./icons/IconBrandRust'),
  'brand-safari': () => import('./icons/IconBrandSafari'),
  'brand-samsungpass': () => import('./icons/IconBrandSamsungpass'),
  'brand-sass': () => import('./icons/IconBrandSass'),
  'brand-sentry': () => import('./icons/IconBrandSentry'),
  'brand-sharik': () => import('./icons/IconBrandSharik'),
  'brand-shazam': () => import('./icons/IconBrandShazam'),
  'brand-shopee': () => import('./icons/IconBrandShopee'),
  'brand-sketch': () => import('./icons/IconBrandSketch'),
  'brand-skype': () => import('./icons/IconBrandSkype'),
  'brand-slack': () => import('./icons/IconBrandSlack'),
  'brand-snapchat': () => import('./icons/IconBrandSnapchat'),
  'brand-snapseed': () => import('./icons/IconBrandSnapseed'),
  'brand-snowflake': () => import('./icons/IconBrandSnowflake'),
  'brand-socket-io': () => import('./icons/IconBrandSocketIo'),
  'brand-solidjs': () => import('./icons/IconBrandSolidjs'),
  'brand-soundcloud': () => import('./icons/IconBrandSoundcloud'),
  'brand-spacehey': () => import('./icons/IconBrandSpacehey'),
  'brand-speedtest': () => import('./icons/IconBrandSpeedtest'),
  'brand-spotify': () => import('./icons/IconBrandSpotify'),
  'brand-stackoverflow': () => import('./icons/IconBrandStackoverflow'),
  'brand-stackshare': () => import('./icons/IconBrandStackshare'),
  'brand-steam': () => import('./icons/IconBrandSteam'),
  'brand-stocktwits': () => import('./icons/IconBrandStocktwits'),
  'brand-storj': () => import('./icons/IconBrandStorj'),
  'brand-storybook': () => import('./icons/IconBrandStorybook'),
  'brand-storytel': () => import('./icons/IconBrandStorytel'),
  'brand-strava': () => import('./icons/IconBrandStrava'),
  'brand-stripe': () => import('./icons/IconBrandStripe'),
  'brand-sublime-text': () => import('./icons/IconBrandSublimeText'),
  'brand-sugarizer': () => import('./icons/IconBrandSugarizer'),
  'brand-supabase': () => import('./icons/IconBrandSupabase'),
  'brand-superhuman': () => import('./icons/IconBrandSuperhuman'),
  'brand-supernova': () => import('./icons/IconBrandSupernova'),
  'brand-surfshark': () => import('./icons/IconBrandSurfshark'),
  'brand-svelte': () => import('./icons/IconBrandSvelte'),
  'brand-swift': () => import('./icons/IconBrandSwift'),
  'brand-symfony': () => import('./icons/IconBrandSymfony'),
  'brand-tabler': () => import('./icons/IconBrandTabler'),
  'brand-tailwind': () => import('./icons/IconBrandTailwind'),
  'brand-taobao': () => import('./icons/IconBrandTaobao'),
  'brand-teams': () => import('./icons/IconBrandTeams'),
  'brand-ted': () => import('./icons/IconBrandTed'),
  'brand-telegram': () => import('./icons/IconBrandTelegram'),
  'brand-terraform': () => import('./icons/IconBrandTerraform'),
  'brand-tether': () => import('./icons/IconBrandTether'),
  'brand-thingiverse': () => import('./icons/IconBrandThingiverse'),
  'brand-threads': () => import('./icons/IconBrandThreads'),
  'brand-threejs': () => import('./icons/IconBrandThreejs'),
  'brand-tidal': () => import('./icons/IconBrandTidal'),
  'brand-tiktok': () => import('./icons/IconBrandTiktok'),
  'brand-tinder': () => import('./icons/IconBrandTinder'),
  'brand-topbuzz': () => import('./icons/IconBrandTopbuzz'),
  'brand-torchain': () => import('./icons/IconBrandTorchain'),
  'brand-toyota': () => import('./icons/IconBrandToyota'),
  'brand-trello': () => import('./icons/IconBrandTrello'),
  'brand-tripadvisor': () => import('./icons/IconBrandTripadvisor'),
  'brand-tumblr': () => import('./icons/IconBrandTumblr'),
  'brand-twilio': () => import('./icons/IconBrandTwilio'),
  'brand-twitch': () => import('./icons/IconBrandTwitch'),
  'brand-twitter': () => import('./icons/IconBrandTwitter'),
  'brand-typescript': () => import('./icons/IconBrandTypescript'),
  'brand-uber': () => import('./icons/IconBrandUber'),
  'brand-ubuntu': () => import('./icons/IconBrandUbuntu'),
  'brand-unity': () => import('./icons/IconBrandUnity'),
  'brand-unsplash': () => import('./icons/IconBrandUnsplash'),
  'brand-upwork': () => import('./icons/IconBrandUpwork'),
  'brand-valorant': () => import('./icons/IconBrandValorant'),
  'brand-vercel': () => import('./icons/IconBrandVercel'),
  'brand-vimeo': () => import('./icons/IconBrandVimeo'),
  'brand-vinted': () => import('./icons/IconBrandVinted'),
  'brand-visa': () => import('./icons/IconBrandVisa'),
  'brand-visual-studio': () => import('./icons/IconBrandVisualStudio'),
  'brand-vite': () => import('./icons/IconBrandVite'),
  'brand-vivaldi': () => import('./icons/IconBrandVivaldi'),
  'brand-vk': () => import('./icons/IconBrandVk'),
  'brand-vlc': () => import('./icons/IconBrandVlc'),
  'brand-volkswagen': () => import('./icons/IconBrandVolkswagen'),
  'brand-vsco': () => import('./icons/IconBrandVsco'),
  'brand-vscode': () => import('./icons/IconBrandVscode'),
  'brand-vue': () => import('./icons/IconBrandVue'),
  'brand-walmart': () => import('./icons/IconBrandWalmart'),
  'brand-waze': () => import('./icons/IconBrandWaze'),
  'brand-webflow': () => import('./icons/IconBrandWebflow'),
  'brand-wechat': () => import('./icons/IconBrandWechat'),
  'brand-weibo': () => import('./icons/IconBrandWeibo'),
  'brand-whatsapp': () => import('./icons/IconBrandWhatsapp'),
  'brand-wikipedia': () => import('./icons/IconBrandWikipedia'),
  'brand-windows': () => import('./icons/IconBrandWindows'),
  'brand-windy': () => import('./icons/IconBrandWindy'),
  'brand-wish': () => import('./icons/IconBrandWish'),
  'brand-wix': () => import('./icons/IconBrandWix'),
  'brand-wordpress': () => import('./icons/IconBrandWordpress'),
  'brand-x': () => import('./icons/IconBrandX'),
  'brand-xamarin': () => import('./icons/IconBrandXamarin'),
  'brand-xbox': () => import('./icons/IconBrandXbox'),
  'brand-xdeep': () => import('./icons/IconBrandXdeep'),
  'brand-xing': () => import('./icons/IconBrandXing'),
  'brand-yahoo': () => import('./icons/IconBrandYahoo'),
  'brand-yandex': () => import('./icons/IconBrandYandex'),
  'brand-yarn': () => import('./icons/IconBrandYarn'),
  'brand-yatse': () => import('./icons/IconBrandYatse'),
  'brand-ycombinator': () => import('./icons/IconBrandYcombinator'),
  'brand-youtube-kids': () => import('./icons/IconBrandYoutubeKids'),
  'brand-youtube': () => import('./icons/IconBrandYoutube'),
  'brand-zalando': () => import('./icons/IconBrandZalando'),
  'brand-zapier': () => import('./icons/IconBrandZapier'),
  'brand-zeit': () => import('./icons/IconBrandZeit'),
  'brand-zhihu': () => import('./icons/IconBrandZhihu'),
  'brand-zoom': () => import('./icons/IconBrandZoom'),
  'brand-zulip': () => import('./icons/IconBrandZulip'),
  'brand-zwift': () => import('./icons/IconBrandZwift'),
  'bread-off': () => import('./icons/IconBreadOff'),
  'bread': () => import('./icons/IconBread'),
  'briefcase-2': () => import('./icons/IconBriefcase2'),
  'briefcase-off': () => import('./icons/IconBriefcaseOff'),
  'briefcase': () => import('./icons/IconBriefcase'),
  'brightness-2': () => import('./icons/IconBrightness2'),
  'brightness-auto': () => import('./icons/IconBrightnessAuto'),
  'brightness-down': () => import('./icons/IconBrightnessDown'),
  'brightness-half': () => import('./icons/IconBrightnessHalf'),
  'brightness-off': () => import('./icons/IconBrightnessOff'),
  'brightness-up': () => import('./icons/IconBrightnessUp'),
  'brightness': () => import('./icons/IconBrightness'),
  'broadcast-off': () => import('./icons/IconBroadcastOff'),
  'broadcast': () => import('./icons/IconBroadcast'),
  'browser-check': () => import('./icons/IconBrowserCheck'),
  'browser-off': () => import('./icons/IconBrowserOff'),
  'browser-plus': () => import('./icons/IconBrowserPlus'),
  'browser-x': () => import('./icons/IconBrowserX'),
  'browser': () => import('./icons/IconBrowser'),
  'brush-off': () => import('./icons/IconBrushOff'),
  'brush': () => import('./icons/IconBrush'),
  'bubble-minus': () => import('./icons/IconBubbleMinus'),
  'bubble-plus': () => import('./icons/IconBubblePlus'),
  'bubble-tea-2': () => import('./icons/IconBubbleTea2'),
  'bubble-tea': () => import('./icons/IconBubbleTea'),
  'bubble-text': () => import('./icons/IconBubbleText'),
  'bubble-x': () => import('./icons/IconBubbleX'),
  'bubble': () => import('./icons/IconBubble'),
  'bucket-droplet': () => import('./icons/IconBucketDroplet'),
  'bucket-off': () => import('./icons/IconBucketOff'),
  'bucket': () => import('./icons/IconBucket'),
  'bug-off': () => import('./icons/IconBugOff'),
  'bug': () => import('./icons/IconBug'),
  'building-airport': () => import('./icons/IconBuildingAirport'),
  'building-arch': () => import('./icons/IconBuildingArch'),
  'building-bank': () => import('./icons/IconBuildingBank'),
  'building-bridge-2': () => import('./icons/IconBuildingBridge2'),
  'building-bridge': () => import('./icons/IconBuildingBridge'),
  'building-broadcast-tower': () => import('./icons/IconBuildingBroadcastTower'),
  'building-burj-al-arab': () => import('./icons/IconBuildingBurjAlArab'),
  'building-carousel': () => import('./icons/IconBuildingCarousel'),
  'building-castle': () => import('./icons/IconBuildingCastle'),
  'building-church': () => import('./icons/IconBuildingChurch'),
  'building-circus': () => import('./icons/IconBuildingCircus'),
  'building-cog': () => import('./icons/IconBuildingCog'),
  'building-community': () => import('./icons/IconBuildingCommunity'),
  'building-cottage': () => import('./icons/IconBuildingCottage'),
  'building-estate': () => import('./icons/IconBuildingEstate'),
  'building-factory-2': () => import('./icons/IconBuildingFactory2'),
  'building-factory': () => import('./icons/IconBuildingFactory'),
  'building-fortress': () => import('./icons/IconBuildingFortress'),
  'building-hospital': () => import('./icons/IconBuildingHospital'),
  'building-lighthouse': () => import('./icons/IconBuildingLighthouse'),
  'building-minus': () => import('./icons/IconBuildingMinus'),
  'building-monument': () => import('./icons/IconBuildingMonument'),
  'building-mosque': () => import('./icons/IconBuildingMosque'),
  'building-off': () => import('./icons/IconBuildingOff'),
  'building-pavilion': () => import('./icons/IconBuildingPavilion'),
  'building-plus': () => import('./icons/IconBuildingPlus'),
  'building-skyscraper': () => import('./icons/IconBuildingSkyscraper'),
  'building-stadium': () => import('./icons/IconBuildingStadium'),
  'building-store': () => import('./icons/IconBuildingStore'),
  'building-tunnel': () => import('./icons/IconBuildingTunnel'),
  'building-warehouse': () => import('./icons/IconBuildingWarehouse'),
  'building-wind-turbine': () => import('./icons/IconBuildingWindTurbine'),
  'building': () => import('./icons/IconBuilding'),
  'buildings': () => import('./icons/IconBuildings'),
  'bulb-off': () => import('./icons/IconBulbOff'),
  'bulb': () => import('./icons/IconBulb'),
  'bulldozer': () => import('./icons/IconBulldozer'),
  'burger': () => import('./icons/IconBurger'),
  'bus-off': () => import('./icons/IconBusOff'),
  'bus-stop': () => import('./icons/IconBusStop'),
  'bus': () => import('./icons/IconBus'),
  'businessplan': () => import('./icons/IconBusinessplan'),
  'butterfly': () => import('./icons/IconButterfly'),
  'cactus-off': () => import('./icons/IconCactusOff'),
  'cactus': () => import('./icons/IconCactus'),
  'cake-off': () => import('./icons/IconCakeOff'),
  'cake': () => import('./icons/IconCake'),
  'calculator-off': () => import('./icons/IconCalculatorOff'),
  'calculator': () => import('./icons/IconCalculator'),
  'calendar-bolt': () => import('./icons/IconCalendarBolt'),
  'calendar-cancel': () => import('./icons/IconCalendarCancel'),
  'calendar-check': () => import('./icons/IconCalendarCheck'),
  'calendar-clock': () => import('./icons/IconCalendarClock'),
  'calendar-code': () => import('./icons/IconCalendarCode'),
  'calendar-cog': () => import('./icons/IconCalendarCog'),
  'calendar-dollar': () => import('./icons/IconCalendarDollar'),
  'calendar-dot': () => import('./icons/IconCalendarDot'),
  'calendar-down': () => import('./icons/IconCalendarDown'),
  'calendar-due': () => import('./icons/IconCalendarDue'),
  'calendar-event': () => import('./icons/IconCalendarEvent'),
  'calendar-exclamation': () => import('./icons/IconCalendarExclamation'),
  'calendar-heart': () => import('./icons/IconCalendarHeart'),
  'calendar-minus': () => import('./icons/IconCalendarMinus'),
  'calendar-month': () => import('./icons/IconCalendarMonth'),
  'calendar-off': () => import('./icons/IconCalendarOff'),
  'calendar-pause': () => import('./icons/IconCalendarPause'),
  'calendar-pin': () => import('./icons/IconCalendarPin'),
  'calendar-plus': () => import('./icons/IconCalendarPlus'),
  'calendar-question': () => import('./icons/IconCalendarQuestion'),
  'calendar-repeat': () => import('./icons/IconCalendarRepeat'),
  'calendar-sad': () => import('./icons/IconCalendarSad'),
  'calendar-search': () => import('./icons/IconCalendarSearch'),
  'calendar-share': () => import('./icons/IconCalendarShare'),
  'calendar-smile': () => import('./icons/IconCalendarSmile'),
  'calendar-star': () => import('./icons/IconCalendarStar'),
  'calendar-stats': () => import('./icons/IconCalendarStats'),
  'calendar-time': () => import('./icons/IconCalendarTime'),
  'calendar-up': () => import('./icons/IconCalendarUp'),
  'calendar-user': () => import('./icons/IconCalendarUser'),
  'calendar-week': () => import('./icons/IconCalendarWeek'),
  'calendar-x': () => import('./icons/IconCalendarX'),
  'calendar': () => import('./icons/IconCalendar'),
  'camera-ai': () => import('./icons/IconCameraAi'),
  'camera-bitcoin': () => import('./icons/IconCameraBitcoin'),
  'camera-bolt': () => import('./icons/IconCameraBolt'),
  'camera-cancel': () => import('./icons/IconCameraCancel'),
  'camera-check': () => import('./icons/IconCameraCheck'),
  'camera-code': () => import('./icons/IconCameraCode'),
  'camera-cog': () => import('./icons/IconCameraCog'),
  'camera-dollar': () => import('./icons/IconCameraDollar'),
  'camera-down': () => import('./icons/IconCameraDown'),
  'camera-exclamation': () => import('./icons/IconCameraExclamation'),
  'camera-heart': () => import('./icons/IconCameraHeart'),
  'camera-minus': () => import('./icons/IconCameraMinus'),
  'camera-moon': () => import('./icons/IconCameraMoon'),
  'camera-off': () => import('./icons/IconCameraOff'),
  'camera-pause': () => import('./icons/IconCameraPause'),
  'camera-pin': () => import('./icons/IconCameraPin'),
  'camera-plus': () => import('./icons/IconCameraPlus'),
  'camera-question': () => import('./icons/IconCameraQuestion'),
  'camera-rotate': () => import('./icons/IconCameraRotate'),
  'camera-search': () => import('./icons/IconCameraSearch'),
  'camera-selfie': () => import('./icons/IconCameraSelfie'),
  'camera-share': () => import('./icons/IconCameraShare'),
  'camera-spark': () => import('./icons/IconCameraSpark'),
  'camera-star': () => import('./icons/IconCameraStar'),
  'camera-up': () => import('./icons/IconCameraUp'),
  'camera-x': () => import('./icons/IconCameraX'),
  'camera': () => import('./icons/IconCamera'),
  'camper': () => import('./icons/IconCamper'),
  'campfire': () => import('./icons/IconCampfire'),
  'cancel': () => import('./icons/IconCancel'),
  'candle': () => import('./icons/IconCandle'),
  'candy-off': () => import('./icons/IconCandyOff'),
  'candy': () => import('./icons/IconCandy'),
  'cane': () => import('./icons/IconCane'),
  'cannabis': () => import('./icons/IconCannabis'),
  'cap-projecting': () => import('./icons/IconCapProjecting'),
  'cap-rounded': () => import('./icons/IconCapRounded'),
  'cap-straight': () => import('./icons/IconCapStraight'),
  'capsule-horizontal': () => import('./icons/IconCapsuleHorizontal'),
  'capsule': () => import('./icons/IconCapsule'),
  'capture-off': () => import('./icons/IconCaptureOff'),
  'capture': () => import('./icons/IconCapture'),
  'car-4wd': () => import('./icons/IconCar4wd'),
  'car-crane': () => import('./icons/IconCarCrane'),
  'car-crash': () => import('./icons/IconCarCrash'),
  'car-fan-1': () => import('./icons/IconCarFan1'),
  'car-fan-2': () => import('./icons/IconCarFan2'),
  'car-fan-3': () => import('./icons/IconCarFan3'),
  'car-fan-auto': () => import('./icons/IconCarFanAuto'),
  'car-fan': () => import('./icons/IconCarFan'),
  'car-garage': () => import('./icons/IconCarGarage'),
  'car-off': () => import('./icons/IconCarOff'),
  'car-suv': () => import('./icons/IconCarSuv'),
  'car-turbine': () => import('./icons/IconCarTurbine'),
  'car': () => import('./icons/IconCar'),
  'carambola': () => import('./icons/IconCarambola'),
  'caravan': () => import('./icons/IconCaravan'),
  'cardboards-off': () => import('./icons/IconCardboardsOff'),
  'cardboards': () => import('./icons/IconCardboards'),
  'cards': () => import('./icons/IconCards'),
  'caret-down': () => import('./icons/IconCaretDown'),
  'caret-left-right': () => import('./icons/IconCaretLeftRight'),
  'caret-left': () => import('./icons/IconCaretLeft'),
  'caret-right': () => import('./icons/IconCaretRight'),
  'caret-up-down': () => import('./icons/IconCaretUpDown'),
  'caret-up': () => import('./icons/IconCaretUp'),
  'carousel-horizontal': () => import('./icons/IconCarouselHorizontal'),
  'carousel-vertical': () => import('./icons/IconCarouselVertical'),
  'carrot-off': () => import('./icons/IconCarrotOff'),
  'carrot': () => import('./icons/IconCarrot'),
  'cash-banknote-off': () => import('./icons/IconCashBanknoteOff'),
  'cash-banknote': () => import('./icons/IconCashBanknote'),
  'cash-off': () => import('./icons/IconCashOff'),
  'cash-register': () => import('./icons/IconCashRegister'),
  'cash': () => import('./icons/IconCash'),
  'cast-off': () => import('./icons/IconCastOff'),
  'cast': () => import('./icons/IconCast'),
  'cat': () => import('./icons/IconCat'),
  'category-2': () => import('./icons/IconCategory2'),
  'category-minus': () => import('./icons/IconCategoryMinus'),
  'category-plus': () => import('./icons/IconCategoryPlus'),
  'category': () => import('./icons/IconCategory'),
  'ce-off': () => import('./icons/IconCeOff'),
  'ce': () => import('./icons/IconCe'),
  'cell-signal-1': () => import('./icons/IconCellSignal1'),
  'cell-signal-2': () => import('./icons/IconCellSignal2'),
  'cell-signal-3': () => import('./icons/IconCellSignal3'),
  'cell-signal-4': () => import('./icons/IconCellSignal4'),
  'cell-signal-5': () => import('./icons/IconCellSignal5'),
  'cell-signal-off': () => import('./icons/IconCellSignalOff'),
  'cell': () => import('./icons/IconCell'),
  'certificate-2-off': () => import('./icons/IconCertificate2Off'),
  'certificate-2': () => import('./icons/IconCertificate2'),
  'certificate-off': () => import('./icons/IconCertificateOff'),
  'certificate': () => import('./icons/IconCertificate'),
  'chair-director': () => import('./icons/IconChairDirector'),
  'chalkboard-off': () => import('./icons/IconChalkboardOff'),
  'chalkboard': () => import('./icons/IconChalkboard'),
  'charging-pile': () => import('./icons/IconChargingPile'),
  'chart-arcs-3': () => import('./icons/IconChartArcs3'),
  'chart-arcs': () => import('./icons/IconChartArcs'),
  'chart-area-line': () => import('./icons/IconChartAreaLine'),
  'chart-area': () => import('./icons/IconChartArea'),
  'chart-arrows-vertical': () => import('./icons/IconChartArrowsVertical'),
  'chart-arrows': () => import('./icons/IconChartArrows'),
  'chart-bar-off': () => import('./icons/IconChartBarOff'),
  'chart-bar-popular': () => import('./icons/IconChartBarPopular'),
  'chart-bar': () => import('./icons/IconChartBar'),
  'chart-bubble': () => import('./icons/IconChartBubble'),
  'chart-candle': () => import('./icons/IconChartCandle'),
  'chart-circles': () => import('./icons/IconChartCircles'),
  'chart-cohort': () => import('./icons/IconChartCohort'),
  'chart-column': () => import('./icons/IconChartColumn'),
  'chart-covariate': () => import('./icons/IconChartCovariate'),
  'chart-donut-2': () => import('./icons/IconChartDonut2'),
  'chart-donut-3': () => import('./icons/IconChartDonut3'),
  'chart-donut-4': () => import('./icons/IconChartDonut4'),
  'chart-donut': () => import('./icons/IconChartDonut'),
  'chart-dots-2': () => import('./icons/IconChartDots2'),
  'chart-dots-3': () => import('./icons/IconChartDots3'),
  'chart-dots': () => import('./icons/IconChartDots'),
  'chart-funnel': () => import('./icons/IconChartFunnel'),
  'chart-grid-dots': () => import('./icons/IconChartGridDots'),
  'chart-histogram': () => import('./icons/IconChartHistogram'),
  'chart-infographic': () => import('./icons/IconChartInfographic'),
  'chart-line': () => import('./icons/IconChartLine'),
  'chart-pie-2': () => import('./icons/IconChartPie2'),
  'chart-pie-3': () => import('./icons/IconChartPie3'),
  'chart-pie-4': () => import('./icons/IconChartPie4'),
  'chart-pie-off': () => import('./icons/IconChartPieOff'),
  'chart-pie': () => import('./icons/IconChartPie'),
  'chart-ppf': () => import('./icons/IconChartPpf'),
  'chart-radar': () => import('./icons/IconChartRadar'),
  'chart-sankey': () => import('./icons/IconChartSankey'),
  'chart-scatter-3d': () => import('./icons/IconChartScatter3d'),
  'chart-scatter': () => import('./icons/IconChartScatter'),
  'chart-treemap': () => import('./icons/IconChartTreemap'),
  'check': () => import('./icons/IconCheck'),
  'checkbox': () => import('./icons/IconCheckbox'),
  'checklist': () => import('./icons/IconChecklist'),
  'checks': () => import('./icons/IconChecks'),
  'checkup-list': () => import('./icons/IconCheckupList'),
  'cheese': () => import('./icons/IconCheese'),
  'chef-hat-off': () => import('./icons/IconChefHatOff'),
  'chef-hat': () => import('./icons/IconChefHat'),
  'cherry': () => import('./icons/IconCherry'),
  'chess-bishop': () => import('./icons/IconChessBishop'),
  'chess-king': () => import('./icons/IconChessKing'),
  'chess-knight': () => import('./icons/IconChessKnight'),
  'chess-queen': () => import('./icons/IconChessQueen'),
  'chess-rook': () => import('./icons/IconChessRook'),
  'chess': () => import('./icons/IconChess'),
  'chevron-compact-down': () => import('./icons/IconChevronCompactDown'),
  'chevron-compact-left': () => import('./icons/IconChevronCompactLeft'),
  'chevron-compact-right': () => import('./icons/IconChevronCompactRight'),
  'chevron-compact-up': () => import('./icons/IconChevronCompactUp'),
  'chevron-down-left': () => import('./icons/IconChevronDownLeft'),
  'chevron-down-right': () => import('./icons/IconChevronDownRight'),
  'chevron-down': () => import('./icons/IconChevronDown'),
  'chevron-left-pipe': () => import('./icons/IconChevronLeftPipe'),
  'chevron-left': () => import('./icons/IconChevronLeft'),
  'chevron-right-pipe': () => import('./icons/IconChevronRightPipe'),
  'chevron-right': () => import('./icons/IconChevronRight'),
  'chevron-up-left': () => import('./icons/IconChevronUpLeft'),
  'chevron-up-right': () => import('./icons/IconChevronUpRight'),
  'chevron-up': () => import('./icons/IconChevronUp'),
  'chevrons-down-left': () => import('./icons/IconChevronsDownLeft'),
  'chevrons-down-right': () => import('./icons/IconChevronsDownRight'),
  'chevrons-down': () => import('./icons/IconChevronsDown'),
  'chevrons-left': () => import('./icons/IconChevronsLeft'),
  'chevrons-right': () => import('./icons/IconChevronsRight'),
  'chevrons-up-left': () => import('./icons/IconChevronsUpLeft'),
  'chevrons-up-right': () => import('./icons/IconChevronsUpRight'),
  'chevrons-up': () => import('./icons/IconChevronsUp'),
  'chisel': () => import('./icons/IconChisel'),
  'christmas-ball': () => import('./icons/IconChristmasBall'),
  'christmas-tree-off': () => import('./icons/IconChristmasTreeOff'),
  'christmas-tree': () => import('./icons/IconChristmasTree'),
  'circle-arrow-down-left': () => import('./icons/IconCircleArrowDownLeft'),
  'circle-arrow-down-right': () => import('./icons/IconCircleArrowDownRight'),
  'circle-arrow-down': () => import('./icons/IconCircleArrowDown'),
  'circle-arrow-left': () => import('./icons/IconCircleArrowLeft'),
  'circle-arrow-right': () => import('./icons/IconCircleArrowRight'),
  'circle-arrow-up-left': () => import('./icons/IconCircleArrowUpLeft'),
  'circle-arrow-up-right': () => import('./icons/IconCircleArrowUpRight'),
  'circle-arrow-up': () => import('./icons/IconCircleArrowUp'),
  'circle-caret-down': () => import('./icons/IconCircleCaretDown'),
  'circle-caret-left': () => import('./icons/IconCircleCaretLeft'),
  'circle-caret-right': () => import('./icons/IconCircleCaretRight'),
  'circle-caret-up': () => import('./icons/IconCircleCaretUp'),
  'circle-check': () => import('./icons/IconCircleCheck'),
  'circle-chevron-down': () => import('./icons/IconCircleChevronDown'),
  'circle-chevron-left': () => import('./icons/IconCircleChevronLeft'),
  'circle-chevron-right': () => import('./icons/IconCircleChevronRight'),
  'circle-chevron-up': () => import('./icons/IconCircleChevronUp'),
  'circle-chevrons-down': () => import('./icons/IconCircleChevronsDown'),
  'circle-chevrons-left': () => import('./icons/IconCircleChevronsLeft'),
  'circle-chevrons-right': () => import('./icons/IconCircleChevronsRight'),
  'circle-chevrons-up': () => import('./icons/IconCircleChevronsUp'),
  'circle-dashed-check': () => import('./icons/IconCircleDashedCheck'),
  'circle-dashed-letter-a': () => import('./icons/IconCircleDashedLetterA'),
  'circle-dashed-letter-b': () => import('./icons/IconCircleDashedLetterB'),
  'circle-dashed-letter-c': () => import('./icons/IconCircleDashedLetterC'),
  'circle-dashed-letter-d': () => import('./icons/IconCircleDashedLetterD'),
  'circle-dashed-letter-e': () => import('./icons/IconCircleDashedLetterE'),
  'circle-dashed-letter-f': () => import('./icons/IconCircleDashedLetterF'),
  'circle-dashed-letter-g': () => import('./icons/IconCircleDashedLetterG'),
  'circle-dashed-letter-h': () => import('./icons/IconCircleDashedLetterH'),
  'circle-dashed-letter-i': () => import('./icons/IconCircleDashedLetterI'),
  'circle-dashed-letter-j': () => import('./icons/IconCircleDashedLetterJ'),
  'circle-dashed-letter-k': () => import('./icons/IconCircleDashedLetterK'),
  'circle-dashed-letter-l': () => import('./icons/IconCircleDashedLetterL'),
  'circle-dashed-letter-m': () => import('./icons/IconCircleDashedLetterM'),
  'circle-dashed-letter-n': () => import('./icons/IconCircleDashedLetterN'),
  'circle-dashed-letter-o': () => import('./icons/IconCircleDashedLetterO'),
  'circle-dashed-letter-p': () => import('./icons/IconCircleDashedLetterP'),
  'circle-dashed-letter-q': () => import('./icons/IconCircleDashedLetterQ'),
  'circle-dashed-letter-r': () => import('./icons/IconCircleDashedLetterR'),
  'circle-dashed-letter-s': () => import('./icons/IconCircleDashedLetterS'),
  'circle-dashed-letter-t': () => import('./icons/IconCircleDashedLetterT'),
  'circle-dashed-letter-u': () => import('./icons/IconCircleDashedLetterU'),
  'circle-dashed-letter-v': () => import('./icons/IconCircleDashedLetterV'),
  'circle-dashed-letter-w': () => import('./icons/IconCircleDashedLetterW'),
  'circle-dashed-letter-x': () => import('./icons/IconCircleDashedLetterX'),
  'circle-dashed-letter-y': () => import('./icons/IconCircleDashedLetterY'),
  'circle-dashed-letter-z': () => import('./icons/IconCircleDashedLetterZ'),
  'circle-dashed-minus': () => import('./icons/IconCircleDashedMinus'),
  'circle-dashed-number-0': () => import('./icons/IconCircleDashedNumber0'),
  'circle-dashed-number-1': () => import('./icons/IconCircleDashedNumber1'),
  'circle-dashed-number-2': () => import('./icons/IconCircleDashedNumber2'),
  'circle-dashed-number-3': () => import('./icons/IconCircleDashedNumber3'),
  'circle-dashed-number-4': () => import('./icons/IconCircleDashedNumber4'),
  'circle-dashed-number-5': () => import('./icons/IconCircleDashedNumber5'),
  'circle-dashed-number-6': () => import('./icons/IconCircleDashedNumber6'),
  'circle-dashed-number-7': () => import('./icons/IconCircleDashedNumber7'),
  'circle-dashed-number-8': () => import('./icons/IconCircleDashedNumber8'),
  'circle-dashed-number-9': () => import('./icons/IconCircleDashedNumber9'),
  'circle-dashed-percentage': () => import('./icons/IconCircleDashedPercentage'),
  'circle-dashed-plus': () => import('./icons/IconCircleDashedPlus'),
  'circle-dashed-x': () => import('./icons/IconCircleDashedX'),
  'circle-dashed': () => import('./icons/IconCircleDashed'),
  'circle-dot': () => import('./icons/IconCircleDot'),
  'circle-dotted-letter-a': () => import('./icons/IconCircleDottedLetterA'),
  'circle-dotted-letter-b': () => import('./icons/IconCircleDottedLetterB'),
  'circle-dotted-letter-c': () => import('./icons/IconCircleDottedLetterC'),
  'circle-dotted-letter-d': () => import('./icons/IconCircleDottedLetterD'),
  'circle-dotted-letter-e': () => import('./icons/IconCircleDottedLetterE'),
  'circle-dotted-letter-f': () => import('./icons/IconCircleDottedLetterF'),
  'circle-dotted-letter-g': () => import('./icons/IconCircleDottedLetterG'),
  'circle-dotted-letter-h': () => import('./icons/IconCircleDottedLetterH'),
  'circle-dotted-letter-i': () => import('./icons/IconCircleDottedLetterI'),
  'circle-dotted-letter-j': () => import('./icons/IconCircleDottedLetterJ'),
  'circle-dotted-letter-k': () => import('./icons/IconCircleDottedLetterK'),
  'circle-dotted-letter-l': () => import('./icons/IconCircleDottedLetterL'),
  'circle-dotted-letter-m': () => import('./icons/IconCircleDottedLetterM'),
  'circle-dotted-letter-n': () => import('./icons/IconCircleDottedLetterN'),
  'circle-dotted-letter-o': () => import('./icons/IconCircleDottedLetterO'),
  'circle-dotted-letter-p': () => import('./icons/IconCircleDottedLetterP'),
  'circle-dotted-letter-q': () => import('./icons/IconCircleDottedLetterQ'),
  'circle-dotted-letter-r': () => import('./icons/IconCircleDottedLetterR'),
  'circle-dotted-letter-s': () => import('./icons/IconCircleDottedLetterS'),
  'circle-dotted-letter-t': () => import('./icons/IconCircleDottedLetterT'),
  'circle-dotted-letter-u': () => import('./icons/IconCircleDottedLetterU'),
  'circle-dotted-letter-v': () => import('./icons/IconCircleDottedLetterV'),
  'circle-dotted-letter-w': () => import('./icons/IconCircleDottedLetterW'),
  'circle-dotted-letter-x': () => import('./icons/IconCircleDottedLetterX'),
  'circle-dotted-letter-y': () => import('./icons/IconCircleDottedLetterY'),
  'circle-dotted-letter-z': () => import('./icons/IconCircleDottedLetterZ'),
  'circle-dotted': () => import('./icons/IconCircleDotted'),
  'circle-half-2': () => import('./icons/IconCircleHalf2'),
  'circle-half-vertical': () => import('./icons/IconCircleHalfVertical'),
  'circle-half': () => import('./icons/IconCircleHalf'),
  'circle-key': () => import('./icons/IconCircleKey'),
  'circle-letter-a': () => import('./icons/IconCircleLetterA'),
  'circle-letter-b': () => import('./icons/IconCircleLetterB'),
  'circle-letter-c': () => import('./icons/IconCircleLetterC'),
  'circle-letter-d': () => import('./icons/IconCircleLetterD'),
  'circle-letter-e': () => import('./icons/IconCircleLetterE'),
  'circle-letter-f': () => import('./icons/IconCircleLetterF'),
  'circle-letter-g': () => import('./icons/IconCircleLetterG'),
  'circle-letter-h': () => import('./icons/IconCircleLetterH'),
  'circle-letter-i': () => import('./icons/IconCircleLetterI'),
  'circle-letter-j': () => import('./icons/IconCircleLetterJ'),
  'circle-letter-k': () => import('./icons/IconCircleLetterK'),
  'circle-letter-l': () => import('./icons/IconCircleLetterL'),
  'circle-letter-m': () => import('./icons/IconCircleLetterM'),
  'circle-letter-n': () => import('./icons/IconCircleLetterN'),
  'circle-letter-o': () => import('./icons/IconCircleLetterO'),
  'circle-letter-p': () => import('./icons/IconCircleLetterP'),
  'circle-letter-q': () => import('./icons/IconCircleLetterQ'),
  'circle-letter-r': () => import('./icons/IconCircleLetterR'),
  'circle-letter-s': () => import('./icons/IconCircleLetterS'),
  'circle-letter-t': () => import('./icons/IconCircleLetterT'),
  'circle-letter-u': () => import('./icons/IconCircleLetterU'),
  'circle-letter-v': () => import('./icons/IconCircleLetterV'),
  'circle-letter-w': () => import('./icons/IconCircleLetterW'),
  'circle-letter-x': () => import('./icons/IconCircleLetterX'),
  'circle-letter-y': () => import('./icons/IconCircleLetterY'),
  'circle-letter-z': () => import('./icons/IconCircleLetterZ'),
  'circle-minus-2': () => import('./icons/IconCircleMinus2'),
  'circle-minus': () => import('./icons/IconCircleMinus'),
  'circle-number-0': () => import('./icons/IconCircleNumber0'),
  'circle-number-1': () => import('./icons/IconCircleNumber1'),
  'circle-number-2': () => import('./icons/IconCircleNumber2'),
  'circle-number-3': () => import('./icons/IconCircleNumber3'),
  'circle-number-4': () => import('./icons/IconCircleNumber4'),
  'circle-number-5': () => import('./icons/IconCircleNumber5'),
  'circle-number-6': () => import('./icons/IconCircleNumber6'),
  'circle-number-7': () => import('./icons/IconCircleNumber7'),
  'circle-number-8': () => import('./icons/IconCircleNumber8'),
  'circle-number-9': () => import('./icons/IconCircleNumber9'),
  'circle-off': () => import('./icons/IconCircleOff'),
  'circle-percentage': () => import('./icons/IconCirclePercentage'),
  'circle-plus-2': () => import('./icons/IconCirclePlus2'),
  'circle-plus': () => import('./icons/IconCirclePlus'),
  'circle-rectangle-off': () => import('./icons/IconCircleRectangleOff'),
  'circle-rectangle': () => import('./icons/IconCircleRectangle'),
  'circle-square': () => import('./icons/IconCircleSquare'),
  'circle-triangle': () => import('./icons/IconCircleTriangle'),
  'circle-x': () => import('./icons/IconCircleX'),
  'circle': () => import('./icons/IconCircle'),
  'circles-relation': () => import('./icons/IconCirclesRelation'),
  'circles': () => import('./icons/IconCircles'),
  'circuit-ammeter': () => import('./icons/IconCircuitAmmeter'),
  'circuit-battery': () => import('./icons/IconCircuitBattery'),
  'circuit-bulb': () => import('./icons/IconCircuitBulb'),
  'circuit-capacitor-polarized': () => import('./icons/IconCircuitCapacitorPolarized'),
  'circuit-capacitor': () => import('./icons/IconCircuitCapacitor'),
  'circuit-cell-plus': () => import('./icons/IconCircuitCellPlus'),
  'circuit-cell': () => import('./icons/IconCircuitCell'),
  'circuit-changeover': () => import('./icons/IconCircuitChangeover'),
  'circuit-diode-zener': () => import('./icons/IconCircuitDiodeZener'),
  'circuit-diode': () => import('./icons/IconCircuitDiode'),
  'circuit-ground-digital': () => import('./icons/IconCircuitGroundDigital'),
  'circuit-ground': () => import('./icons/IconCircuitGround'),
  'circuit-inductor': () => import('./icons/IconCircuitInductor'),
  'circuit-motor': () => import('./icons/IconCircuitMotor'),
  'circuit-pushbutton': () => import('./icons/IconCircuitPushbutton'),
  'circuit-resistor': () => import('./icons/IconCircuitResistor'),
  'circuit-switch-closed': () => import('./icons/IconCircuitSwitchClosed'),
  'circuit-switch-open': () => import('./icons/IconCircuitSwitchOpen'),
  'circuit-voltmeter': () => import('./icons/IconCircuitVoltmeter'),
  'clear-all': () => import('./icons/IconClearAll'),
  'clear-formatting': () => import('./icons/IconClearFormatting'),
  'click': () => import('./icons/IconClick'),
  'cliff-jumping': () => import('./icons/IconCliffJumping'),
  'clipboard-check': () => import('./icons/IconClipboardCheck'),
  'clipboard-copy': () => import('./icons/IconClipboardCopy'),
  'clipboard-data': () => import('./icons/IconClipboardData'),
  'clipboard-heart': () => import('./icons/IconClipboardHeart'),
  'clipboard-list': () => import('./icons/IconClipboardList'),
  'clipboard-off': () => import('./icons/IconClipboardOff'),
  'clipboard-plus': () => import('./icons/IconClipboardPlus'),
  'clipboard-smile': () => import('./icons/IconClipboardSmile'),
  'clipboard-text': () => import('./icons/IconClipboardText'),
  'clipboard-typography': () => import('./icons/IconClipboardTypography'),
  'clipboard-x': () => import('./icons/IconClipboardX'),
  'clipboard': () => import('./icons/IconClipboard'),
  'clock-12': () => import('./icons/IconClock12'),
  'clock-2': () => import('./icons/IconClock2'),
  'clock-24': () => import('./icons/IconClock24'),
  'clock-bitcoin': () => import('./icons/IconClockBitcoin'),
  'clock-bolt': () => import('./icons/IconClockBolt'),
  'clock-cancel': () => import('./icons/IconClockCancel'),
  'clock-check': () => import('./icons/IconClockCheck'),
  'clock-code': () => import('./icons/IconClockCode'),
  'clock-cog': () => import('./icons/IconClockCog'),
  'clock-dollar': () => import('./icons/IconClockDollar'),
  'clock-down': () => import('./icons/IconClockDown'),
  'clock-edit': () => import('./icons/IconClockEdit'),
  'clock-exclamation': () => import('./icons/IconClockExclamation'),
  'clock-heart': () => import('./icons/IconClockHeart'),
  'clock-hour-1': () => import('./icons/IconClockHour1'),
  'clock-hour-10': () => import('./icons/IconClockHour10'),
  'clock-hour-11': () => import('./icons/IconClockHour11'),
  'clock-hour-12': () => import('./icons/IconClockHour12'),
  'clock-hour-2': () => import('./icons/IconClockHour2'),
  'clock-hour-3': () => import('./icons/IconClockHour3'),
  'clock-hour-4': () => import('./icons/IconClockHour4'),
  'clock-hour-5': () => import('./icons/IconClockHour5'),
  'clock-hour-6': () => import('./icons/IconClockHour6'),
  'clock-hour-7': () => import('./icons/IconClockHour7'),
  'clock-hour-8': () => import('./icons/IconClockHour8'),
  'clock-hour-9': () => import('./icons/IconClockHour9'),
  'clock-minus': () => import('./icons/IconClockMinus'),
  'clock-off': () => import('./icons/IconClockOff'),
  'clock-pause': () => import('./icons/IconClockPause'),
  'clock-pin': () => import('./icons/IconClockPin'),
  'clock-play': () => import('./icons/IconClockPlay'),
  'clock-plus': () => import('./icons/IconClockPlus'),
  'clock-question': () => import('./icons/IconClockQuestion'),
  'clock-record': () => import('./icons/IconClockRecord'),
  'clock-search': () => import('./icons/IconClockSearch'),
  'clock-share': () => import('./icons/IconClockShare'),
  'clock-shield': () => import('./icons/IconClockShield'),
  'clock-star': () => import('./icons/IconClockStar'),
  'clock-stop': () => import('./icons/IconClockStop'),
  'clock-up': () => import('./icons/IconClockUp'),
  'clock-x': () => import('./icons/IconClockX'),
  'clock': () => import('./icons/IconClock'),
  'clothes-rack-off': () => import('./icons/IconClothesRackOff'),
  'clothes-rack': () => import('./icons/IconClothesRack'),
  'cloud-bitcoin': () => import('./icons/IconCloudBitcoin'),
  'cloud-bolt': () => import('./icons/IconCloudBolt'),
  'cloud-cancel': () => import('./icons/IconCloudCancel'),
  'cloud-check': () => import('./icons/IconCloudCheck'),
  'cloud-code': () => import('./icons/IconCloudCode'),
  'cloud-cog': () => import('./icons/IconCloudCog'),
  'cloud-computing': () => import('./icons/IconCloudComputing'),
  'cloud-data-connection': () => import('./icons/IconCloudDataConnection'),
  'cloud-dollar': () => import('./icons/IconCloudDollar'),
  'cloud-down': () => import('./icons/IconCloudDown'),
  'cloud-download': () => import('./icons/IconCloudDownload'),
  'cloud-exclamation': () => import('./icons/IconCloudExclamation'),
  'cloud-fog': () => import('./icons/IconCloudFog'),
  'cloud-heart': () => import('./icons/IconCloudHeart'),
  'cloud-lock-open': () => import('./icons/IconCloudLockOpen'),
  'cloud-lock': () => import('./icons/IconCloudLock'),
  'cloud-minus': () => import('./icons/IconCloudMinus'),
  'cloud-network': () => import('./icons/IconCloudNetwork'),
  'cloud-off': () => import('./icons/IconCloudOff'),
  'cloud-pause': () => import('./icons/IconCloudPause'),
  'cloud-pin': () => import('./icons/IconCloudPin'),
  'cloud-plus': () => import('./icons/IconCloudPlus'),
  'cloud-question': () => import('./icons/IconCloudQuestion'),
  'cloud-rain': () => import('./icons/IconCloudRain'),
  'cloud-search': () => import('./icons/IconCloudSearch'),
  'cloud-share': () => import('./icons/IconCloudShare'),
  'cloud-snow': () => import('./icons/IconCloudSnow'),
  'cloud-star': () => import('./icons/IconCloudStar'),
  'cloud-storm': () => import('./icons/IconCloudStorm'),
  'cloud-up': () => import('./icons/IconCloudUp'),
  'cloud-upload': () => import('./icons/IconCloudUpload'),
  'cloud-x': () => import('./icons/IconCloudX'),
  'cloud': () => import('./icons/IconCloud'),
  'clover-2': () => import('./icons/IconClover2'),
  'clover': () => import('./icons/IconClover'),
  'clubs': () => import('./icons/IconClubs'),
  'code-asterisk': () => import('./icons/IconCodeAsterisk'),
  'code-circle-2': () => import('./icons/IconCodeCircle2'),
  'code-circle': () => import('./icons/IconCodeCircle'),
  'code-dots': () => import('./icons/IconCodeDots'),
  'code-minus': () => import('./icons/IconCodeMinus'),
  'code-off': () => import('./icons/IconCodeOff'),
  'code-plus': () => import('./icons/IconCodePlus'),
  'code': () => import('./icons/IconCode'),
  'coffee-off': () => import('./icons/IconCoffeeOff'),
  'coffee': () => import('./icons/IconCoffee'),
  'coffin': () => import('./icons/IconCoffin'),
  'coin-bitcoin': () => import('./icons/IconCoinBitcoin'),
  'coin-euro': () => import('./icons/IconCoinEuro'),
  'coin-monero': () => import('./icons/IconCoinMonero'),
  'coin-off': () => import('./icons/IconCoinOff'),
  'coin-pound': () => import('./icons/IconCoinPound'),
  'coin-rupee': () => import('./icons/IconCoinRupee'),
  'coin-taka': () => import('./icons/IconCoinTaka'),
  'coin-yen': () => import('./icons/IconCoinYen'),
  'coin-yuan': () => import('./icons/IconCoinYuan'),
  'coin': () => import('./icons/IconCoin'),
  'coins': () => import('./icons/IconCoins'),
  'color-filter': () => import('./icons/IconColorFilter'),
  'color-picker-off': () => import('./icons/IconColorPickerOff'),
  'color-picker': () => import('./icons/IconColorPicker'),
  'color-swatch-off': () => import('./icons/IconColorSwatchOff'),
  'color-swatch': () => import('./icons/IconColorSwatch'),
  'column-insert-left': () => import('./icons/IconColumnInsertLeft'),
  'column-insert-right': () => import('./icons/IconColumnInsertRight'),
  'column-remove': () => import('./icons/IconColumnRemove'),
  'columns-1': () => import('./icons/IconColumns1'),
  'columns-2': () => import('./icons/IconColumns2'),
  'columns-3': () => import('./icons/IconColumns3'),
  'columns-off': () => import('./icons/IconColumnsOff'),
  'columns': () => import('./icons/IconColumns'),
  'comet': () => import('./icons/IconComet'),
  'command-off': () => import('./icons/IconCommandOff'),
  'command': () => import('./icons/IconCommand'),
  'compass-off': () => import('./icons/IconCompassOff'),
  'compass': () => import('./icons/IconCompass'),
  'components-off': () => import('./icons/IconComponentsOff'),
  'components': () => import('./icons/IconComponents'),
  'cone-2': () => import('./icons/IconCone2'),
  'cone-off': () => import('./icons/IconConeOff'),
  'cone-plus': () => import('./icons/IconConePlus'),
  'cone': () => import('./icons/IconCone'),
  'confetti-off': () => import('./icons/IconConfettiOff'),
  'confetti': () => import('./icons/IconConfetti'),
  'confucius': () => import('./icons/IconConfucius'),
  'congruent-to': () => import('./icons/IconCongruentTo'),
  'container-off': () => import('./icons/IconContainerOff'),
  'container': () => import('./icons/IconContainer'),
  'contract': () => import('./icons/IconContract'),
  'contrast-2-off': () => import('./icons/IconContrast2Off'),
  'contrast-2': () => import('./icons/IconContrast2'),
  'contrast-off': () => import('./icons/IconContrastOff'),
  'contrast': () => import('./icons/IconContrast'),
  'cooker': () => import('./icons/IconCooker'),
  'cookie-man': () => import('./icons/IconCookieMan'),
  'cookie-off': () => import('./icons/IconCookieOff'),
  'cookie': () => import('./icons/IconCookie'),
  'copy-check': () => import('./icons/IconCopyCheck'),
  'copy-minus': () => import('./icons/IconCopyMinus'),
  'copy-off': () => import('./icons/IconCopyOff'),
  'copy-plus': () => import('./icons/IconCopyPlus'),
  'copy-x': () => import('./icons/IconCopyX'),
  'copy': () => import('./icons/IconCopy'),
  'copyleft-off': () => import('./icons/IconCopyleftOff'),
  'copyleft': () => import('./icons/IconCopyleft'),
  'copyright-off': () => import('./icons/IconCopyrightOff'),
  'copyright': () => import('./icons/IconCopyright'),
  'corner-down-left-double': () => import('./icons/IconCornerDownLeftDouble'),
  'corner-down-left': () => import('./icons/IconCornerDownLeft'),
  'corner-down-right-double': () => import('./icons/IconCornerDownRightDouble'),
  'corner-down-right': () => import('./icons/IconCornerDownRight'),
  'corner-left-down-double': () => import('./icons/IconCornerLeftDownDouble'),
  'corner-left-down': () => import('./icons/IconCornerLeftDown'),
  'corner-left-up-double': () => import('./icons/IconCornerLeftUpDouble'),
  'corner-left-up': () => import('./icons/IconCornerLeftUp'),
  'corner-right-down-double': () => import('./icons/IconCornerRightDownDouble'),
  'corner-right-down': () => import('./icons/IconCornerRightDown'),
  'corner-right-up-double': () => import('./icons/IconCornerRightUpDouble'),
  'corner-right-up': () => import('./icons/IconCornerRightUp'),
  'corner-up-left-double': () => import('./icons/IconCornerUpLeftDouble'),
  'corner-up-left': () => import('./icons/IconCornerUpLeft'),
  'corner-up-right-double': () => import('./icons/IconCornerUpRightDouble'),
  'corner-up-right': () => import('./icons/IconCornerUpRight'),
  'cpu-2': () => import('./icons/IconCpu2'),
  'cpu-off': () => import('./icons/IconCpuOff'),
  'cpu': () => import('./icons/IconCpu'),
  'crane-off': () => import('./icons/IconCraneOff'),
  'crane': () => import('./icons/IconCrane'),
  'creative-commons-by': () => import('./icons/IconCreativeCommonsBy'),
  'creative-commons-nc': () => import('./icons/IconCreativeCommonsNc'),
  'creative-commons-nd': () => import('./icons/IconCreativeCommonsNd'),
  'creative-commons-off': () => import('./icons/IconCreativeCommonsOff'),
  'creative-commons-sa': () => import('./icons/IconCreativeCommonsSa'),
  'creative-commons-zero': () => import('./icons/IconCreativeCommonsZero'),
  'creative-commons': () => import('./icons/IconCreativeCommons'),
  'credit-card-off': () => import('./icons/IconCreditCardOff'),
  'credit-card-pay': () => import('./icons/IconCreditCardPay'),
  'credit-card-refund': () => import('./icons/IconCreditCardRefund'),
  'credit-card': () => import('./icons/IconCreditCard'),
  'cricket': () => import('./icons/IconCricket'),
  'crop-1-1': () => import('./icons/IconCrop11'),
  'crop-16-9': () => import('./icons/IconCrop169'),
  'crop-3-2': () => import('./icons/IconCrop32'),
  'crop-5-4': () => import('./icons/IconCrop54'),
  'crop-7-5': () => import('./icons/IconCrop75'),
  'crop-landscape': () => import('./icons/IconCropLandscape'),
  'crop-portrait': () => import('./icons/IconCropPortrait'),
  'crop': () => import('./icons/IconCrop'),
  'cross-off': () => import('./icons/IconCrossOff'),
  'cross': () => import('./icons/IconCross'),
  'crosshair': () => import('./icons/IconCrosshair'),
  'crown-off': () => import('./icons/IconCrownOff'),
  'crown': () => import('./icons/IconCrown'),
  'crutches-off': () => import('./icons/IconCrutchesOff'),
  'crutches': () => import('./icons/IconCrutches'),
  'crystal-ball': () => import('./icons/IconCrystalBall'),
  'csv': () => import('./icons/IconCsv'),
  'cube-3d-sphere-off': () => import('./icons/IconCube3dSphereOff'),
  'cube-3d-sphere': () => import('./icons/IconCube3dSphere'),
  'cube-off': () => import('./icons/IconCubeOff'),
  'cube-plus': () => import('./icons/IconCubePlus'),
  'cube-send': () => import('./icons/IconCubeSend'),
  'cube-spark': () => import('./icons/IconCubeSpark'),
  'cube-unfolded': () => import('./icons/IconCubeUnfolded'),
  'cube': () => import('./icons/IconCube'),
  'cup-off': () => import('./icons/IconCupOff'),
  'cup': () => import('./icons/IconCup'),
  'curling': () => import('./icons/IconCurling'),
  'curly-loop': () => import('./icons/IconCurlyLoop'),
  'currency-afghani': () => import('./icons/IconCurrencyAfghani'),
  'currency-bahraini': () => import('./icons/IconCurrencyBahraini'),
  'currency-baht': () => import('./icons/IconCurrencyBaht'),
  'currency-bitcoin': () => import('./icons/IconCurrencyBitcoin'),
  'currency-cent': () => import('./icons/IconCurrencyCent'),
  'currency-dinar': () => import('./icons/IconCurrencyDinar'),
  'currency-dirham': () => import('./icons/IconCurrencyDirham'),
  'currency-dogecoin': () => import('./icons/IconCurrencyDogecoin'),
  'currency-dollar-australian': () => import('./icons/IconCurrencyDollarAustralian'),
  'currency-dollar-brunei': () => import('./icons/IconCurrencyDollarBrunei'),
  'currency-dollar-canadian': () => import('./icons/IconCurrencyDollarCanadian'),
  'currency-dollar-guyanese': () => import('./icons/IconCurrencyDollarGuyanese'),
  'currency-dollar-off': () => import('./icons/IconCurrencyDollarOff'),
  'currency-dollar-singapore': () => import('./icons/IconCurrencyDollarSingapore'),
  'currency-dollar-zimbabwean': () => import('./icons/IconCurrencyDollarZimbabwean'),
  'currency-dollar': () => import('./icons/IconCurrencyDollar'),
  'currency-dong': () => import('./icons/IconCurrencyDong'),
  'currency-dram': () => import('./icons/IconCurrencyDram'),
  'currency-ethereum': () => import('./icons/IconCurrencyEthereum'),
  'currency-euro-off': () => import('./icons/IconCurrencyEuroOff'),
  'currency-euro': () => import('./icons/IconCurrencyEuro'),
  'currency-florin': () => import('./icons/IconCurrencyFlorin'),
  'currency-forint': () => import('./icons/IconCurrencyForint'),
  'currency-frank': () => import('./icons/IconCurrencyFrank'),
  'currency-guarani': () => import('./icons/IconCurrencyGuarani'),
  'currency-hryvnia': () => import('./icons/IconCurrencyHryvnia'),
  'currency-iranian-rial': () => import('./icons/IconCurrencyIranianRial'),
  'currency-kip': () => import('./icons/IconCurrencyKip'),
  'currency-krone-czech': () => import('./icons/IconCurrencyKroneCzech'),
  'currency-krone-danish': () => import('./icons/IconCurrencyKroneDanish'),
  'currency-krone-swedish': () => import('./icons/IconCurrencyKroneSwedish'),
  'currency-lari': () => import('./icons/IconCurrencyLari'),
  'currency-leu': () => import('./icons/IconCurrencyLeu'),
  'currency-lira': () => import('./icons/IconCurrencyLira'),
  'currency-litecoin': () => import('./icons/IconCurrencyLitecoin'),
  'currency-lyd': () => import('./icons/IconCurrencyLyd'),
  'currency-manat': () => import('./icons/IconCurrencyManat'),
  'currency-monero': () => import('./icons/IconCurrencyMonero'),
  'currency-naira': () => import('./icons/IconCurrencyNaira'),
  'currency-nano': () => import('./icons/IconCurrencyNano'),
  'currency-off': () => import('./icons/IconCurrencyOff'),
  'currency-paanga': () => import('./icons/IconCurrencyPaanga'),
  'currency-peso': () => import('./icons/IconCurrencyPeso'),
  'currency-pound-off': () => import('./icons/IconCurrencyPoundOff'),
  'currency-pound': () => import('./icons/IconCurrencyPound'),
  'currency-quetzal': () => import('./icons/IconCurrencyQuetzal'),
  'currency-real': () => import('./icons/IconCurrencyReal'),
  'currency-renminbi': () => import('./icons/IconCurrencyRenminbi'),
  'currency-ripple': () => import('./icons/IconCurrencyRipple'),
  'currency-riyal': () => import('./icons/IconCurrencyRiyal'),
  'currency-rubel': () => import('./icons/IconCurrencyRubel'),
  'currency-rufiyaa': () => import('./icons/IconCurrencyRufiyaa'),
  'currency-rupee-nepalese': () => import('./icons/IconCurrencyRupeeNepalese'),
  'currency-rupee': () => import('./icons/IconCurrencyRupee'),
  'currency-shekel': () => import('./icons/IconCurrencyShekel'),
  'currency-solana': () => import('./icons/IconCurrencySolana'),
  'currency-som': () => import('./icons/IconCurrencySom'),
  'currency-taka': () => import('./icons/IconCurrencyTaka'),
  'currency-tenge': () => import('./icons/IconCurrencyTenge'),
  'currency-tugrik': () => import('./icons/IconCurrencyTugrik'),
  'currency-won': () => import('./icons/IconCurrencyWon'),
  'currency-xrp': () => import('./icons/IconCurrencyXrp'),
  'currency-yen-off': () => import('./icons/IconCurrencyYenOff'),
  'currency-yen': () => import('./icons/IconCurrencyYen'),
  'currency-yuan': () => import('./icons/IconCurrencyYuan'),
  'currency-zloty': () => import('./icons/IconCurrencyZloty'),
  'currency': () => import('./icons/IconCurrency'),
  'current-location-off': () => import('./icons/IconCurrentLocationOff'),
  'current-location': () => import('./icons/IconCurrentLocation'),
  'cursor-off': () => import('./icons/IconCursorOff'),
  'cursor-text': () => import('./icons/IconCursorText'),
  'cut': () => import('./icons/IconCut'),
  'cylinder-off': () => import('./icons/IconCylinderOff'),
  'cylinder-plus': () => import('./icons/IconCylinderPlus'),
  'cylinder': () => import('./icons/IconCylinder'),
  'dashboard-off': () => import('./icons/IconDashboardOff'),
  'dashboard': () => import('./icons/IconDashboard'),
  'database-cog': () => import('./icons/IconDatabaseCog'),
  'database-dollar': () => import('./icons/IconDatabaseDollar'),
  'database-edit': () => import('./icons/IconDatabaseEdit'),
  'database-exclamation': () => import('./icons/IconDatabaseExclamation'),
  'database-export': () => import('./icons/IconDatabaseExport'),
  'database-heart': () => import('./icons/IconDatabaseHeart'),
  'database-import': () => import('./icons/IconDatabaseImport'),
  'database-leak': () => import('./icons/IconDatabaseLeak'),
  'database-minus': () => import('./icons/IconDatabaseMinus'),
  'database-off': () => import('./icons/IconDatabaseOff'),
  'database-plus': () => import('./icons/IconDatabasePlus'),
  'database-search': () => import('./icons/IconDatabaseSearch'),
  'database-share': () => import('./icons/IconDatabaseShare'),
  'database-smile': () => import('./icons/IconDatabaseSmile'),
  'database-star': () => import('./icons/IconDatabaseStar'),
  'database-x': () => import('./icons/IconDatabaseX'),
  'database': () => import('./icons/IconDatabase'),
  'decimal': () => import('./icons/IconDecimal'),
  'deer': () => import('./icons/IconDeer'),
  'delta': () => import('./icons/IconDelta'),
  'dental-broken': () => import('./icons/IconDentalBroken'),
  'dental-off': () => import('./icons/IconDentalOff'),
  'dental': () => import('./icons/IconDental'),
  'deselect': () => import('./icons/IconDeselect'),
  'desk': () => import('./icons/IconDesk'),
  'details-off': () => import('./icons/IconDetailsOff'),
  'details': () => import('./icons/IconDetails'),
  'device-airpods-case': () => import('./icons/IconDeviceAirpodsCase'),
  'device-airpods': () => import('./icons/IconDeviceAirpods'),
  'device-airtag': () => import('./icons/IconDeviceAirtag'),
  'device-analytics': () => import('./icons/IconDeviceAnalytics'),
  'device-audio-tape': () => import('./icons/IconDeviceAudioTape'),
  'device-camera-phone': () => import('./icons/IconDeviceCameraPhone'),
  'device-cctv-off': () => import('./icons/IconDeviceCctvOff'),
  'device-cctv': () => import('./icons/IconDeviceCctv'),
  'device-computer-camera-off': () => import('./icons/IconDeviceComputerCameraOff'),
  'device-computer-camera': () => import('./icons/IconDeviceComputerCamera'),
  'device-desktop-analytics': () => import('./icons/IconDeviceDesktopAnalytics'),
  'device-desktop-bolt': () => import('./icons/IconDeviceDesktopBolt'),
  'device-desktop-cancel': () => import('./icons/IconDeviceDesktopCancel'),
  'device-desktop-check': () => import('./icons/IconDeviceDesktopCheck'),
  'device-desktop-code': () => import('./icons/IconDeviceDesktopCode'),
  'device-desktop-cog': () => import('./icons/IconDeviceDesktopCog'),
  'device-desktop-dollar': () => import('./icons/IconDeviceDesktopDollar'),
  'device-desktop-down': () => import('./icons/IconDeviceDesktopDown'),
  'device-desktop-exclamation': () => import('./icons/IconDeviceDesktopExclamation'),
  'device-desktop-heart': () => import('./icons/IconDeviceDesktopHeart'),
  'device-desktop-minus': () => import('./icons/IconDeviceDesktopMinus'),
  'device-desktop-off': () => import('./icons/IconDeviceDesktopOff'),
  'device-desktop-pause': () => import('./icons/IconDeviceDesktopPause'),
  'device-desktop-pin': () => import('./icons/IconDeviceDesktopPin'),
  'device-desktop-plus': () => import('./icons/IconDeviceDesktopPlus'),
  'device-desktop-question': () => import('./icons/IconDeviceDesktopQuestion'),
  'device-desktop-search': () => import('./icons/IconDeviceDesktopSearch'),
  'device-desktop-share': () => import('./icons/IconDeviceDesktopShare'),
  'device-desktop-star': () => import('./icons/IconDeviceDesktopStar'),
  'device-desktop-up': () => import('./icons/IconDeviceDesktopUp'),
  'device-desktop-x': () => import('./icons/IconDeviceDesktopX'),
  'device-desktop': () => import('./icons/IconDeviceDesktop'),
  'device-floppy': () => import('./icons/IconDeviceFloppy'),
  'device-gamepad-2': () => import('./icons/IconDeviceGamepad2'),
  'device-gamepad-3': () => import('./icons/IconDeviceGamepad3'),
  'device-gamepad': () => import('./icons/IconDeviceGamepad'),
  'device-heart-monitor': () => import('./icons/IconDeviceHeartMonitor'),
  'device-imac-bolt': () => import('./icons/IconDeviceImacBolt'),
  'device-imac-cancel': () => import('./icons/IconDeviceImacCancel'),
  'device-imac-check': () => import('./icons/IconDeviceImacCheck'),
  'device-imac-code': () => import('./icons/IconDeviceImacCode'),
  'device-imac-cog': () => import('./icons/IconDeviceImacCog'),
  'device-imac-dollar': () => import('./icons/IconDeviceImacDollar'),
  'device-imac-down': () => import('./icons/IconDeviceImacDown'),
  'device-imac-exclamation': () => import('./icons/IconDeviceImacExclamation'),
  'device-imac-heart': () => import('./icons/IconDeviceImacHeart'),
  'device-imac-minus': () => import('./icons/IconDeviceImacMinus'),
  'device-imac-off': () => import('./icons/IconDeviceImacOff'),
  'device-imac-pause': () => import('./icons/IconDeviceImacPause'),
  'device-imac-pin': () => import('./icons/IconDeviceImacPin'),
  'device-imac-plus': () => import('./icons/IconDeviceImacPlus'),
  'device-imac-question': () => import('./icons/IconDeviceImacQuestion'),
  'device-imac-search': () => import('./icons/IconDeviceImacSearch'),
  'device-imac-share': () => import('./icons/IconDeviceImacShare'),
  'device-imac-star': () => import('./icons/IconDeviceImacStar'),
  'device-imac-up': () => import('./icons/IconDeviceImacUp'),
  'device-imac-x': () => import('./icons/IconDeviceImacX'),
  'device-imac': () => import('./icons/IconDeviceImac'),
  'device-ipad-bolt': () => import('./icons/IconDeviceIpadBolt'),
  'device-ipad-cancel': () => import('./icons/IconDeviceIpadCancel'),
  'device-ipad-check': () => import('./icons/IconDeviceIpadCheck'),
  'device-ipad-code': () => import('./icons/IconDeviceIpadCode'),
  'device-ipad-cog': () => import('./icons/IconDeviceIpadCog'),
  'device-ipad-dollar': () => import('./icons/IconDeviceIpadDollar'),
  'device-ipad-down': () => import('./icons/IconDeviceIpadDown'),
  'device-ipad-exclamation': () => import('./icons/IconDeviceIpadExclamation'),
  'device-ipad-heart': () => import('./icons/IconDeviceIpadHeart'),
  'device-ipad-horizontal-bolt': () => import('./icons/IconDeviceIpadHorizontalBolt'),
  'device-ipad-horizontal-cancel': () => import('./icons/IconDeviceIpadHorizontalCancel'),
  'device-ipad-horizontal-check': () => import('./icons/IconDeviceIpadHorizontalCheck'),
  'device-ipad-horizontal-code': () => import('./icons/IconDeviceIpadHorizontalCode'),
  'device-ipad-horizontal-cog': () => import('./icons/IconDeviceIpadHorizontalCog'),
  'device-ipad-horizontal-dollar': () => import('./icons/IconDeviceIpadHorizontalDollar'),
  'device-ipad-horizontal-down': () => import('./icons/IconDeviceIpadHorizontalDown'),
  'device-ipad-horizontal-exclamation': () => import('./icons/IconDeviceIpadHorizontalExclamation'),
  'device-ipad-horizontal-heart': () => import('./icons/IconDeviceIpadHorizontalHeart'),
  'device-ipad-horizontal-minus': () => import('./icons/IconDeviceIpadHorizontalMinus'),
  'device-ipad-horizontal-off': () => import('./icons/IconDeviceIpadHorizontalOff'),
  'device-ipad-horizontal-pause': () => import('./icons/IconDeviceIpadHorizontalPause'),
  'device-ipad-horizontal-pin': () => import('./icons/IconDeviceIpadHorizontalPin'),
  'device-ipad-horizontal-plus': () => import('./icons/IconDeviceIpadHorizontalPlus'),
  'device-ipad-horizontal-question': () => import('./icons/IconDeviceIpadHorizontalQuestion'),
  'device-ipad-horizontal-search': () => import('./icons/IconDeviceIpadHorizontalSearch'),
  'device-ipad-horizontal-share': () => import('./icons/IconDeviceIpadHorizontalShare'),
  'device-ipad-horizontal-star': () => import('./icons/IconDeviceIpadHorizontalStar'),
  'device-ipad-horizontal-up': () => import('./icons/IconDeviceIpadHorizontalUp'),
  'device-ipad-horizontal-x': () => import('./icons/IconDeviceIpadHorizontalX'),
  'device-ipad-horizontal': () => import('./icons/IconDeviceIpadHorizontal'),
  'device-ipad-minus': () => import('./icons/IconDeviceIpadMinus'),
  'device-ipad-off': () => import('./icons/IconDeviceIpadOff'),
  'device-ipad-pause': () => import('./icons/IconDeviceIpadPause'),
  'device-ipad-pin': () => import('./icons/IconDeviceIpadPin'),
  'device-ipad-plus': () => import('./icons/IconDeviceIpadPlus'),
  'device-ipad-question': () => import('./icons/IconDeviceIpadQuestion'),
  'device-ipad-search': () => import('./icons/IconDeviceIpadSearch'),
  'device-ipad-share': () => import('./icons/IconDeviceIpadShare'),
  'device-ipad-star': () => import('./icons/IconDeviceIpadStar'),
  'device-ipad-up': () => import('./icons/IconDeviceIpadUp'),
  'device-ipad-x': () => import('./icons/IconDeviceIpadX'),
  'device-ipad': () => import('./icons/IconDeviceIpad'),
  'device-landline-phone': () => import('./icons/IconDeviceLandlinePhone'),
  'device-laptop-off': () => import('./icons/IconDeviceLaptopOff'),
  'device-laptop': () => import('./icons/IconDeviceLaptop'),
  'device-mobile-bolt': () => import('./icons/IconDeviceMobileBolt'),
  'device-mobile-cancel': () => import('./icons/IconDeviceMobileCancel'),
  'device-mobile-charging': () => import('./icons/IconDeviceMobileCharging'),
  'device-mobile-check': () => import('./icons/IconDeviceMobileCheck'),
  'device-mobile-code': () => import('./icons/IconDeviceMobileCode'),
  'device-mobile-cog': () => import('./icons/IconDeviceMobileCog'),
  'device-mobile-dollar': () => import('./icons/IconDeviceMobileDollar'),
  'device-mobile-down': () => import('./icons/IconDeviceMobileDown'),
  'device-mobile-exclamation': () => import('./icons/IconDeviceMobileExclamation'),
  'device-mobile-heart': () => import('./icons/IconDeviceMobileHeart'),
  'device-mobile-message': () => import('./icons/IconDeviceMobileMessage'),
  'device-mobile-minus': () => import('./icons/IconDeviceMobileMinus'),
  'device-mobile-off': () => import('./icons/IconDeviceMobileOff'),
  'device-mobile-pause': () => import('./icons/IconDeviceMobilePause'),
  'device-mobile-pin': () => import('./icons/IconDeviceMobilePin'),
  'device-mobile-plus': () => import('./icons/IconDeviceMobilePlus'),
  'device-mobile-question': () => import('./icons/IconDeviceMobileQuestion'),
  'device-mobile-rotated': () => import('./icons/IconDeviceMobileRotated'),
  'device-mobile-search': () => import('./icons/IconDeviceMobileSearch'),
  'device-mobile-share': () => import('./icons/IconDeviceMobileShare'),
  'device-mobile-star': () => import('./icons/IconDeviceMobileStar'),
  'device-mobile-up': () => import('./icons/IconDeviceMobileUp'),
  'device-mobile-vibration': () => import('./icons/IconDeviceMobileVibration'),
  'device-mobile-x': () => import('./icons/IconDeviceMobileX'),
  'device-mobile': () => import('./icons/IconDeviceMobile'),
  'device-nintendo-off': () => import('./icons/IconDeviceNintendoOff'),
  'device-nintendo': () => import('./icons/IconDeviceNintendo'),
  'device-projector': () => import('./icons/IconDeviceProjector'),
  'device-remote': () => import('./icons/IconDeviceRemote'),
  'device-sd-card': () => import('./icons/IconDeviceSdCard'),
  'device-sim-1': () => import('./icons/IconDeviceSim1'),
  'device-sim-2': () => import('./icons/IconDeviceSim2'),
  'device-sim-3': () => import('./icons/IconDeviceSim3'),
  'device-sim': () => import('./icons/IconDeviceSim'),
  'device-speaker-off': () => import('./icons/IconDeviceSpeakerOff'),
  'device-speaker': () => import('./icons/IconDeviceSpeaker'),
  'device-tablet-bolt': () => import('./icons/IconDeviceTabletBolt'),
  'device-tablet-cancel': () => import('./icons/IconDeviceTabletCancel'),
  'device-tablet-check': () => import('./icons/IconDeviceTabletCheck'),
  'device-tablet-code': () => import('./icons/IconDeviceTabletCode'),
  'device-tablet-cog': () => import('./icons/IconDeviceTabletCog'),
  'device-tablet-dollar': () => import('./icons/IconDeviceTabletDollar'),
  'device-tablet-down': () => import('./icons/IconDeviceTabletDown'),
  'device-tablet-exclamation': () => import('./icons/IconDeviceTabletExclamation'),
  'device-tablet-heart': () => import('./icons/IconDeviceTabletHeart'),
  'device-tablet-minus': () => import('./icons/IconDeviceTabletMinus'),
  'device-tablet-off': () => import('./icons/IconDeviceTabletOff'),
  'device-tablet-pause': () => import('./icons/IconDeviceTabletPause'),
  'device-tablet-pin': () => import('./icons/IconDeviceTabletPin'),
  'device-tablet-plus': () => import('./icons/IconDeviceTabletPlus'),
  'device-tablet-question': () => import('./icons/IconDeviceTabletQuestion'),
  'device-tablet-search': () => import('./icons/IconDeviceTabletSearch'),
  'device-tablet-share': () => import('./icons/IconDeviceTabletShare'),
  'device-tablet-star': () => import('./icons/IconDeviceTabletStar'),
  'device-tablet-up': () => import('./icons/IconDeviceTabletUp'),
  'device-tablet-x': () => import('./icons/IconDeviceTabletX'),
  'device-tablet': () => import('./icons/IconDeviceTablet'),
  'device-tv-off': () => import('./icons/IconDeviceTvOff'),
  'device-tv-old': () => import('./icons/IconDeviceTvOld'),
  'device-tv': () => import('./icons/IconDeviceTv'),
  'device-unknown': () => import('./icons/IconDeviceUnknown'),
  'device-usb': () => import('./icons/IconDeviceUsb'),
  'device-vision-pro': () => import('./icons/IconDeviceVisionPro'),
  'device-watch-bolt': () => import('./icons/IconDeviceWatchBolt'),
  'device-watch-cancel': () => import('./icons/IconDeviceWatchCancel'),
  'device-watch-check': () => import('./icons/IconDeviceWatchCheck'),
  'device-watch-code': () => import('./icons/IconDeviceWatchCode'),
  'device-watch-cog': () => import('./icons/IconDeviceWatchCog'),
  'device-watch-dollar': () => import('./icons/IconDeviceWatchDollar'),
  'device-watch-down': () => import('./icons/IconDeviceWatchDown'),
  'device-watch-exclamation': () => import('./icons/IconDeviceWatchExclamation'),
  'device-watch-heart': () => import('./icons/IconDeviceWatchHeart'),
  'device-watch-minus': () => import('./icons/IconDeviceWatchMinus'),
  'device-watch-off': () => import('./icons/IconDeviceWatchOff'),
  'device-watch-pause': () => import('./icons/IconDeviceWatchPause'),
  'device-watch-pin': () => import('./icons/IconDeviceWatchPin'),
  'device-watch-plus': () => import('./icons/IconDeviceWatchPlus'),
  'device-watch-question': () => import('./icons/IconDeviceWatchQuestion'),
  'device-watch-search': () => import('./icons/IconDeviceWatchSearch'),
  'device-watch-share': () => import('./icons/IconDeviceWatchShare'),
  'device-watch-star': () => import('./icons/IconDeviceWatchStar'),
  'device-watch-stats-2': () => import('./icons/IconDeviceWatchStats2'),
  'device-watch-stats': () => import('./icons/IconDeviceWatchStats'),
  'device-watch-up': () => import('./icons/IconDeviceWatchUp'),
  'device-watch-x': () => import('./icons/IconDeviceWatchX'),
  'device-watch': () => import('./icons/IconDeviceWatch'),
  'devices-2': () => import('./icons/IconDevices2'),
  'devices-bolt': () => import('./icons/IconDevicesBolt'),
  'devices-cancel': () => import('./icons/IconDevicesCancel'),
  'devices-check': () => import('./icons/IconDevicesCheck'),
  'devices-code': () => import('./icons/IconDevicesCode'),
  'devices-cog': () => import('./icons/IconDevicesCog'),
  'devices-dollar': () => import('./icons/IconDevicesDollar'),
  'devices-down': () => import('./icons/IconDevicesDown'),
  'devices-exclamation': () => import('./icons/IconDevicesExclamation'),
  'devices-heart': () => import('./icons/IconDevicesHeart'),
  'devices-minus': () => import('./icons/IconDevicesMinus'),
  'devices-off': () => import('./icons/IconDevicesOff'),
  'devices-pause': () => import('./icons/IconDevicesPause'),
  'devices-pc-off': () => import('./icons/IconDevicesPcOff'),
  'devices-pc': () => import('./icons/IconDevicesPc'),
  'devices-pin': () => import('./icons/IconDevicesPin'),
  'devices-plus': () => import('./icons/IconDevicesPlus'),
  'devices-question': () => import('./icons/IconDevicesQuestion'),
  'devices-search': () => import('./icons/IconDevicesSearch'),
  'devices-share': () => import('./icons/IconDevicesShare'),
  'devices-star': () => import('./icons/IconDevicesStar'),
  'devices-up': () => import('./icons/IconDevicesUp'),
  'devices-x': () => import('./icons/IconDevicesX'),
  'devices': () => import('./icons/IconDevices'),
  'diabolo-off': () => import('./icons/IconDiaboloOff'),
  'diabolo-plus': () => import('./icons/IconDiaboloPlus'),
  'diabolo': () => import('./icons/IconDiabolo'),
  'dialpad-off': () => import('./icons/IconDialpadOff'),
  'dialpad': () => import('./icons/IconDialpad'),
  'diamond-off': () => import('./icons/IconDiamondOff'),
  'diamond': () => import('./icons/IconDiamond'),
  'diamonds': () => import('./icons/IconDiamonds'),
  'diaper': () => import('./icons/IconDiaper'),
  'dice-1': () => import('./icons/IconDice1'),
  'dice-2': () => import('./icons/IconDice2'),
  'dice-3': () => import('./icons/IconDice3'),
  'dice-4': () => import('./icons/IconDice4'),
  'dice-5': () => import('./icons/IconDice5'),
  'dice-6': () => import('./icons/IconDice6'),
  'dice': () => import('./icons/IconDice'),
  'dimensions': () => import('./icons/IconDimensions'),
  'direction-arrows': () => import('./icons/IconDirectionArrows'),
  'direction-horizontal': () => import('./icons/IconDirectionHorizontal'),
  'direction-sign-off': () => import('./icons/IconDirectionSignOff'),
  'direction-sign': () => import('./icons/IconDirectionSign'),
  'direction': () => import('./icons/IconDirection'),
  'directions-off': () => import('./icons/IconDirectionsOff'),
  'directions': () => import('./icons/IconDirections'),
  'disabled-2': () => import('./icons/IconDisabled2'),
  'disabled-off': () => import('./icons/IconDisabledOff'),
  'disabled': () => import('./icons/IconDisabled'),
  'disc-golf': () => import('./icons/IconDiscGolf'),
  'disc-off': () => import('./icons/IconDiscOff'),
  'disc': () => import('./icons/IconDisc'),
  'discount-off': () => import('./icons/IconDiscountOff'),
  'discount': () => import('./icons/IconDiscount'),
  'divide': () => import('./icons/IconDivide'),
  'dna-2-off': () => import('./icons/IconDna2Off'),
  'dna-2': () => import('./icons/IconDna2'),
  'dna-off': () => import('./icons/IconDnaOff'),
  'dna': () => import('./icons/IconDna'),
  'dog-bowl': () => import('./icons/IconDogBowl'),
  'dog': () => import('./icons/IconDog'),
  'door-enter': () => import('./icons/IconDoorEnter'),
  'door-exit': () => import('./icons/IconDoorExit'),
  'door-off': () => import('./icons/IconDoorOff'),
  'door': () => import('./icons/IconDoor'),
  'dots-circle-horizontal': () => import('./icons/IconDotsCircleHorizontal'),
  'dots-diagonal-2': () => import('./icons/IconDotsDiagonal2'),
  'dots-diagonal': () => import('./icons/IconDotsDiagonal'),
  'dots-vertical': () => import('./icons/IconDotsVertical'),
  'dots': () => import('./icons/IconDots'),
  'download-off': () => import('./icons/IconDownloadOff'),
  'download': () => import('./icons/IconDownload'),
  'drag-drop-2': () => import('./icons/IconDragDrop2'),
  'drag-drop': () => import('./icons/IconDragDrop'),
  'drone-off': () => import('./icons/IconDroneOff'),
  'drone': () => import('./icons/IconDrone'),
  'drop-circle': () => import('./icons/IconDropCircle'),
  'droplet-bolt': () => import('./icons/IconDropletBolt'),
  'droplet-cancel': () => import('./icons/IconDropletCancel'),
  'droplet-check': () => import('./icons/IconDropletCheck'),
  'droplet-code': () => import('./icons/IconDropletCode'),
  'droplet-cog': () => import('./icons/IconDropletCog'),
  'droplet-dollar': () => import('./icons/IconDropletDollar'),
  'droplet-down': () => import('./icons/IconDropletDown'),
  'droplet-exclamation': () => import('./icons/IconDropletExclamation'),
  'droplet-half-2': () => import('./icons/IconDropletHalf2'),
  'droplet-half': () => import('./icons/IconDropletHalf'),
  'droplet-heart': () => import('./icons/IconDropletHeart'),
  'droplet-minus': () => import('./icons/IconDropletMinus'),
  'droplet-off': () => import('./icons/IconDropletOff'),
  'droplet-pause': () => import('./icons/IconDropletPause'),
  'droplet-pin': () => import('./icons/IconDropletPin'),
  'droplet-plus': () => import('./icons/IconDropletPlus'),
  'droplet-question': () => import('./icons/IconDropletQuestion'),
  'droplet-search': () => import('./icons/IconDropletSearch'),
  'droplet-share': () => import('./icons/IconDropletShare'),
  'droplet-star': () => import('./icons/IconDropletStar'),
  'droplet-up': () => import('./icons/IconDropletUp'),
  'droplet-x': () => import('./icons/IconDropletX'),
  'droplet': () => import('./icons/IconDroplet'),
  'droplets': () => import('./icons/IconDroplets'),
  'dual-screen': () => import('./icons/IconDualScreen'),
  'dumpling': () => import('./icons/IconDumpling'),
  'e-passport': () => import('./icons/IconEPassport'),
  'ear-off': () => import('./icons/IconEarOff'),
  'ear-scan': () => import('./icons/IconEarScan'),
  'ear': () => import('./icons/IconEar'),
  'ease-in-control-point': () => import('./icons/IconEaseInControlPoint'),
  'ease-in-out-control-points': () => import('./icons/IconEaseInOutControlPoints'),
  'ease-in-out': () => import('./icons/IconEaseInOut'),
  'ease-in': () => import('./icons/IconEaseIn'),
  'ease-out-control-point': () => import('./icons/IconEaseOutControlPoint'),
  'ease-out': () => import('./icons/IconEaseOut'),
  'edit-circle-off': () => import('./icons/IconEditCircleOff'),
  'edit-circle': () => import('./icons/IconEditCircle'),
  'edit-off': () => import('./icons/IconEditOff'),
  'edit': () => import('./icons/IconEdit'),
  'egg-cracked': () => import('./icons/IconEggCracked'),
  'egg-fried': () => import('./icons/IconEggFried'),
  'egg-off': () => import('./icons/IconEggOff'),
  'egg': () => import('./icons/IconEgg'),
  'eggs': () => import('./icons/IconEggs'),
  'elevator-off': () => import('./icons/IconElevatorOff'),
  'elevator': () => import('./icons/IconElevator'),
  'emergency-bed': () => import('./icons/IconEmergencyBed'),
  'empathize-off': () => import('./icons/IconEmpathizeOff'),
  'empathize': () => import('./icons/IconEmpathize'),
  'emphasis': () => import('./icons/IconEmphasis'),
  'engine-off': () => import('./icons/IconEngineOff'),
  'engine': () => import('./icons/IconEngine'),
  'equal-double': () => import('./icons/IconEqualDouble'),
  'equal-not': () => import('./icons/IconEqualNot'),
  'equal': () => import('./icons/IconEqual'),
  'eraser-off': () => import('./icons/IconEraserOff'),
  'eraser': () => import('./icons/IconEraser'),
  'error-404-off': () => import('./icons/IconError404Off'),
  'error-404': () => import('./icons/IconError404'),
  'escalator-down': () => import('./icons/IconEscalatorDown'),
  'escalator-up': () => import('./icons/IconEscalatorUp'),
  'escalator': () => import('./icons/IconEscalator'),
  'exchange-off': () => import('./icons/IconExchangeOff'),
  'exchange': () => import('./icons/IconExchange'),
  'exclamation-circle': () => import('./icons/IconExclamationCircle'),
  'exclamation-mark-off': () => import('./icons/IconExclamationMarkOff'),
  'exclamation-mark': () => import('./icons/IconExclamationMark'),
  'explicit-off': () => import('./icons/IconExplicitOff'),
  'explicit': () => import('./icons/IconExplicit'),
  'exposure-0': () => import('./icons/IconExposure0'),
  'exposure-minus-1': () => import('./icons/IconExposureMinus1'),
  'exposure-minus-2': () => import('./icons/IconExposureMinus2'),
  'exposure-off': () => import('./icons/IconExposureOff'),
  'exposure-plus-1': () => import('./icons/IconExposurePlus1'),
  'exposure-plus-2': () => import('./icons/IconExposurePlus2'),
  'exposure': () => import('./icons/IconExposure'),
  'external-link-off': () => import('./icons/IconExternalLinkOff'),
  'external-link': () => import('./icons/IconExternalLink'),
  'eye-bitcoin': () => import('./icons/IconEyeBitcoin'),
  'eye-bolt': () => import('./icons/IconEyeBolt'),
  'eye-cancel': () => import('./icons/IconEyeCancel'),
  'eye-check': () => import('./icons/IconEyeCheck'),
  'eye-closed': () => import('./icons/IconEyeClosed'),
  'eye-code': () => import('./icons/IconEyeCode'),
  'eye-cog': () => import('./icons/IconEyeCog'),
  'eye-discount': () => import('./icons/IconEyeDiscount'),
  'eye-dollar': () => import('./icons/IconEyeDollar'),
  'eye-dotted': () => import('./icons/IconEyeDotted'),
  'eye-down': () => import('./icons/IconEyeDown'),
  'eye-edit': () => import('./icons/IconEyeEdit'),
  'eye-exclamation': () => import('./icons/IconEyeExclamation'),
  'eye-heart': () => import('./icons/IconEyeHeart'),
  'eye-minus': () => import('./icons/IconEyeMinus'),
  'eye-off': () => import('./icons/IconEyeOff'),
  'eye-pause': () => import('./icons/IconEyePause'),
  'eye-pin': () => import('./icons/IconEyePin'),
  'eye-plus': () => import('./icons/IconEyePlus'),
  'eye-question': () => import('./icons/IconEyeQuestion'),
  'eye-search': () => import('./icons/IconEyeSearch'),
  'eye-share': () => import('./icons/IconEyeShare'),
  'eye-spark': () => import('./icons/IconEyeSpark'),
  'eye-star': () => import('./icons/IconEyeStar'),
  'eye-table': () => import('./icons/IconEyeTable'),
  'eye-up': () => import('./icons/IconEyeUp'),
  'eye-x': () => import('./icons/IconEyeX'),
  'eye': () => import('./icons/IconEye'),
  'eyeglass-2': () => import('./icons/IconEyeglass2'),
  'eyeglass-off': () => import('./icons/IconEyeglassOff'),
  'eyeglass': () => import('./icons/IconEyeglass'),
  'face-id-error': () => import('./icons/IconFaceIdError'),
  'face-id': () => import('./icons/IconFaceId'),
  'face-mask-off': () => import('./icons/IconFaceMaskOff'),
  'face-mask': () => import('./icons/IconFaceMask'),
  'fall': () => import('./icons/IconFall'),
  'favicon': () => import('./icons/IconFavicon'),
  'feather-off': () => import('./icons/IconFeatherOff'),
  'feather': () => import('./icons/IconFeather'),
  'fence-off': () => import('./icons/IconFenceOff'),
  'fence': () => import('./icons/IconFence'),
  'ferry': () => import('./icons/IconFerry'),
  'fidget-spinner': () => import('./icons/IconFidgetSpinner'),
  'file-3d': () => import('./icons/IconFile3d'),
  'file-ai': () => import('./icons/IconFileAi'),
  'file-alert': () => import('./icons/IconFileAlert'),
  'file-analytics': () => import('./icons/IconFileAnalytics'),
  'file-arrow-left': () => import('./icons/IconFileArrowLeft'),
  'file-arrow-right': () => import('./icons/IconFileArrowRight'),
  'file-barcode': () => import('./icons/IconFileBarcode'),
  'file-bitcoin': () => import('./icons/IconFileBitcoin'),
  'file-broken': () => import('./icons/IconFileBroken'),
  'file-certificate': () => import('./icons/IconFileCertificate'),
  'file-chart': () => import('./icons/IconFileChart'),
  'file-check': () => import('./icons/IconFileCheck'),
  'file-code-2': () => import('./icons/IconFileCode2'),
  'file-code': () => import('./icons/IconFileCode'),
  'file-cv': () => import('./icons/IconFileCv'),
  'file-database': () => import('./icons/IconFileDatabase'),
  'file-delta': () => import('./icons/IconFileDelta'),
  'file-description': () => import('./icons/IconFileDescription'),
  'file-diff': () => import('./icons/IconFileDiff'),
  'file-digit': () => import('./icons/IconFileDigit'),
  'file-dislike': () => import('./icons/IconFileDislike'),
  'file-dollar': () => import('./icons/IconFileDollar'),
  'file-dots': () => import('./icons/IconFileDots'),
  'file-download': () => import('./icons/IconFileDownload'),
  'file-euro': () => import('./icons/IconFileEuro'),
  'file-excel': () => import('./icons/IconFileExcel'),
  'file-export': () => import('./icons/IconFileExport'),
  'file-function': () => import('./icons/IconFileFunction'),
  'file-horizontal': () => import('./icons/IconFileHorizontal'),
  'file-import': () => import('./icons/IconFileImport'),
  'file-infinity': () => import('./icons/IconFileInfinity'),
  'file-info': () => import('./icons/IconFileInfo'),
  'file-invoice': () => import('./icons/IconFileInvoice'),
  'file-isr': () => import('./icons/IconFileIsr'),
  'file-lambda': () => import('./icons/IconFileLambda'),
  'file-like': () => import('./icons/IconFileLike'),
  'file-minus': () => import('./icons/IconFileMinus'),
  'file-music': () => import('./icons/IconFileMusic'),
  'file-neutral': () => import('./icons/IconFileNeutral'),
  'file-off': () => import('./icons/IconFileOff'),
  'file-orientation': () => import('./icons/IconFileOrientation'),
  'file-pencil': () => import('./icons/IconFilePencil'),
  'file-percent': () => import('./icons/IconFilePercent'),
  'file-phone': () => import('./icons/IconFilePhone'),
  'file-plus': () => import('./icons/IconFilePlus'),
  'file-power': () => import('./icons/IconFilePower'),
  'file-report': () => import('./icons/IconFileReport'),
  'file-rss': () => import('./icons/IconFileRss'),
  'file-sad': () => import('./icons/IconFileSad'),
  'file-scissors': () => import('./icons/IconFileScissors'),
  'file-search': () => import('./icons/IconFileSearch'),
  'file-settings': () => import('./icons/IconFileSettings'),
  'file-shredder': () => import('./icons/IconFileShredder'),
  'file-signal': () => import('./icons/IconFileSignal'),
  'file-smile': () => import('./icons/IconFileSmile'),
  'file-spark': () => import('./icons/IconFileSpark'),
  'file-spreadsheet': () => import('./icons/IconFileSpreadsheet'),
  'file-stack': () => import('./icons/IconFileStack'),
  'file-star': () => import('./icons/IconFileStar'),
  'file-symlink': () => import('./icons/IconFileSymlink'),
  'file-text-ai': () => import('./icons/IconFileTextAi'),
  'file-text-spark': () => import('./icons/IconFileTextSpark'),
  'file-text': () => import('./icons/IconFileText'),
  'file-time': () => import('./icons/IconFileTime'),
  'file-type-bmp': () => import('./icons/IconFileTypeBmp'),
  'file-type-css': () => import('./icons/IconFileTypeCss'),
  'file-type-csv': () => import('./icons/IconFileTypeCsv'),
  'file-type-doc': () => import('./icons/IconFileTypeDoc'),
  'file-type-docx': () => import('./icons/IconFileTypeDocx'),
  'file-type-html': () => import('./icons/IconFileTypeHtml'),
  'file-type-jpg': () => import('./icons/IconFileTypeJpg'),
  'file-type-js': () => import('./icons/IconFileTypeJs'),
  'file-type-jsx': () => import('./icons/IconFileTypeJsx'),
  'file-type-pdf': () => import('./icons/IconFileTypePdf'),
  'file-type-php': () => import('./icons/IconFileTypePhp'),
  'file-type-png': () => import('./icons/IconFileTypePng'),
  'file-type-ppt': () => import('./icons/IconFileTypePpt'),
  'file-type-rs': () => import('./icons/IconFileTypeRs'),
  'file-type-sql': () => import('./icons/IconFileTypeSql'),
  'file-type-svg': () => import('./icons/IconFileTypeSvg'),
  'file-type-ts': () => import('./icons/IconFileTypeTs'),
  'file-type-tsx': () => import('./icons/IconFileTypeTsx'),
  'file-type-txt': () => import('./icons/IconFileTypeTxt'),
  'file-type-vue': () => import('./icons/IconFileTypeVue'),
  'file-type-xls': () => import('./icons/IconFileTypeXls'),
  'file-type-xml': () => import('./icons/IconFileTypeXml'),
  'file-type-zip': () => import('./icons/IconFileTypeZip'),
  'file-typography': () => import('./icons/IconFileTypography'),
  'file-unknown': () => import('./icons/IconFileUnknown'),
  'file-upload': () => import('./icons/IconFileUpload'),
  'file-vector': () => import('./icons/IconFileVector'),
  'file-word': () => import('./icons/IconFileWord'),
  'file-x': () => import('./icons/IconFileX'),
  'file-zip': () => import('./icons/IconFileZip'),
  'file': () => import('./icons/IconFile'),
  'files-off': () => import('./icons/IconFilesOff'),
  'files': () => import('./icons/IconFiles'),
  'filter-bolt': () => import('./icons/IconFilterBolt'),
  'filter-cancel': () => import('./icons/IconFilterCancel'),
  'filter-check': () => import('./icons/IconFilterCheck'),
  'filter-code': () => import('./icons/IconFilterCode'),
  'filter-cog': () => import('./icons/IconFilterCog'),
  'filter-discount': () => import('./icons/IconFilterDiscount'),
  'filter-dollar': () => import('./icons/IconFilterDollar'),
  'filter-down': () => import('./icons/IconFilterDown'),
  'filter-edit': () => import('./icons/IconFilterEdit'),
  'filter-exclamation': () => import('./icons/IconFilterExclamation'),
  'filter-heart': () => import('./icons/IconFilterHeart'),
  'filter-minus': () => import('./icons/IconFilterMinus'),
  'filter-off': () => import('./icons/IconFilterOff'),
  'filter-pause': () => import('./icons/IconFilterPause'),
  'filter-pin': () => import('./icons/IconFilterPin'),
  'filter-plus': () => import('./icons/IconFilterPlus'),
  'filter-question': () => import('./icons/IconFilterQuestion'),
  'filter-search': () => import('./icons/IconFilterSearch'),
  'filter-share': () => import('./icons/IconFilterShare'),
  'filter-star': () => import('./icons/IconFilterStar'),
  'filter-up': () => import('./icons/IconFilterUp'),
  'filter-x': () => import('./icons/IconFilterX'),
  'filter': () => import('./icons/IconFilter'),
  'filters': () => import('./icons/IconFilters'),
  'fingerprint-off': () => import('./icons/IconFingerprintOff'),
  'fingerprint-scan': () => import('./icons/IconFingerprintScan'),
  'fingerprint': () => import('./icons/IconFingerprint'),
  'fire-extinguisher': () => import('./icons/IconFireExtinguisher'),
  'fire-hydrant-off': () => import('./icons/IconFireHydrantOff'),
  'fire-hydrant': () => import('./icons/IconFireHydrant'),
  'firetruck': () => import('./icons/IconFiretruck'),
  'first-aid-kit-off': () => import('./icons/IconFirstAidKitOff'),
  'first-aid-kit': () => import('./icons/IconFirstAidKit'),
  'fish-bone': () => import('./icons/IconFishBone'),
  'fish-christianity': () => import('./icons/IconFishChristianity'),
  'fish-hook-off': () => import('./icons/IconFishHookOff'),
  'fish-hook': () => import('./icons/IconFishHook'),
  'fish-off': () => import('./icons/IconFishOff'),
  'fish': () => import('./icons/IconFish'),
  'flag-2-off': () => import('./icons/IconFlag2Off'),
  'flag-2': () => import('./icons/IconFlag2'),
  'flag-3': () => import('./icons/IconFlag3'),
  'flag-bitcoin': () => import('./icons/IconFlagBitcoin'),
  'flag-bolt': () => import('./icons/IconFlagBolt'),
  'flag-cancel': () => import('./icons/IconFlagCancel'),
  'flag-check': () => import('./icons/IconFlagCheck'),
  'flag-code': () => import('./icons/IconFlagCode'),
  'flag-cog': () => import('./icons/IconFlagCog'),
  'flag-discount': () => import('./icons/IconFlagDiscount'),
  'flag-dollar': () => import('./icons/IconFlagDollar'),
  'flag-down': () => import('./icons/IconFlagDown'),
  'flag-exclamation': () => import('./icons/IconFlagExclamation'),
  'flag-heart': () => import('./icons/IconFlagHeart'),
  'flag-minus': () => import('./icons/IconFlagMinus'),
  'flag-off': () => import('./icons/IconFlagOff'),
  'flag-pause': () => import('./icons/IconFlagPause'),
  'flag-pin': () => import('./icons/IconFlagPin'),
  'flag-plus': () => import('./icons/IconFlagPlus'),
  'flag-question': () => import('./icons/IconFlagQuestion'),
  'flag-search': () => import('./icons/IconFlagSearch'),
  'flag-share': () => import('./icons/IconFlagShare'),
  'flag-spark': () => import('./icons/IconFlagSpark'),
  'flag-star': () => import('./icons/IconFlagStar'),
  'flag-up': () => import('./icons/IconFlagUp'),
  'flag-x': () => import('./icons/IconFlagX'),
  'flag': () => import('./icons/IconFlag'),
  'flame-off': () => import('./icons/IconFlameOff'),
  'flame': () => import('./icons/IconFlame'),
  'flare': () => import('./icons/IconFlare'),
  'flask-2-off': () => import('./icons/IconFlask2Off'),
  'flask-2': () => import('./icons/IconFlask2'),
  'flask-off': () => import('./icons/IconFlaskOff'),
  'flask': () => import('./icons/IconFlask'),
  'flip-flops': () => import('./icons/IconFlipFlops'),
  'flip-horizontal': () => import('./icons/IconFlipHorizontal'),
  'flip-vertical': () => import('./icons/IconFlipVertical'),
  'float-center': () => import('./icons/IconFloatCenter'),
  'float-left': () => import('./icons/IconFloatLeft'),
  'float-none': () => import('./icons/IconFloatNone'),
  'float-right': () => import('./icons/IconFloatRight'),
  'flower-off': () => import('./icons/IconFlowerOff'),
  'flower': () => import('./icons/IconFlower'),
  'focus-2': () => import('./icons/IconFocus2'),
  'focus-auto': () => import('./icons/IconFocusAuto'),
  'focus-centered': () => import('./icons/IconFocusCentered'),
  'focus': () => import('./icons/IconFocus'),
  'fold-down': () => import('./icons/IconFoldDown'),
  'fold-up': () => import('./icons/IconFoldUp'),
  'fold': () => import('./icons/IconFold'),
  'folder-bolt': () => import('./icons/IconFolderBolt'),
  'folder-cancel': () => import('./icons/IconFolderCancel'),
  'folder-check': () => import('./icons/IconFolderCheck'),
  'folder-code': () => import('./icons/IconFolderCode'),
  'folder-cog': () => import('./icons/IconFolderCog'),
  'folder-dollar': () => import('./icons/IconFolderDollar'),
  'folder-down': () => import('./icons/IconFolderDown'),
  'folder-exclamation': () => import('./icons/IconFolderExclamation'),
  'folder-heart': () => import('./icons/IconFolderHeart'),
  'folder-minus': () => import('./icons/IconFolderMinus'),
  'folder-off': () => import('./icons/IconFolderOff'),
  'folder-open': () => import('./icons/IconFolderOpen'),
  'folder-pause': () => import('./icons/IconFolderPause'),
  'folder-pin': () => import('./icons/IconFolderPin'),
  'folder-plus': () => import('./icons/IconFolderPlus'),
  'folder-question': () => import('./icons/IconFolderQuestion'),
  'folder-root': () => import('./icons/IconFolderRoot'),
  'folder-search': () => import('./icons/IconFolderSearch'),
  'folder-share': () => import('./icons/IconFolderShare'),
  'folder-star': () => import('./icons/IconFolderStar'),
  'folder-symlink': () => import('./icons/IconFolderSymlink'),
  'folder-up': () => import('./icons/IconFolderUp'),
  'folder-x': () => import('./icons/IconFolderX'),
  'folder': () => import('./icons/IconFolder'),
  'folders-off': () => import('./icons/IconFoldersOff'),
  'folders': () => import('./icons/IconFolders'),
  'forbid-2': () => import('./icons/IconForbid2'),
  'forbid': () => import('./icons/IconForbid'),
  'forklift': () => import('./icons/IconForklift'),
  'forms': () => import('./icons/IconForms'),
  'fountain-off': () => import('./icons/IconFountainOff'),
  'fountain': () => import('./icons/IconFountain'),
  'frame-off': () => import('./icons/IconFrameOff'),
  'frame': () => import('./icons/IconFrame'),
  'free-rights': () => import('./icons/IconFreeRights'),
  'freeze-column': () => import('./icons/IconFreezeColumn'),
  'freeze-row-column': () => import('./icons/IconFreezeRowColumn'),
  'freeze-row': () => import('./icons/IconFreezeRow'),
  'fridge-off': () => import('./icons/IconFridgeOff'),
  'fridge': () => import('./icons/IconFridge'),
  'friends-off': () => import('./icons/IconFriendsOff'),
  'friends': () => import('./icons/IconFriends'),
  'frustum-off': () => import('./icons/IconFrustumOff'),
  'frustum-plus': () => import('./icons/IconFrustumPlus'),
  'frustum': () => import('./icons/IconFrustum'),
  'function-off': () => import('./icons/IconFunctionOff'),
  'function': () => import('./icons/IconFunction'),
  'galaxy': () => import('./icons/IconGalaxy'),
  'garden-cart-off': () => import('./icons/IconGardenCartOff'),
  'garden-cart': () => import('./icons/IconGardenCart'),
  'gas-station-off': () => import('./icons/IconGasStationOff'),
  'gas-station': () => import('./icons/IconGasStation'),
  'gauge-off': () => import('./icons/IconGaugeOff'),
  'gauge': () => import('./icons/IconGauge'),
  'gavel': () => import('./icons/IconGavel'),
  'gender-agender': () => import('./icons/IconGenderAgender'),
  'gender-androgyne': () => import('./icons/IconGenderAndrogyne'),
  'gender-bigender': () => import('./icons/IconGenderBigender'),
  'gender-demiboy': () => import('./icons/IconGenderDemiboy'),
  'gender-demigirl': () => import('./icons/IconGenderDemigirl'),
  'gender-epicene': () => import('./icons/IconGenderEpicene'),
  'gender-female': () => import('./icons/IconGenderFemale'),
  'gender-femme': () => import('./icons/IconGenderFemme'),
  'gender-genderfluid': () => import('./icons/IconGenderGenderfluid'),
  'gender-genderless': () => import('./icons/IconGenderGenderless'),
  'gender-genderqueer': () => import('./icons/IconGenderGenderqueer'),
  'gender-hermaphrodite': () => import('./icons/IconGenderHermaphrodite'),
  'gender-intergender': () => import('./icons/IconGenderIntergender'),
  'gender-male': () => import('./icons/IconGenderMale'),
  'gender-neutrois': () => import('./icons/IconGenderNeutrois'),
  'gender-third': () => import('./icons/IconGenderThird'),
  'gender-transgender': () => import('./icons/IconGenderTransgender'),
  'gender-trasvesti': () => import('./icons/IconGenderTrasvesti'),
  'geometry': () => import('./icons/IconGeometry'),
  'ghost-2': () => import('./icons/IconGhost2'),
  'ghost-3': () => import('./icons/IconGhost3'),
  'ghost-off': () => import('./icons/IconGhostOff'),
  'ghost': () => import('./icons/IconGhost'),
  'gif': () => import('./icons/IconGif'),
  'gift-card': () => import('./icons/IconGiftCard'),
  'gift-off': () => import('./icons/IconGiftOff'),
  'gift': () => import('./icons/IconGift'),
  'git-branch-deleted': () => import('./icons/IconGitBranchDeleted'),
  'git-branch': () => import('./icons/IconGitBranch'),
  'git-cherry-pick': () => import('./icons/IconGitCherryPick'),
  'git-commit': () => import('./icons/IconGitCommit'),
  'git-compare': () => import('./icons/IconGitCompare'),
  'git-fork': () => import('./icons/IconGitFork'),
  'git-merge': () => import('./icons/IconGitMerge'),
  'git-pull-request-closed': () => import('./icons/IconGitPullRequestClosed'),
  'git-pull-request-draft': () => import('./icons/IconGitPullRequestDraft'),
  'git-pull-request': () => import('./icons/IconGitPullRequest'),
  'gizmo': () => import('./icons/IconGizmo'),
  'glass-champagne': () => import('./icons/IconGlassChampagne'),
  'glass-cocktail': () => import('./icons/IconGlassCocktail'),
  'glass-full': () => import('./icons/IconGlassFull'),
  'glass-gin': () => import('./icons/IconGlassGin'),
  'glass-off': () => import('./icons/IconGlassOff'),
  'glass': () => import('./icons/IconGlass'),
  'globe-off': () => import('./icons/IconGlobeOff'),
  'globe': () => import('./icons/IconGlobe'),
  'go-game': () => import('./icons/IconGoGame'),
  'golf-off': () => import('./icons/IconGolfOff'),
  'golf': () => import('./icons/IconGolf'),
  'gps': () => import('./icons/IconGps'),
  'gradienter': () => import('./icons/IconGradienter'),
  'grain': () => import('./icons/IconGrain'),
  'graph-off': () => import('./icons/IconGraphOff'),
  'graph': () => import('./icons/IconGraph'),
  'grave-2': () => import('./icons/IconGrave2'),
  'grave': () => import('./icons/IconGrave'),
  'grid-3x3': () => import('./icons/IconGrid3x3'),
  'grid-4x4': () => import('./icons/IconGrid4x4'),
  'grid-dots': () => import('./icons/IconGridDots'),
  'grid-goldenratio': () => import('./icons/IconGridGoldenratio'),
  'grid-pattern': () => import('./icons/IconGridPattern'),
  'grid-scan': () => import('./icons/IconGridScan'),
  'grill-fork': () => import('./icons/IconGrillFork'),
  'grill-off': () => import('./icons/IconGrillOff'),
  'grill-spatula': () => import('./icons/IconGrillSpatula'),
  'grill': () => import('./icons/IconGrill'),
  'grip-horizontal': () => import('./icons/IconGripHorizontal'),
  'grip-vertical': () => import('./icons/IconGripVertical'),
  'growth': () => import('./icons/IconGrowth'),
  'guitar-pick': () => import('./icons/IconGuitarPick'),
  'gymnastics': () => import('./icons/IconGymnastics'),
  'h-1': () => import('./icons/IconH1'),
  'h-2': () => import('./icons/IconH2'),
  'h-3': () => import('./icons/IconH3'),
  'h-4': () => import('./icons/IconH4'),
  'h-5': () => import('./icons/IconH5'),
  'h-6': () => import('./icons/IconH6'),
  'hammer-off': () => import('./icons/IconHammerOff'),
  'hammer': () => import('./icons/IconHammer'),
  'hand-click': () => import('./icons/IconHandClick'),
  'hand-finger-down': () => import('./icons/IconHandFingerDown'),
  'hand-finger-left': () => import('./icons/IconHandFingerLeft'),
  'hand-finger-off': () => import('./icons/IconHandFingerOff'),
  'hand-finger-right': () => import('./icons/IconHandFingerRight'),
  'hand-finger': () => import('./icons/IconHandFinger'),
  'hand-grab': () => import('./icons/IconHandGrab'),
  'hand-little-finger': () => import('./icons/IconHandLittleFinger'),
  'hand-love-you': () => import('./icons/IconHandLoveYou'),
  'hand-middle-finger': () => import('./icons/IconHandMiddleFinger'),
  'hand-move': () => import('./icons/IconHandMove'),
  'hand-off': () => import('./icons/IconHandOff'),
  'hand-ring-finger': () => import('./icons/IconHandRingFinger'),
  'hand-sanitizer': () => import('./icons/IconHandSanitizer'),
  'hand-stop': () => import('./icons/IconHandStop'),
  'hand-three-fingers': () => import('./icons/IconHandThreeFingers'),
  'hand-two-fingers': () => import('./icons/IconHandTwoFingers'),
  'hanger-2': () => import('./icons/IconHanger2'),
  'hanger-off': () => import('./icons/IconHangerOff'),
  'hanger': () => import('./icons/IconHanger'),
  'hash': () => import('./icons/IconHash'),
  'haze-moon': () => import('./icons/IconHazeMoon'),
  'haze': () => import('./icons/IconHaze'),
  'hdr': () => import('./icons/IconHdr'),
  'heading-off': () => import('./icons/IconHeadingOff'),
  'heading': () => import('./icons/IconHeading'),
  'headphones-off': () => import('./icons/IconHeadphonesOff'),
  'headphones': () => import('./icons/IconHeadphones'),
  'headset-off': () => import('./icons/IconHeadsetOff'),
  'headset': () => import('./icons/IconHeadset'),
  'health-recognition': () => import('./icons/IconHealthRecognition'),
  'heart-bitcoin': () => import('./icons/IconHeartBitcoin'),
  'heart-bolt': () => import('./icons/IconHeartBolt'),
  'heart-broken': () => import('./icons/IconHeartBroken'),
  'heart-cancel': () => import('./icons/IconHeartCancel'),
  'heart-check': () => import('./icons/IconHeartCheck'),
  'heart-code': () => import('./icons/IconHeartCode'),
  'heart-cog': () => import('./icons/IconHeartCog'),
  'heart-discount': () => import('./icons/IconHeartDiscount'),
  'heart-dollar': () => import('./icons/IconHeartDollar'),
  'heart-down': () => import('./icons/IconHeartDown'),
  'heart-exclamation': () => import('./icons/IconHeartExclamation'),
  'heart-handshake': () => import('./icons/IconHeartHandshake'),
  'heart-minus': () => import('./icons/IconHeartMinus'),
  'heart-off': () => import('./icons/IconHeartOff'),
  'heart-pause': () => import('./icons/IconHeartPause'),
  'heart-pin': () => import('./icons/IconHeartPin'),
  'heart-plus': () => import('./icons/IconHeartPlus'),
  'heart-question': () => import('./icons/IconHeartQuestion'),
  'heart-rate-monitor': () => import('./icons/IconHeartRateMonitor'),
  'heart-search': () => import('./icons/IconHeartSearch'),
  'heart-share': () => import('./icons/IconHeartShare'),
  'heart-spark': () => import('./icons/IconHeartSpark'),
  'heart-star': () => import('./icons/IconHeartStar'),
  'heart-up': () => import('./icons/IconHeartUp'),
  'heart-x': () => import('./icons/IconHeartX'),
  'heart': () => import('./icons/IconHeart'),
  'heartbeat': () => import('./icons/IconHeartbeat'),
  'hearts-off': () => import('./icons/IconHeartsOff'),
  'hearts': () => import('./icons/IconHearts'),
  'helicopter-landing': () => import('./icons/IconHelicopterLanding'),
  'helicopter': () => import('./icons/IconHelicopter'),
  'helmet-off': () => import('./icons/IconHelmetOff'),
  'helmet': () => import('./icons/IconHelmet'),
  'help-circle': () => import('./icons/IconHelpCircle'),
  'help-hexagon': () => import('./icons/IconHelpHexagon'),
  'help-octagon': () => import('./icons/IconHelpOctagon'),
  'help-off': () => import('./icons/IconHelpOff'),
  'help-small': () => import('./icons/IconHelpSmall'),
  'help-square-rounded': () => import('./icons/IconHelpSquareRounded'),
  'help-square': () => import('./icons/IconHelpSquare'),
  'help-triangle': () => import('./icons/IconHelpTriangle'),
  'help': () => import('./icons/IconHelp'),
  'hemisphere-off': () => import('./icons/IconHemisphereOff'),
  'hemisphere-plus': () => import('./icons/IconHemispherePlus'),
  'hemisphere': () => import('./icons/IconHemisphere'),
  'hexagon-3d': () => import('./icons/IconHexagon3d'),
  'hexagon-letter-a': () => import('./icons/IconHexagonLetterA'),
  'hexagon-letter-b': () => import('./icons/IconHexagonLetterB'),
  'hexagon-letter-c': () => import('./icons/IconHexagonLetterC'),
  'hexagon-letter-d': () => import('./icons/IconHexagonLetterD'),
  'hexagon-letter-e': () => import('./icons/IconHexagonLetterE'),
  'hexagon-letter-f': () => import('./icons/IconHexagonLetterF'),
  'hexagon-letter-g': () => import('./icons/IconHexagonLetterG'),
  'hexagon-letter-h': () => import('./icons/IconHexagonLetterH'),
  'hexagon-letter-i': () => import('./icons/IconHexagonLetterI'),
  'hexagon-letter-j': () => import('./icons/IconHexagonLetterJ'),
  'hexagon-letter-k': () => import('./icons/IconHexagonLetterK'),
  'hexagon-letter-l': () => import('./icons/IconHexagonLetterL'),
  'hexagon-letter-m': () => import('./icons/IconHexagonLetterM'),
  'hexagon-letter-n': () => import('./icons/IconHexagonLetterN'),
  'hexagon-letter-o': () => import('./icons/IconHexagonLetterO'),
  'hexagon-letter-p': () => import('./icons/IconHexagonLetterP'),
  'hexagon-letter-q': () => import('./icons/IconHexagonLetterQ'),
  'hexagon-letter-r': () => import('./icons/IconHexagonLetterR'),
  'hexagon-letter-s': () => import('./icons/IconHexagonLetterS'),
  'hexagon-letter-t': () => import('./icons/IconHexagonLetterT'),
  'hexagon-letter-u': () => import('./icons/IconHexagonLetterU'),
  'hexagon-letter-v': () => import('./icons/IconHexagonLetterV'),
  'hexagon-letter-w': () => import('./icons/IconHexagonLetterW'),
  'hexagon-letter-x': () => import('./icons/IconHexagonLetterX'),
  'hexagon-letter-y': () => import('./icons/IconHexagonLetterY'),
  'hexagon-letter-z': () => import('./icons/IconHexagonLetterZ'),
  'hexagon-minus-2': () => import('./icons/IconHexagonMinus2'),
  'hexagon-minus': () => import('./icons/IconHexagonMinus'),
  'hexagon-number-0': () => import('./icons/IconHexagonNumber0'),
  'hexagon-number-1': () => import('./icons/IconHexagonNumber1'),
  'hexagon-number-2': () => import('./icons/IconHexagonNumber2'),
  'hexagon-number-3': () => import('./icons/IconHexagonNumber3'),
  'hexagon-number-4': () => import('./icons/IconHexagonNumber4'),
  'hexagon-number-5': () => import('./icons/IconHexagonNumber5'),
  'hexagon-number-6': () => import('./icons/IconHexagonNumber6'),
  'hexagon-number-7': () => import('./icons/IconHexagonNumber7'),
  'hexagon-number-8': () => import('./icons/IconHexagonNumber8'),
  'hexagon-number-9': () => import('./icons/IconHexagonNumber9'),
  'hexagon-off': () => import('./icons/IconHexagonOff'),
  'hexagon-plus-2': () => import('./icons/IconHexagonPlus2'),
  'hexagon-plus': () => import('./icons/IconHexagonPlus'),
  'hexagon': () => import('./icons/IconHexagon'),
  'hexagonal-prism-off': () => import('./icons/IconHexagonalPrismOff'),
  'hexagonal-prism-plus': () => import('./icons/IconHexagonalPrismPlus'),
  'hexagonal-prism': () => import('./icons/IconHexagonalPrism'),
  'hexagonal-pyramid-off': () => import('./icons/IconHexagonalPyramidOff'),
  'hexagonal-pyramid-plus': () => import('./icons/IconHexagonalPyramidPlus'),
  'hexagonal-pyramid': () => import('./icons/IconHexagonalPyramid'),
  'hexagons-off': () => import('./icons/IconHexagonsOff'),
  'hexagons': () => import('./icons/IconHexagons'),
  'hierarchy-2': () => import('./icons/IconHierarchy2'),
  'hierarchy-3': () => import('./icons/IconHierarchy3'),
  'hierarchy-off': () => import('./icons/IconHierarchyOff'),
  'hierarchy': () => import('./icons/IconHierarchy'),
  'highlight-off': () => import('./icons/IconHighlightOff'),
  'highlight': () => import('./icons/IconHighlight'),
  'history-off': () => import('./icons/IconHistoryOff'),
  'history-toggle': () => import('./icons/IconHistoryToggle'),
  'history': () => import('./icons/IconHistory'),
  'home-2': () => import('./icons/IconHome2'),
  'home-bitcoin': () => import('./icons/IconHomeBitcoin'),
  'home-bolt': () => import('./icons/IconHomeBolt'),
  'home-cancel': () => import('./icons/IconHomeCancel'),
  'home-check': () => import('./icons/IconHomeCheck'),
  'home-cog': () => import('./icons/IconHomeCog'),
  'home-dollar': () => import('./icons/IconHomeDollar'),
  'home-dot': () => import('./icons/IconHomeDot'),
  'home-down': () => import('./icons/IconHomeDown'),
  'home-eco': () => import('./icons/IconHomeEco'),
  'home-edit': () => import('./icons/IconHomeEdit'),
  'home-exclamation': () => import('./icons/IconHomeExclamation'),
  'home-hand': () => import('./icons/IconHomeHand'),
  'home-heart': () => import('./icons/IconHomeHeart'),
  'home-infinity': () => import('./icons/IconHomeInfinity'),
  'home-link': () => import('./icons/IconHomeLink'),
  'home-minus': () => import('./icons/IconHomeMinus'),
  'home-move': () => import('./icons/IconHomeMove'),
  'home-off': () => import('./icons/IconHomeOff'),
  'home-plus': () => import('./icons/IconHomePlus'),
  'home-question': () => import('./icons/IconHomeQuestion'),
  'home-ribbon': () => import('./icons/IconHomeRibbon'),
  'home-search': () => import('./icons/IconHomeSearch'),
  'home-share': () => import('./icons/IconHomeShare'),
  'home-shield': () => import('./icons/IconHomeShield'),
  'home-signal': () => import('./icons/IconHomeSignal'),
  'home-spark': () => import('./icons/IconHomeSpark'),
  'home-star': () => import('./icons/IconHomeStar'),
  'home-stats': () => import('./icons/IconHomeStats'),
  'home-up': () => import('./icons/IconHomeUp'),
  'home-x': () => import('./icons/IconHomeX'),
  'home': () => import('./icons/IconHome'),
  'horse-toy': () => import('./icons/IconHorseToy'),
  'horse': () => import('./icons/IconHorse'),
  'horseshoe': () => import('./icons/IconHorseshoe'),
  'hospital-circle': () => import('./icons/IconHospitalCircle'),
  'hospital': () => import('./icons/IconHospital'),
  'hotel-service': () => import('./icons/IconHotelService'),
  'hourglass-empty': () => import('./icons/IconHourglassEmpty'),
  'hourglass-high': () => import('./icons/IconHourglassHigh'),
  'hourglass-low': () => import('./icons/IconHourglassLow'),
  'hourglass-off': () => import('./icons/IconHourglassOff'),
  'hourglass': () => import('./icons/IconHourglass'),
  'hours-12': () => import('./icons/IconHours12'),
  'hours-24': () => import('./icons/IconHours24'),
  'html': () => import('./icons/IconHtml'),
  'http-connect': () => import('./icons/IconHttpConnect'),
  'http-delete': () => import('./icons/IconHttpDelete'),
  'http-get': () => import('./icons/IconHttpGet'),
  'http-head': () => import('./icons/IconHttpHead'),
  'http-options': () => import('./icons/IconHttpOptions'),
  'http-patch': () => import('./icons/IconHttpPatch'),
  'http-post': () => import('./icons/IconHttpPost'),
  'http-put': () => import('./icons/IconHttpPut'),
  'http-que': () => import('./icons/IconHttpQue'),
  'http-trace': () => import('./icons/IconHttpTrace'),
  'ice-cream-2': () => import('./icons/IconIceCream2'),
  'ice-cream-off': () => import('./icons/IconIceCreamOff'),
  'ice-cream': () => import('./icons/IconIceCream'),
  'ice-skating': () => import('./icons/IconIceSkating'),
  'icons-off': () => import('./icons/IconIconsOff'),
  'icons': () => import('./icons/IconIcons'),
  'id-badge-2': () => import('./icons/IconIdBadge2'),
  'id-badge-off': () => import('./icons/IconIdBadgeOff'),
  'id-badge': () => import('./icons/IconIdBadge'),
  'id-off': () => import('./icons/IconIdOff'),
  'id': () => import('./icons/IconId'),
  'ikosaedr': () => import('./icons/IconIkosaedr'),
  'image-in-picture': () => import('./icons/IconImageInPicture'),
  'inbox-off': () => import('./icons/IconInboxOff'),
  'inbox': () => import('./icons/IconInbox'),
  'indent-decrease': () => import('./icons/IconIndentDecrease'),
  'indent-increase': () => import('./icons/IconIndentIncrease'),
  'infinity-off': () => import('./icons/IconInfinityOff'),
  'infinity': () => import('./icons/IconInfinity'),
  'info-circle': () => import('./icons/IconInfoCircle'),
  'info-hexagon': () => import('./icons/IconInfoHexagon'),
  'info-octagon': () => import('./icons/IconInfoOctagon'),
  'info-small': () => import('./icons/IconInfoSmall'),
  'info-square-rounded': () => import('./icons/IconInfoSquareRounded'),
  'info-square': () => import('./icons/IconInfoSquare'),
  'info-triangle': () => import('./icons/IconInfoTriangle'),
  'inner-shadow-bottom-left': () => import('./icons/IconInnerShadowBottomLeft'),
  'inner-shadow-bottom-right': () => import('./icons/IconInnerShadowBottomRight'),
  'inner-shadow-bottom': () => import('./icons/IconInnerShadowBottom'),
  'inner-shadow-left': () => import('./icons/IconInnerShadowLeft'),
  'inner-shadow-right': () => import('./icons/IconInnerShadowRight'),
  'inner-shadow-top-left': () => import('./icons/IconInnerShadowTopLeft'),
  'inner-shadow-top-right': () => import('./icons/IconInnerShadowTopRight'),
  'inner-shadow-top': () => import('./icons/IconInnerShadowTop'),
  'input-ai': () => import('./icons/IconInputAi'),
  'input-check': () => import('./icons/IconInputCheck'),
  'input-search': () => import('./icons/IconInputSearch'),
  'input-spark': () => import('./icons/IconInputSpark'),
  'input-x': () => import('./icons/IconInputX'),
  'invoice': () => import('./icons/IconInvoice'),
  'ironing-1': () => import('./icons/IconIroning1'),
  'ironing-2': () => import('./icons/IconIroning2'),
  'ironing-3': () => import('./icons/IconIroning3'),
  'ironing-off': () => import('./icons/IconIroningOff'),
  'ironing-steam-off': () => import('./icons/IconIroningSteamOff'),
  'ironing-steam': () => import('./icons/IconIroningSteam'),
  'ironing': () => import('./icons/IconIroning'),
  'irregular-polyhedron-off': () => import('./icons/IconIrregularPolyhedronOff'),
  'irregular-polyhedron-plus': () => import('./icons/IconIrregularPolyhedronPlus'),
  'irregular-polyhedron': () => import('./icons/IconIrregularPolyhedron'),
  'italic': () => import('./icons/IconItalic'),
  'jacket': () => import('./icons/IconJacket'),
  'jetpack': () => import('./icons/IconJetpack'),
  'jewish-star': () => import('./icons/IconJewishStar'),
  'join-bevel': () => import('./icons/IconJoinBevel'),
  'join-round': () => import('./icons/IconJoinRound'),
  'join-straight': () => import('./icons/IconJoinStraight'),
  'joker': () => import('./icons/IconJoker'),
  'jpg': () => import('./icons/IconJpg'),
  'json': () => import('./icons/IconJson'),
  'jump-rope': () => import('./icons/IconJumpRope'),
  'karate': () => import('./icons/IconKarate'),
  'kayak': () => import('./icons/IconKayak'),
  'kerning': () => import('./icons/IconKerning'),
  'key-off': () => import('./icons/IconKeyOff'),
  'key': () => import('./icons/IconKey'),
  'keyboard-hide': () => import('./icons/IconKeyboardHide'),
  'keyboard-off': () => import('./icons/IconKeyboardOff'),
  'keyboard-show': () => import('./icons/IconKeyboardShow'),
  'keyboard': () => import('./icons/IconKeyboard'),
  'keyframe-align-center': () => import('./icons/IconKeyframeAlignCenter'),
  'keyframe-align-horizontal': () => import('./icons/IconKeyframeAlignHorizontal'),
  'keyframe-align-vertical': () => import('./icons/IconKeyframeAlignVertical'),
  'keyframe': () => import('./icons/IconKeyframe'),
  'keyframes': () => import('./icons/IconKeyframes'),
  'label-important': () => import('./icons/IconLabelImportant'),
  'label-off': () => import('./icons/IconLabelOff'),
  'label': () => import('./icons/IconLabel'),
  'ladder-off': () => import('./icons/IconLadderOff'),
  'ladder': () => import('./icons/IconLadder'),
  'ladle': () => import('./icons/IconLadle'),
  'lambda': () => import('./icons/IconLambda'),
  'lamp-2': () => import('./icons/IconLamp2'),
  'lamp-off': () => import('./icons/IconLampOff'),
  'lamp': () => import('./icons/IconLamp'),
  'lane': () => import('./icons/IconLane'),
  'language-hiragana': () => import('./icons/IconLanguageHiragana'),
  'language-katakana': () => import('./icons/IconLanguageKatakana'),
  'language-off': () => import('./icons/IconLanguageOff'),
  'language': () => import('./icons/IconLanguage'),
  'lasso-off': () => import('./icons/IconLassoOff'),
  'lasso-polygon': () => import('./icons/IconLassoPolygon'),
  'lasso': () => import('./icons/IconLasso'),
  'laurel-wreath-1': () => import('./icons/IconLaurelWreath1'),
  'laurel-wreath-2': () => import('./icons/IconLaurelWreath2'),
  'laurel-wreath-3': () => import('./icons/IconLaurelWreath3'),
  'laurel-wreath': () => import('./icons/IconLaurelWreath'),
  'layers-difference': () => import('./icons/IconLayersDifference'),
  'layers-intersect-2': () => import('./icons/IconLayersIntersect2'),
  'layers-intersect': () => import('./icons/IconLayersIntersect'),
  'layers-linked': () => import('./icons/IconLayersLinked'),
  'layers-off': () => import('./icons/IconLayersOff'),
  'layers-selected-bottom': () => import('./icons/IconLayersSelectedBottom'),
  'layers-selected': () => import('./icons/IconLayersSelected'),
  'layers-subtract': () => import('./icons/IconLayersSubtract'),
  'layers-union': () => import('./icons/IconLayersUnion'),
  'layout-2': () => import('./icons/IconLayout2'),
  'layout-align-bottom': () => import('./icons/IconLayoutAlignBottom'),
  'layout-align-center': () => import('./icons/IconLayoutAlignCenter'),
  'layout-align-left': () => import('./icons/IconLayoutAlignLeft'),
  'layout-align-middle': () => import('./icons/IconLayoutAlignMiddle'),
  'layout-align-right': () => import('./icons/IconLayoutAlignRight'),
  'layout-align-top': () => import('./icons/IconLayoutAlignTop'),
  'layout-board-split': () => import('./icons/IconLayoutBoardSplit'),
  'layout-board': () => import('./icons/IconLayoutBoard'),
  'layout-bottombar-collapse': () => import('./icons/IconLayoutBottombarCollapse'),
  'layout-bottombar-expand': () => import('./icons/IconLayoutBottombarExpand'),
  'layout-bottombar-inactive': () => import('./icons/IconLayoutBottombarInactive'),
  'layout-bottombar': () => import('./icons/IconLayoutBottombar'),
  'layout-cards': () => import('./icons/IconLayoutCards'),
  'layout-collage': () => import('./icons/IconLayoutCollage'),
  'layout-columns': () => import('./icons/IconLayoutColumns'),
  'layout-dashboard': () => import('./icons/IconLayoutDashboard'),
  'layout-distribute-horizontal': () => import('./icons/IconLayoutDistributeHorizontal'),
  'layout-distribute-vertical': () => import('./icons/IconLayoutDistributeVertical'),
  'layout-grid-add': () => import('./icons/IconLayoutGridAdd'),
  'layout-grid-remove': () => import('./icons/IconLayoutGridRemove'),
  'layout-grid': () => import('./icons/IconLayoutGrid'),
  'layout-kanban': () => import('./icons/IconLayoutKanban'),
  'layout-list': () => import('./icons/IconLayoutList'),
  'layout-navbar-collapse': () => import('./icons/IconLayoutNavbarCollapse'),
  'layout-navbar-expand': () => import('./icons/IconLayoutNavbarExpand'),
  'layout-navbar-inactive': () => import('./icons/IconLayoutNavbarInactive'),
  'layout-navbar': () => import('./icons/IconLayoutNavbar'),
  'layout-off': () => import('./icons/IconLayoutOff'),
  'layout-rows': () => import('./icons/IconLayoutRows'),
  'layout-sidebar-inactive': () => import('./icons/IconLayoutSidebarInactive'),
  'layout-sidebar-left-collapse': () => import('./icons/IconLayoutSidebarLeftCollapse'),
  'layout-sidebar-left-expand': () => import('./icons/IconLayoutSidebarLeftExpand'),
  'layout-sidebar-right-collapse': () => import('./icons/IconLayoutSidebarRightCollapse'),
  'layout-sidebar-right-expand': () => import('./icons/IconLayoutSidebarRightExpand'),
  'layout-sidebar-right-inactive': () => import('./icons/IconLayoutSidebarRightInactive'),
  'layout-sidebar-right': () => import('./icons/IconLayoutSidebarRight'),
  'layout-sidebar': () => import('./icons/IconLayoutSidebar'),
  'layout': () => import('./icons/IconLayout'),
  'leaf-2': () => import('./icons/IconLeaf2'),
  'leaf-off': () => import('./icons/IconLeafOff'),
  'leaf': () => import('./icons/IconLeaf'),
  'lego-off': () => import('./icons/IconLegoOff'),
  'lego': () => import('./icons/IconLego'),
  'lemon-2': () => import('./icons/IconLemon2'),
  'lemon': () => import('./icons/IconLemon'),
  'letter-a-small': () => import('./icons/IconLetterASmall'),
  'letter-a': () => import('./icons/IconLetterA'),
  'letter-b-small': () => import('./icons/IconLetterBSmall'),
  'letter-b': () => import('./icons/IconLetterB'),
  'letter-c-small': () => import('./icons/IconLetterCSmall'),
  'letter-c': () => import('./icons/IconLetterC'),
  'letter-case-lower': () => import('./icons/IconLetterCaseLower'),
  'letter-case-toggle': () => import('./icons/IconLetterCaseToggle'),
  'letter-case-upper': () => import('./icons/IconLetterCaseUpper'),
  'letter-case': () => import('./icons/IconLetterCase'),
  'letter-d-small': () => import('./icons/IconLetterDSmall'),
  'letter-d': () => import('./icons/IconLetterD'),
  'letter-e-small': () => import('./icons/IconLetterESmall'),
  'letter-e': () => import('./icons/IconLetterE'),
  'letter-f-small': () => import('./icons/IconLetterFSmall'),
  'letter-f': () => import('./icons/IconLetterF'),
  'letter-g-small': () => import('./icons/IconLetterGSmall'),
  'letter-g': () => import('./icons/IconLetterG'),
  'letter-h-small': () => import('./icons/IconLetterHSmall'),
  'letter-h': () => import('./icons/IconLetterH'),
  'letter-i-small': () => import('./icons/IconLetterISmall'),
  'letter-i': () => import('./icons/IconLetterI'),
  'letter-j-small': () => import('./icons/IconLetterJSmall'),
  'letter-j': () => import('./icons/IconLetterJ'),
  'letter-k-small': () => import('./icons/IconLetterKSmall'),
  'letter-k': () => import('./icons/IconLetterK'),
  'letter-l-small': () => import('./icons/IconLetterLSmall'),
  'letter-l': () => import('./icons/IconLetterL'),
  'letter-m-small': () => import('./icons/IconLetterMSmall'),
  'letter-m': () => import('./icons/IconLetterM'),
  'letter-n-small': () => import('./icons/IconLetterNSmall'),
  'letter-n': () => import('./icons/IconLetterN'),
  'letter-o-small': () => import('./icons/IconLetterOSmall'),
  'letter-o': () => import('./icons/IconLetterO'),
  'letter-p-small': () => import('./icons/IconLetterPSmall'),
  'letter-p': () => import('./icons/IconLetterP'),
  'letter-q-small': () => import('./icons/IconLetterQSmall'),
  'letter-q': () => import('./icons/IconLetterQ'),
  'letter-r-small': () => import('./icons/IconLetterRSmall'),
  'letter-r': () => import('./icons/IconLetterR'),
  'letter-s-small': () => import('./icons/IconLetterSSmall'),
  'letter-s': () => import('./icons/IconLetterS'),
  'letter-spacing': () => import('./icons/IconLetterSpacing'),
  'letter-t-small': () => import('./icons/IconLetterTSmall'),
  'letter-t': () => import('./icons/IconLetterT'),
  'letter-u-small': () => import('./icons/IconLetterUSmall'),
  'letter-u': () => import('./icons/IconLetterU'),
  'letter-v-small': () => import('./icons/IconLetterVSmall'),
  'letter-v': () => import('./icons/IconLetterV'),
  'letter-w-small': () => import('./icons/IconLetterWSmall'),
  'letter-w': () => import('./icons/IconLetterW'),
  'letter-x-small': () => import('./icons/IconLetterXSmall'),
  'letter-x': () => import('./icons/IconLetterX'),
  'letter-y-small': () => import('./icons/IconLetterYSmall'),
  'letter-y': () => import('./icons/IconLetterY'),
  'letter-z-small': () => import('./icons/IconLetterZSmall'),
  'letter-z': () => import('./icons/IconLetterZ'),
  'library-minus': () => import('./icons/IconLibraryMinus'),
  'library-photo': () => import('./icons/IconLibraryPhoto'),
  'library-plus': () => import('./icons/IconLibraryPlus'),
  'library': () => import('./icons/IconLibrary'),
  'license-off': () => import('./icons/IconLicenseOff'),
  'license': () => import('./icons/IconLicense'),
  'lifebuoy-off': () => import('./icons/IconLifebuoyOff'),
  'lifebuoy': () => import('./icons/IconLifebuoy'),
  'lighter': () => import('./icons/IconLighter'),
  'line-dashed': () => import('./icons/IconLineDashed'),
  'line-dotted': () => import('./icons/IconLineDotted'),
  'line-height': () => import('./icons/IconLineHeight'),
  'line-scan': () => import('./icons/IconLineScan'),
  'line': () => import('./icons/IconLine'),
  'link-minus': () => import('./icons/IconLinkMinus'),
  'link-off': () => import('./icons/IconLinkOff'),
  'link-plus': () => import('./icons/IconLinkPlus'),
  'link': () => import('./icons/IconLink'),
  'list-check': () => import('./icons/IconListCheck'),
  'list-details': () => import('./icons/IconListDetails'),
  'list-letters': () => import('./icons/IconListLetters'),
  'list-numbers': () => import('./icons/IconListNumbers'),
  'list-search': () => import('./icons/IconListSearch'),
  'list-tree': () => import('./icons/IconListTree'),
  'list': () => import('./icons/IconList'),
  'live-photo-off': () => import('./icons/IconLivePhotoOff'),
  'live-photo': () => import('./icons/IconLivePhoto'),
  'live-view': () => import('./icons/IconLiveView'),
  'load-balancer': () => import('./icons/IconLoadBalancer'),
  'loader-2': () => import('./icons/IconLoader2'),
  'loader-3': () => import('./icons/IconLoader3'),
  'loader-quarter': () => import('./icons/IconLoaderQuarter'),
  'loader': () => import('./icons/IconLoader'),
  'location-bolt': () => import('./icons/IconLocationBolt'),
  'location-broken': () => import('./icons/IconLocationBroken'),
  'location-cancel': () => import('./icons/IconLocationCancel'),
  'location-check': () => import('./icons/IconLocationCheck'),
  'location-code': () => import('./icons/IconLocationCode'),
  'location-cog': () => import('./icons/IconLocationCog'),
  'location-discount': () => import('./icons/IconLocationDiscount'),
  'location-dollar': () => import('./icons/IconLocationDollar'),
  'location-down': () => import('./icons/IconLocationDown'),
  'location-exclamation': () => import('./icons/IconLocationExclamation'),
  'location-heart': () => import('./icons/IconLocationHeart'),
  'location-minus': () => import('./icons/IconLocationMinus'),
  'location-off': () => import('./icons/IconLocationOff'),
  'location-pause': () => import('./icons/IconLocationPause'),
  'location-pin': () => import('./icons/IconLocationPin'),
  'location-plus': () => import('./icons/IconLocationPlus'),
  'location-question': () => import('./icons/IconLocationQuestion'),
  'location-search': () => import('./icons/IconLocationSearch'),
  'location-share': () => import('./icons/IconLocationShare'),
  'location-star': () => import('./icons/IconLocationStar'),
  'location-up': () => import('./icons/IconLocationUp'),
  'location-x': () => import('./icons/IconLocationX'),
  'location': () => import('./icons/IconLocation'),
  'lock-access-off': () => import('./icons/IconLockAccessOff'),
  'lock-access': () => import('./icons/IconLockAccess'),
  'lock-bitcoin': () => import('./icons/IconLockBitcoin'),
  'lock-bolt': () => import('./icons/IconLockBolt'),
  'lock-cancel': () => import('./icons/IconLockCancel'),
  'lock-check': () => import('./icons/IconLockCheck'),
  'lock-code': () => import('./icons/IconLockCode'),
  'lock-cog': () => import('./icons/IconLockCog'),
  'lock-dollar': () => import('./icons/IconLockDollar'),
  'lock-down': () => import('./icons/IconLockDown'),
  'lock-exclamation': () => import('./icons/IconLockExclamation'),
  'lock-heart': () => import('./icons/IconLockHeart'),
  'lock-minus': () => import('./icons/IconLockMinus'),
  'lock-off': () => import('./icons/IconLockOff'),
  'lock-open-2': () => import('./icons/IconLockOpen2'),
  'lock-open-off': () => import('./icons/IconLockOpenOff'),
  'lock-open': () => import('./icons/IconLockOpen'),
  'lock-password': () => import('./icons/IconLockPassword'),
  'lock-pause': () => import('./icons/IconLockPause'),
  'lock-pin': () => import('./icons/IconLockPin'),
  'lock-plus': () => import('./icons/IconLockPlus'),
  'lock-question': () => import('./icons/IconLockQuestion'),
  'lock-search': () => import('./icons/IconLockSearch'),
  'lock-share': () => import('./icons/IconLockShare'),
  'lock-square-rounded': () => import('./icons/IconLockSquareRounded'),
  'lock-square': () => import('./icons/IconLockSquare'),
  'lock-star': () => import('./icons/IconLockStar'),
  'lock-up': () => import('./icons/IconLockUp'),
  'lock-x': () => import('./icons/IconLockX'),
  'lock': () => import('./icons/IconLock'),
  'logic-and': () => import('./icons/IconLogicAnd'),
  'logic-buffer': () => import('./icons/IconLogicBuffer'),
  'logic-nand': () => import('./icons/IconLogicNand'),
  'logic-nor': () => import('./icons/IconLogicNor'),
  'logic-not': () => import('./icons/IconLogicNot'),
  'logic-or': () => import('./icons/IconLogicOr'),
  'logic-xnor': () => import('./icons/IconLogicXnor'),
  'logic-xor': () => import('./icons/IconLogicXor'),
  'login-2': () => import('./icons/IconLogin2'),
  'login': () => import('./icons/IconLogin'),
  'logout-2': () => import('./icons/IconLogout2'),
  'logout': () => import('./icons/IconLogout'),
  'logs': () => import('./icons/IconLogs'),
  'lollipop-off': () => import('./icons/IconLollipopOff'),
  'lollipop': () => import('./icons/IconLollipop'),
  'luggage-off': () => import('./icons/IconLuggageOff'),
  'luggage': () => import('./icons/IconLuggage'),
  'lungs-off': () => import('./icons/IconLungsOff'),
  'lungs': () => import('./icons/IconLungs'),
  'macro-off': () => import('./icons/IconMacroOff'),
  'macro': () => import('./icons/IconMacro'),
  'magnet-off': () => import('./icons/IconMagnetOff'),
  'magnet': () => import('./icons/IconMagnet'),
  'magnetic': () => import('./icons/IconMagnetic'),
  'mail-ai': () => import('./icons/IconMailAi'),
  'mail-bitcoin': () => import('./icons/IconMailBitcoin'),
  'mail-bolt': () => import('./icons/IconMailBolt'),
  'mail-cancel': () => import('./icons/IconMailCancel'),
  'mail-check': () => import('./icons/IconMailCheck'),
  'mail-code': () => import('./icons/IconMailCode'),
  'mail-cog': () => import('./icons/IconMailCog'),
  'mail-dollar': () => import('./icons/IconMailDollar'),
  'mail-down': () => import('./icons/IconMailDown'),
  'mail-exclamation': () => import('./icons/IconMailExclamation'),
  'mail-fast': () => import('./icons/IconMailFast'),
  'mail-forward': () => import('./icons/IconMailForward'),
  'mail-heart': () => import('./icons/IconMailHeart'),
  'mail-minus': () => import('./icons/IconMailMinus'),
  'mail-off': () => import('./icons/IconMailOff'),
  'mail-opened': () => import('./icons/IconMailOpened'),
  'mail-pause': () => import('./icons/IconMailPause'),
  'mail-pin': () => import('./icons/IconMailPin'),
  'mail-plus': () => import('./icons/IconMailPlus'),
  'mail-question': () => import('./icons/IconMailQuestion'),
  'mail-search': () => import('./icons/IconMailSearch'),
  'mail-share': () => import('./icons/IconMailShare'),
  'mail-spark': () => import('./icons/IconMailSpark'),
  'mail-star': () => import('./icons/IconMailStar'),
  'mail-up': () => import('./icons/IconMailUp'),
  'mail-x': () => import('./icons/IconMailX'),
  'mail': () => import('./icons/IconMail'),
  'mailbox-off': () => import('./icons/IconMailboxOff'),
  'mailbox': () => import('./icons/IconMailbox'),
  'man': () => import('./icons/IconMan'),
  'manual-gearbox': () => import('./icons/IconManualGearbox'),
  'map-2': () => import('./icons/IconMap2'),
  'map-bolt': () => import('./icons/IconMapBolt'),
  'map-cancel': () => import('./icons/IconMapCancel'),
  'map-check': () => import('./icons/IconMapCheck'),
  'map-code': () => import('./icons/IconMapCode'),
  'map-cog': () => import('./icons/IconMapCog'),
  'map-discount': () => import('./icons/IconMapDiscount'),
  'map-dollar': () => import('./icons/IconMapDollar'),
  'map-down': () => import('./icons/IconMapDown'),
  'map-east': () => import('./icons/IconMapEast'),
  'map-exclamation': () => import('./icons/IconMapExclamation'),
  'map-heart': () => import('./icons/IconMapHeart'),
  'map-minus': () => import('./icons/IconMapMinus'),
  'map-north': () => import('./icons/IconMapNorth'),
  'map-off': () => import('./icons/IconMapOff'),
  'map-pause': () => import('./icons/IconMapPause'),
  'map-pin-2': () => import('./icons/IconMapPin2'),
  'map-pin-bolt': () => import('./icons/IconMapPinBolt'),
  'map-pin-cancel': () => import('./icons/IconMapPinCancel'),
  'map-pin-check': () => import('./icons/IconMapPinCheck'),
  'map-pin-code': () => import('./icons/IconMapPinCode'),
  'map-pin-cog': () => import('./icons/IconMapPinCog'),
  'map-pin-dollar': () => import('./icons/IconMapPinDollar'),
  'map-pin-down': () => import('./icons/IconMapPinDown'),
  'map-pin-exclamation': () => import('./icons/IconMapPinExclamation'),
  'map-pin-heart': () => import('./icons/IconMapPinHeart'),
  'map-pin-minus': () => import('./icons/IconMapPinMinus'),
  'map-pin-off': () => import('./icons/IconMapPinOff'),
  'map-pin-pause': () => import('./icons/IconMapPinPause'),
  'map-pin-pin': () => import('./icons/IconMapPinPin'),
  'map-pin-plus': () => import('./icons/IconMapPinPlus'),
  'map-pin-question': () => import('./icons/IconMapPinQuestion'),
  'map-pin-search': () => import('./icons/IconMapPinSearch'),
  'map-pin-share': () => import('./icons/IconMapPinShare'),
  'map-pin-star': () => import('./icons/IconMapPinStar'),
  'map-pin-up': () => import('./icons/IconMapPinUp'),
  'map-pin-x': () => import('./icons/IconMapPinX'),
  'map-pin': () => import('./icons/IconMapPin'),
  'map-pins': () => import('./icons/IconMapPins'),
  'map-plus': () => import('./icons/IconMapPlus'),
  'map-question': () => import('./icons/IconMapQuestion'),
  'map-route': () => import('./icons/IconMapRoute'),
  'map-search': () => import('./icons/IconMapSearch'),
  'map-share': () => import('./icons/IconMapShare'),
  'map-south': () => import('./icons/IconMapSouth'),
  'map-star': () => import('./icons/IconMapStar'),
  'map-up': () => import('./icons/IconMapUp'),
  'map-west': () => import('./icons/IconMapWest'),
  'map-x': () => import('./icons/IconMapX'),
  'map': () => import('./icons/IconMap'),
  'markdown-off': () => import('./icons/IconMarkdownOff'),
  'markdown': () => import('./icons/IconMarkdown'),
  'marquee-2': () => import('./icons/IconMarquee2'),
  'marquee-off': () => import('./icons/IconMarqueeOff'),
  'marquee': () => import('./icons/IconMarquee'),
  'mars': () => import('./icons/IconMars'),
  'mask-off': () => import('./icons/IconMaskOff'),
  'mask': () => import('./icons/IconMask'),
  'masks-theater-off': () => import('./icons/IconMasksTheaterOff'),
  'masks-theater': () => import('./icons/IconMasksTheater'),
  'massage': () => import('./icons/IconMassage'),
  'matchstick': () => import('./icons/IconMatchstick'),
  'math-1-divide-2': () => import('./icons/IconMath1Divide2'),
  'math-1-divide-3': () => import('./icons/IconMath1Divide3'),
  'math-avg': () => import('./icons/IconMathAvg'),
  'math-cos': () => import('./icons/IconMathCos'),
  'math-ctg': () => import('./icons/IconMathCtg'),
  'math-equal-greater': () => import('./icons/IconMathEqualGreater'),
  'math-equal-lower': () => import('./icons/IconMathEqualLower'),
  'math-function-off': () => import('./icons/IconMathFunctionOff'),
  'math-function-y': () => import('./icons/IconMathFunctionY'),
  'math-function': () => import('./icons/IconMathFunction'),
  'math-greater': () => import('./icons/IconMathGreater'),
  'math-integral-x': () => import('./icons/IconMathIntegralX'),
  'math-integral': () => import('./icons/IconMathIntegral'),
  'math-integrals': () => import('./icons/IconMathIntegrals'),
  'math-lower': () => import('./icons/IconMathLower'),
  'math-max-min': () => import('./icons/IconMathMaxMin'),
  'math-max': () => import('./icons/IconMathMax'),
  'math-min': () => import('./icons/IconMathMin'),
  'math-not': () => import('./icons/IconMathNot'),
  'math-off': () => import('./icons/IconMathOff'),
  'math-pi-divide-2': () => import('./icons/IconMathPiDivide2'),
  'math-pi': () => import('./icons/IconMathPi'),
  'math-sec': () => import('./icons/IconMathSec'),
  'math-sin': () => import('./icons/IconMathSin'),
  'math-symbols': () => import('./icons/IconMathSymbols'),
  'math-tg': () => import('./icons/IconMathTg'),
  'math-x-divide-2': () => import('./icons/IconMathXDivide2'),
  'math-x-divide-y-2': () => import('./icons/IconMathXDivideY2'),
  'math-x-divide-y': () => import('./icons/IconMathXDivideY'),
  'math-x-floor-divide-y': () => import('./icons/IconMathXFloorDivideY'),
  'math-x-minus-x': () => import('./icons/IconMathXMinusX'),
  'math-x-minus-y': () => import('./icons/IconMathXMinusY'),
  'math-x-plus-x': () => import('./icons/IconMathXPlusX'),
  'math-x-plus-y': () => import('./icons/IconMathXPlusY'),
  'math-xy': () => import('./icons/IconMathXy'),
  'math-y-minus-y': () => import('./icons/IconMathYMinusY'),
  'math-y-plus-y': () => import('./icons/IconMathYPlusY'),
  'math': () => import('./icons/IconMath'),
  'maximize-off': () => import('./icons/IconMaximizeOff'),
  'maximize': () => import('./icons/IconMaximize'),
  'meat-off': () => import('./icons/IconMeatOff'),
  'meat': () => import('./icons/IconMeat'),
  'medal-2': () => import('./icons/IconMedal2'),
  'medal': () => import('./icons/IconMedal'),
  'medical-cross-circle': () => import('./icons/IconMedicalCrossCircle'),
  'medical-cross-off': () => import('./icons/IconMedicalCrossOff'),
  'medical-cross': () => import('./icons/IconMedicalCross'),
  'medicine-syrup': () => import('./icons/IconMedicineSyrup'),
  'meeple': () => import('./icons/IconMeeple'),
  'melon': () => import('./icons/IconMelon'),
  'menorah': () => import('./icons/IconMenorah'),
  'menu-2': () => import('./icons/IconMenu2'),
  'menu-3': () => import('./icons/IconMenu3'),
  'menu-4': () => import('./icons/IconMenu4'),
  'menu-deep': () => import('./icons/IconMenuDeep'),
  'menu-order': () => import('./icons/IconMenuOrder'),
  'menu': () => import('./icons/IconMenu'),
  'message-2-bolt': () => import('./icons/IconMessage2Bolt'),
  'message-2-cancel': () => import('./icons/IconMessage2Cancel'),
  'message-2-check': () => import('./icons/IconMessage2Check'),
  'message-2-code': () => import('./icons/IconMessage2Code'),
  'message-2-cog': () => import('./icons/IconMessage2Cog'),
  'message-2-dollar': () => import('./icons/IconMessage2Dollar'),
  'message-2-down': () => import('./icons/IconMessage2Down'),
  'message-2-exclamation': () => import('./icons/IconMessage2Exclamation'),
  'message-2-heart': () => import('./icons/IconMessage2Heart'),
  'message-2-minus': () => import('./icons/IconMessage2Minus'),
  'message-2-off': () => import('./icons/IconMessage2Off'),
  'message-2-pause': () => import('./icons/IconMessage2Pause'),
  'message-2-pin': () => import('./icons/IconMessage2Pin'),
  'message-2-plus': () => import('./icons/IconMessage2Plus'),
  'message-2-question': () => import('./icons/IconMessage2Question'),
  'message-2-search': () => import('./icons/IconMessage2Search'),
  'message-2-share': () => import('./icons/IconMessage2Share'),
  'message-2-star': () => import('./icons/IconMessage2Star'),
  'message-2-up': () => import('./icons/IconMessage2Up'),
  'message-2-x': () => import('./icons/IconMessage2X'),
  'message-2': () => import('./icons/IconMessage2'),
  'message-bolt': () => import('./icons/IconMessageBolt'),
  'message-cancel': () => import('./icons/IconMessageCancel'),
  'message-chatbot': () => import('./icons/IconMessageChatbot'),
  'message-check': () => import('./icons/IconMessageCheck'),
  'message-circle-bolt': () => import('./icons/IconMessageCircleBolt'),
  'message-circle-cancel': () => import('./icons/IconMessageCircleCancel'),
  'message-circle-check': () => import('./icons/IconMessageCircleCheck'),
  'message-circle-code': () => import('./icons/IconMessageCircleCode'),
  'message-circle-cog': () => import('./icons/IconMessageCircleCog'),
  'message-circle-dollar': () => import('./icons/IconMessageCircleDollar'),
  'message-circle-down': () => import('./icons/IconMessageCircleDown'),
  'message-circle-exclamation': () => import('./icons/IconMessageCircleExclamation'),
  'message-circle-heart': () => import('./icons/IconMessageCircleHeart'),
  'message-circle-minus': () => import('./icons/IconMessageCircleMinus'),
  'message-circle-off': () => import('./icons/IconMessageCircleOff'),
  'message-circle-pause': () => import('./icons/IconMessageCirclePause'),
  'message-circle-pin': () => import('./icons/IconMessageCirclePin'),
  'message-circle-plus': () => import('./icons/IconMessageCirclePlus'),
  'message-circle-question': () => import('./icons/IconMessageCircleQuestion'),
  'message-circle-search': () => import('./icons/IconMessageCircleSearch'),
  'message-circle-share': () => import('./icons/IconMessageCircleShare'),
  'message-circle-star': () => import('./icons/IconMessageCircleStar'),
  'message-circle-up': () => import('./icons/IconMessageCircleUp'),
  'message-circle-user': () => import('./icons/IconMessageCircleUser'),
  'message-circle-x': () => import('./icons/IconMessageCircleX'),
  'message-circle': () => import('./icons/IconMessageCircle'),
  'message-code': () => import('./icons/IconMessageCode'),
  'message-cog': () => import('./icons/IconMessageCog'),
  'message-dollar': () => import('./icons/IconMessageDollar'),
  'message-dots': () => import('./icons/IconMessageDots'),
  'message-down': () => import('./icons/IconMessageDown'),
  'message-exclamation': () => import('./icons/IconMessageExclamation'),
  'message-forward': () => import('./icons/IconMessageForward'),
  'message-heart': () => import('./icons/IconMessageHeart'),
  'message-language': () => import('./icons/IconMessageLanguage'),
  'message-minus': () => import('./icons/IconMessageMinus'),
  'message-off': () => import('./icons/IconMessageOff'),
  'message-pause': () => import('./icons/IconMessagePause'),
  'message-pin': () => import('./icons/IconMessagePin'),
  'message-plus': () => import('./icons/IconMessagePlus'),
  'message-question': () => import('./icons/IconMessageQuestion'),
  'message-reply': () => import('./icons/IconMessageReply'),
  'message-report': () => import('./icons/IconMessageReport'),
  'message-search': () => import('./icons/IconMessageSearch'),
  'message-share': () => import('./icons/IconMessageShare'),
  'message-star': () => import('./icons/IconMessageStar'),
  'message-up': () => import('./icons/IconMessageUp'),
  'message-user': () => import('./icons/IconMessageUser'),
  'message-x': () => import('./icons/IconMessageX'),
  'message': () => import('./icons/IconMessage'),
  'messages-off': () => import('./icons/IconMessagesOff'),
  'messages': () => import('./icons/IconMessages'),
  'meteor-off': () => import('./icons/IconMeteorOff'),
  'meteor': () => import('./icons/IconMeteor'),
  'meter-cube': () => import('./icons/IconMeterCube'),
  'meter-square': () => import('./icons/IconMeterSquare'),
  'metronome': () => import('./icons/IconMetronome'),
  'michelin-bib-gourmand': () => import('./icons/IconMichelinBibGourmand'),
  'michelin-star-green': () => import('./icons/IconMichelinStarGreen'),
  'michelin-star': () => import('./icons/IconMichelinStar'),
  'mickey': () => import('./icons/IconMickey'),
  'microphone-2-off': () => import('./icons/IconMicrophone2Off'),
  'microphone-2': () => import('./icons/IconMicrophone2'),
  'microphone-off': () => import('./icons/IconMicrophoneOff'),
  'microphone': () => import('./icons/IconMicrophone'),
  'microscope-off': () => import('./icons/IconMicroscopeOff'),
  'microscope': () => import('./icons/IconMicroscope'),
  'microwave-off': () => import('./icons/IconMicrowaveOff'),
  'microwave': () => import('./icons/IconMicrowave'),
  'military-award': () => import('./icons/IconMilitaryAward'),
  'military-rank': () => import('./icons/IconMilitaryRank'),
  'milk-off': () => import('./icons/IconMilkOff'),
  'milk': () => import('./icons/IconMilk'),
  'milkshake': () => import('./icons/IconMilkshake'),
  'minimize': () => import('./icons/IconMinimize'),
  'minus-vertical': () => import('./icons/IconMinusVertical'),
  'minus': () => import('./icons/IconMinus'),
  'mist-off': () => import('./icons/IconMistOff'),
  'mist': () => import('./icons/IconMist'),
  'mobiledata-off': () => import('./icons/IconMobiledataOff'),
  'mobiledata': () => import('./icons/IconMobiledata'),
  'moneybag': () => import('./icons/IconMoneybag'),
  'monkeybar': () => import('./icons/IconMonkeybar'),
  'mood-angry': () => import('./icons/IconMoodAngry'),
  'mood-annoyed-2': () => import('./icons/IconMoodAnnoyed2'),
  'mood-annoyed': () => import('./icons/IconMoodAnnoyed'),
  'mood-bitcoin': () => import('./icons/IconMoodBitcoin'),
  'mood-boy': () => import('./icons/IconMoodBoy'),
  'mood-check': () => import('./icons/IconMoodCheck'),
  'mood-cog': () => import('./icons/IconMoodCog'),
  'mood-confuzed': () => import('./icons/IconMoodConfuzed'),
  'mood-crazy-happy': () => import('./icons/IconMoodCrazyHappy'),
  'mood-cry': () => import('./icons/IconMoodCry'),
  'mood-dollar': () => import('./icons/IconMoodDollar'),
  'mood-edit': () => import('./icons/IconMoodEdit'),
  'mood-empty': () => import('./icons/IconMoodEmpty'),
  'mood-happy': () => import('./icons/IconMoodHappy'),
  'mood-heart': () => import('./icons/IconMoodHeart'),
  'mood-kid': () => import('./icons/IconMoodKid'),
  'mood-look-down': () => import('./icons/IconMoodLookDown'),
  'mood-look-left': () => import('./icons/IconMoodLookLeft'),
  'mood-look-right': () => import('./icons/IconMoodLookRight'),
  'mood-look-up': () => import('./icons/IconMoodLookUp'),
  'mood-minus': () => import('./icons/IconMoodMinus'),
  'mood-nerd': () => import('./icons/IconMoodNerd'),
  'mood-nervous': () => import('./icons/IconMoodNervous'),
  'mood-neutral': () => import('./icons/IconMoodNeutral'),
  'mood-off': () => import('./icons/IconMoodOff'),
  'mood-pin': () => import('./icons/IconMoodPin'),
  'mood-plus': () => import('./icons/IconMoodPlus'),
  'mood-puzzled': () => import('./icons/IconMoodPuzzled'),
  'mood-sad-2': () => import('./icons/IconMoodSad2'),
  'mood-sad-dizzy': () => import('./icons/IconMoodSadDizzy'),
  'mood-sad-squint': () => import('./icons/IconMoodSadSquint'),
  'mood-sad': () => import('./icons/IconMoodSad'),
  'mood-search': () => import('./icons/IconMoodSearch'),
  'mood-share': () => import('./icons/IconMoodShare'),
  'mood-sick': () => import('./icons/IconMoodSick'),
  'mood-silence': () => import('./icons/IconMoodSilence'),
  'mood-sing': () => import('./icons/IconMoodSing'),
  'mood-smile-beam': () => import('./icons/IconMoodSmileBeam'),
  'mood-smile-dizzy': () => import('./icons/IconMoodSmileDizzy'),
  'mood-smile': () => import('./icons/IconMoodSmile'),
  'mood-spark': () => import('./icons/IconMoodSpark'),
  'mood-surprised': () => import('./icons/IconMoodSurprised'),
  'mood-tongue-wink-2': () => import('./icons/IconMoodTongueWink2'),
  'mood-tongue-wink': () => import('./icons/IconMoodTongueWink'),
  'mood-tongue': () => import('./icons/IconMoodTongue'),
  'mood-unamused': () => import('./icons/IconMoodUnamused'),
  'mood-up': () => import('./icons/IconMoodUp'),
  'mood-wink-2': () => import('./icons/IconMoodWink2'),
  'mood-wink': () => import('./icons/IconMoodWink'),
  'mood-wrrr': () => import('./icons/IconMoodWrrr'),
  'mood-x': () => import('./icons/IconMoodX'),
  'mood-xd': () => import('./icons/IconMoodXd'),
  'moon-2': () => import('./icons/IconMoon2'),
  'moon-off': () => import('./icons/IconMoonOff'),
  'moon-stars': () => import('./icons/IconMoonStars'),
  'moon': () => import('./icons/IconMoon'),
  'moped': () => import('./icons/IconMoped'),
  'motorbike': () => import('./icons/IconMotorbike'),
  'mountain-off': () => import('./icons/IconMountainOff'),
  'mountain': () => import('./icons/IconMountain'),
  'mouse-2': () => import('./icons/IconMouse2'),
  'mouse-off': () => import('./icons/IconMouseOff'),
  'mouse': () => import('./icons/IconMouse'),
  'moustache': () => import('./icons/IconMoustache'),
  'movie-off': () => import('./icons/IconMovieOff'),
  'movie': () => import('./icons/IconMovie'),
  'mug-off': () => import('./icons/IconMugOff'),
  'mug': () => import('./icons/IconMug'),
  'multiplier-0-5x': () => import('./icons/IconMultiplier05x'),
  'multiplier-1-5x': () => import('./icons/IconMultiplier15x'),
  'multiplier-1x': () => import('./icons/IconMultiplier1x'),
  'multiplier-2x': () => import('./icons/IconMultiplier2x'),
  'mushroom-off': () => import('./icons/IconMushroomOff'),
  'mushroom': () => import('./icons/IconMushroom'),
  'music-bolt': () => import('./icons/IconMusicBolt'),
  'music-cancel': () => import('./icons/IconMusicCancel'),
  'music-check': () => import('./icons/IconMusicCheck'),
  'music-code': () => import('./icons/IconMusicCode'),
  'music-cog': () => import('./icons/IconMusicCog'),
  'music-discount': () => import('./icons/IconMusicDiscount'),
  'music-dollar': () => import('./icons/IconMusicDollar'),
  'music-down': () => import('./icons/IconMusicDown'),
  'music-exclamation': () => import('./icons/IconMusicExclamation'),
  'music-heart': () => import('./icons/IconMusicHeart'),
  'music-minus': () => import('./icons/IconMusicMinus'),
  'music-off': () => import('./icons/IconMusicOff'),
  'music-pause': () => import('./icons/IconMusicPause'),
  'music-pin': () => import('./icons/IconMusicPin'),
  'music-plus': () => import('./icons/IconMusicPlus'),
  'music-question': () => import('./icons/IconMusicQuestion'),
  'music-search': () => import('./icons/IconMusicSearch'),
  'music-share': () => import('./icons/IconMusicShare'),
  'music-star': () => import('./icons/IconMusicStar'),
  'music-up': () => import('./icons/IconMusicUp'),
  'music-x': () => import('./icons/IconMusicX'),
  'music': () => import('./icons/IconMusic'),
  'navigation-bolt': () => import('./icons/IconNavigationBolt'),
  'navigation-cancel': () => import('./icons/IconNavigationCancel'),
  'navigation-check': () => import('./icons/IconNavigationCheck'),
  'navigation-code': () => import('./icons/IconNavigationCode'),
  'navigation-cog': () => import('./icons/IconNavigationCog'),
  'navigation-discount': () => import('./icons/IconNavigationDiscount'),
  'navigation-dollar': () => import('./icons/IconNavigationDollar'),
  'navigation-down': () => import('./icons/IconNavigationDown'),
  'navigation-east': () => import('./icons/IconNavigationEast'),
  'navigation-exclamation': () => import('./icons/IconNavigationExclamation'),
  'navigation-heart': () => import('./icons/IconNavigationHeart'),
  'navigation-minus': () => import('./icons/IconNavigationMinus'),
  'navigation-north': () => import('./icons/IconNavigationNorth'),
  'navigation-off': () => import('./icons/IconNavigationOff'),
  'navigation-pause': () => import('./icons/IconNavigationPause'),
  'navigation-pin': () => import('./icons/IconNavigationPin'),
  'navigation-plus': () => import('./icons/IconNavigationPlus'),
  'navigation-question': () => import('./icons/IconNavigationQuestion'),
  'navigation-search': () => import('./icons/IconNavigationSearch'),
  'navigation-share': () => import('./icons/IconNavigationShare'),
  'navigation-south': () => import('./icons/IconNavigationSouth'),
  'navigation-star': () => import('./icons/IconNavigationStar'),
  'navigation-top': () => import('./icons/IconNavigationTop'),
  'navigation-up': () => import('./icons/IconNavigationUp'),
  'navigation-west': () => import('./icons/IconNavigationWest'),
  'navigation-x': () => import('./icons/IconNavigationX'),
  'navigation': () => import('./icons/IconNavigation'),
  'needle-thread': () => import('./icons/IconNeedleThread'),
  'needle': () => import('./icons/IconNeedle'),
  'network-off': () => import('./icons/IconNetworkOff'),
  'network': () => import('./icons/IconNetwork'),
  'new-section': () => import('./icons/IconNewSection'),
  'news-off': () => import('./icons/IconNewsOff'),
  'news': () => import('./icons/IconNews'),
  'nfc-off': () => import('./icons/IconNfcOff'),
  'nfc': () => import('./icons/IconNfc'),
  'no-copyright': () => import('./icons/IconNoCopyright'),
  'no-creative-commons': () => import('./icons/IconNoCreativeCommons'),
  'no-derivatives': () => import('./icons/IconNoDerivatives'),
  'north-star': () => import('./icons/IconNorthStar'),
  'note-off': () => import('./icons/IconNoteOff'),
  'note': () => import('./icons/IconNote'),
  'notebook-off': () => import('./icons/IconNotebookOff'),
  'notebook': () => import('./icons/IconNotebook'),
  'notes-off': () => import('./icons/IconNotesOff'),
  'notes': () => import('./icons/IconNotes'),
  'notification-off': () => import('./icons/IconNotificationOff'),
  'notification': () => import('./icons/IconNotification'),
  'number-0-small': () => import('./icons/IconNumber0Small'),
  'number-0': () => import('./icons/IconNumber0'),
  'number-1-small': () => import('./icons/IconNumber1Small'),
  'number-1': () => import('./icons/IconNumber1'),
  'number-10-small': () => import('./icons/IconNumber10Small'),
  'number-10': () => import('./icons/IconNumber10'),
  'number-100-small': () => import('./icons/IconNumber100Small'),
  'number-11-small': () => import('./icons/IconNumber11Small'),
  'number-11': () => import('./icons/IconNumber11'),
  'number-12-small': () => import('./icons/IconNumber12Small'),
  'number-123': () => import('./icons/IconNumber123'),
  'number-13-small': () => import('./icons/IconNumber13Small'),
  'number-14-small': () => import('./icons/IconNumber14Small'),
  'number-15-small': () => import('./icons/IconNumber15Small'),
  'number-16-small': () => import('./icons/IconNumber16Small'),
  'number-17-small': () => import('./icons/IconNumber17Small'),
  'number-18-small': () => import('./icons/IconNumber18Small'),
  'number-19-small': () => import('./icons/IconNumber19Small'),
  'number-2-small': () => import('./icons/IconNumber2Small'),
  'number-2': () => import('./icons/IconNumber2'),
  'number-20-small': () => import('./icons/IconNumber20Small'),
  'number-21-small': () => import('./icons/IconNumber21Small'),
  'number-22-small': () => import('./icons/IconNumber22Small'),
  'number-23-small': () => import('./icons/IconNumber23Small'),
  'number-24-small': () => import('./icons/IconNumber24Small'),
  'number-25-small': () => import('./icons/IconNumber25Small'),
  'number-26-small': () => import('./icons/IconNumber26Small'),
  'number-27-small': () => import('./icons/IconNumber27Small'),
  'number-28-small': () => import('./icons/IconNumber28Small'),
  'number-29-small': () => import('./icons/IconNumber29Small'),
  'number-3-small': () => import('./icons/IconNumber3Small'),
  'number-3': () => import('./icons/IconNumber3'),
  'number-30-small': () => import('./icons/IconNumber30Small'),
  'number-31-small': () => import('./icons/IconNumber31Small'),
  'number-32-small': () => import('./icons/IconNumber32Small'),
  'number-33-small': () => import('./icons/IconNumber33Small'),
  'number-34-small': () => import('./icons/IconNumber34Small'),
  'number-35-small': () => import('./icons/IconNumber35Small'),
  'number-36-small': () => import('./icons/IconNumber36Small'),
  'number-37-small': () => import('./icons/IconNumber37Small'),
  'number-38-small': () => import('./icons/IconNumber38Small'),
  'number-39-small': () => import('./icons/IconNumber39Small'),
  'number-4-small': () => import('./icons/IconNumber4Small'),
  'number-4': () => import('./icons/IconNumber4'),
  'number-40-small': () => import('./icons/IconNumber40Small'),
  'number-41-small': () => import('./icons/IconNumber41Small'),
  'number-42-small': () => import('./icons/IconNumber42Small'),
  'number-43-small': () => import('./icons/IconNumber43Small'),
  'number-44-small': () => import('./icons/IconNumber44Small'),
  'number-45-small': () => import('./icons/IconNumber45Small'),
  'number-46-small': () => import('./icons/IconNumber46Small'),
  'number-47-small': () => import('./icons/IconNumber47Small'),
  'number-48-small': () => import('./icons/IconNumber48Small'),
  'number-49-small': () => import('./icons/IconNumber49Small'),
  'number-5-small': () => import('./icons/IconNumber5Small'),
  'number-5': () => import('./icons/IconNumber5'),
  'number-50-small': () => import('./icons/IconNumber50Small'),
  'number-51-small': () => import('./icons/IconNumber51Small'),
  'number-52-small': () => import('./icons/IconNumber52Small'),
  'number-53-small': () => import('./icons/IconNumber53Small'),
  'number-54-small': () => import('./icons/IconNumber54Small'),
  'number-55-small': () => import('./icons/IconNumber55Small'),
  'number-56-small': () => import('./icons/IconNumber56Small'),
  'number-57-small': () => import('./icons/IconNumber57Small'),
  'number-58-small': () => import('./icons/IconNumber58Small'),
  'number-59-small': () => import('./icons/IconNumber59Small'),
  'number-6-small': () => import('./icons/IconNumber6Small'),
  'number-6': () => import('./icons/IconNumber6'),
  'number-60-small': () => import('./icons/IconNumber60Small'),
  'number-61-small': () => import('./icons/IconNumber61Small'),
  'number-62-small': () => import('./icons/IconNumber62Small'),
  'number-63-small': () => import('./icons/IconNumber63Small'),
  'number-64-small': () => import('./icons/IconNumber64Small'),
  'number-65-small': () => import('./icons/IconNumber65Small'),
  'number-66-small': () => import('./icons/IconNumber66Small'),
  'number-67-small': () => import('./icons/IconNumber67Small'),
  'number-68-small': () => import('./icons/IconNumber68Small'),
  'number-69-small': () => import('./icons/IconNumber69Small'),
  'number-7-small': () => import('./icons/IconNumber7Small'),
  'number-7': () => import('./icons/IconNumber7'),
  'number-70-small': () => import('./icons/IconNumber70Small'),
  'number-71-small': () => import('./icons/IconNumber71Small'),
  'number-72-small': () => import('./icons/IconNumber72Small'),
  'number-73-small': () => import('./icons/IconNumber73Small'),
  'number-74-small': () => import('./icons/IconNumber74Small'),
  'number-75-small': () => import('./icons/IconNumber75Small'),
  'number-76-small': () => import('./icons/IconNumber76Small'),
  'number-77-small': () => import('./icons/IconNumber77Small'),
  'number-78-small': () => import('./icons/IconNumber78Small'),
  'number-79-small': () => import('./icons/IconNumber79Small'),
  'number-8-small': () => import('./icons/IconNumber8Small'),
  'number-8': () => import('./icons/IconNumber8'),
  'number-80-small': () => import('./icons/IconNumber80Small'),
  'number-81-small': () => import('./icons/IconNumber81Small'),
  'number-82-small': () => import('./icons/IconNumber82Small'),
  'number-83-small': () => import('./icons/IconNumber83Small'),
  'number-84-small': () => import('./icons/IconNumber84Small'),
  'number-85-small': () => import('./icons/IconNumber85Small'),
  'number-86-small': () => import('./icons/IconNumber86Small'),
  'number-87-small': () => import('./icons/IconNumber87Small'),
  'number-88-small': () => import('./icons/IconNumber88Small'),
  'number-89-small': () => import('./icons/IconNumber89Small'),
  'number-9-small': () => import('./icons/IconNumber9Small'),
  'number-9': () => import('./icons/IconNumber9'),
  'number-90-small': () => import('./icons/IconNumber90Small'),
  'number-91-small': () => import('./icons/IconNumber91Small'),
  'number-92-small': () => import('./icons/IconNumber92Small'),
  'number-93-small': () => import('./icons/IconNumber93Small'),
  'number-94-small': () => import('./icons/IconNumber94Small'),
  'number-95-small': () => import('./icons/IconNumber95Small'),
  'number-96-small': () => import('./icons/IconNumber96Small'),
  'number-97-small': () => import('./icons/IconNumber97Small'),
  'number-98-small': () => import('./icons/IconNumber98Small'),
  'number-99-small': () => import('./icons/IconNumber99Small'),
  'number': () => import('./icons/IconNumber'),
  'numbers': () => import('./icons/IconNumbers'),
  'nurse': () => import('./icons/IconNurse'),
  'nut': () => import('./icons/IconNut'),
  'object-scan': () => import('./icons/IconObjectScan'),
  'octagon-minus-2': () => import('./icons/IconOctagonMinus2'),
  'octagon-minus': () => import('./icons/IconOctagonMinus'),
  'octagon-off': () => import('./icons/IconOctagonOff'),
  'octagon-plus-2': () => import('./icons/IconOctagonPlus2'),
  'octagon-plus': () => import('./icons/IconOctagonPlus'),
  'octagon': () => import('./icons/IconOctagon'),
  'octahedron-off': () => import('./icons/IconOctahedronOff'),
  'octahedron-plus': () => import('./icons/IconOctahedronPlus'),
  'octahedron': () => import('./icons/IconOctahedron'),
  'old': () => import('./icons/IconOld'),
  'olympics-off': () => import('./icons/IconOlympicsOff'),
  'olympics': () => import('./icons/IconOlympics'),
  'om': () => import('./icons/IconOm'),
  'omega': () => import('./icons/IconOmega'),
  'outbound': () => import('./icons/IconOutbound'),
  'outlet': () => import('./icons/IconOutlet'),
  'oval-vertical': () => import('./icons/IconOvalVertical'),
  'oval': () => import('./icons/IconOval'),
  'overline': () => import('./icons/IconOverline'),
  'package-export': () => import('./icons/IconPackageExport'),
  'package-import': () => import('./icons/IconPackageImport'),
  'package-off': () => import('./icons/IconPackageOff'),
  'package': () => import('./icons/IconPackage'),
  'packages': () => import('./icons/IconPackages'),
  'pacman': () => import('./icons/IconPacman'),
  'page-break': () => import('./icons/IconPageBreak'),
  'paint-off': () => import('./icons/IconPaintOff'),
  'paint': () => import('./icons/IconPaint'),
  'palette-off': () => import('./icons/IconPaletteOff'),
  'palette': () => import('./icons/IconPalette'),
  'panorama-horizontal-off': () => import('./icons/IconPanoramaHorizontalOff'),
  'panorama-horizontal': () => import('./icons/IconPanoramaHorizontal'),
  'panorama-vertical-off': () => import('./icons/IconPanoramaVerticalOff'),
  'panorama-vertical': () => import('./icons/IconPanoramaVertical'),
  'paper-bag-off': () => import('./icons/IconPaperBagOff'),
  'paper-bag': () => import('./icons/IconPaperBag'),
  'paperclip': () => import('./icons/IconPaperclip'),
  'parachute-off': () => import('./icons/IconParachuteOff'),
  'parachute': () => import('./icons/IconParachute'),
  'parentheses-off': () => import('./icons/IconParenthesesOff'),
  'parentheses': () => import('./icons/IconParentheses'),
  'parking-circle': () => import('./icons/IconParkingCircle'),
  'parking-off': () => import('./icons/IconParkingOff'),
  'parking': () => import('./icons/IconParking'),
  'password-fingerprint': () => import('./icons/IconPasswordFingerprint'),
  'password-mobile-phone': () => import('./icons/IconPasswordMobilePhone'),
  'password-user': () => import('./icons/IconPasswordUser'),
  'password': () => import('./icons/IconPassword'),
  'paw-off': () => import('./icons/IconPawOff'),
  'paw': () => import('./icons/IconPaw'),
  'paywall': () => import('./icons/IconPaywall'),
  'pdf': () => import('./icons/IconPdf'),
  'peace': () => import('./icons/IconPeace'),
  'pencil-bolt': () => import('./icons/IconPencilBolt'),
  'pencil-cancel': () => import('./icons/IconPencilCancel'),
  'pencil-check': () => import('./icons/IconPencilCheck'),
  'pencil-code': () => import('./icons/IconPencilCode'),
  'pencil-cog': () => import('./icons/IconPencilCog'),
  'pencil-discount': () => import('./icons/IconPencilDiscount'),
  'pencil-dollar': () => import('./icons/IconPencilDollar'),
  'pencil-down': () => import('./icons/IconPencilDown'),
  'pencil-exclamation': () => import('./icons/IconPencilExclamation'),
  'pencil-heart': () => import('./icons/IconPencilHeart'),
  'pencil-minus': () => import('./icons/IconPencilMinus'),
  'pencil-off': () => import('./icons/IconPencilOff'),
  'pencil-pause': () => import('./icons/IconPencilPause'),
  'pencil-pin': () => import('./icons/IconPencilPin'),
  'pencil-plus': () => import('./icons/IconPencilPlus'),
  'pencil-question': () => import('./icons/IconPencilQuestion'),
  'pencil-search': () => import('./icons/IconPencilSearch'),
  'pencil-share': () => import('./icons/IconPencilShare'),
  'pencil-star': () => import('./icons/IconPencilStar'),
  'pencil-up': () => import('./icons/IconPencilUp'),
  'pencil-x': () => import('./icons/IconPencilX'),
  'pencil': () => import('./icons/IconPencil'),
  'pennant-2': () => import('./icons/IconPennant2'),
  'pennant-off': () => import('./icons/IconPennantOff'),
  'pennant': () => import('./icons/IconPennant'),
  'pentagon-minus': () => import('./icons/IconPentagonMinus'),
  'pentagon-number-0': () => import('./icons/IconPentagonNumber0'),
  'pentagon-number-1': () => import('./icons/IconPentagonNumber1'),
  'pentagon-number-2': () => import('./icons/IconPentagonNumber2'),
  'pentagon-number-3': () => import('./icons/IconPentagonNumber3'),
  'pentagon-number-4': () => import('./icons/IconPentagonNumber4'),
  'pentagon-number-5': () => import('./icons/IconPentagonNumber5'),
  'pentagon-number-6': () => import('./icons/IconPentagonNumber6'),
  'pentagon-number-7': () => import('./icons/IconPentagonNumber7'),
  'pentagon-number-8': () => import('./icons/IconPentagonNumber8'),
  'pentagon-number-9': () => import('./icons/IconPentagonNumber9'),
  'pentagon-off': () => import('./icons/IconPentagonOff'),
  'pentagon-plus': () => import('./icons/IconPentagonPlus'),
  'pentagon-x': () => import('./icons/IconPentagonX'),
  'pentagon': () => import('./icons/IconPentagon'),
  'pentagram': () => import('./icons/IconPentagram'),
  'pepper-off': () => import('./icons/IconPepperOff'),
  'pepper': () => import('./icons/IconPepper'),
  'percentage-0': () => import('./icons/IconPercentage0'),
  'percentage-10': () => import('./icons/IconPercentage10'),
  'percentage-100': () => import('./icons/IconPercentage100'),
  'percentage-20': () => import('./icons/IconPercentage20'),
  'percentage-25': () => import('./icons/IconPercentage25'),
  'percentage-30': () => import('./icons/IconPercentage30'),
  'percentage-33': () => import('./icons/IconPercentage33'),
  'percentage-40': () => import('./icons/IconPercentage40'),
  'percentage-50': () => import('./icons/IconPercentage50'),
  'percentage-60': () => import('./icons/IconPercentage60'),
  'percentage-66': () => import('./icons/IconPercentage66'),
  'percentage-70': () => import('./icons/IconPercentage70'),
  'percentage-75': () => import('./icons/IconPercentage75'),
  'percentage-80': () => import('./icons/IconPercentage80'),
  'percentage-90': () => import('./icons/IconPercentage90'),
  'percentage': () => import('./icons/IconPercentage'),
  'perfume': () => import('./icons/IconPerfume'),
  'perspective-off': () => import('./icons/IconPerspectiveOff'),
  'perspective': () => import('./icons/IconPerspective'),
  'phone-call': () => import('./icons/IconPhoneCall'),
  'phone-calling': () => import('./icons/IconPhoneCalling'),
  'phone-check': () => import('./icons/IconPhoneCheck'),
  'phone-done': () => import('./icons/IconPhoneDone'),
  'phone-end': () => import('./icons/IconPhoneEnd'),
  'phone-incoming': () => import('./icons/IconPhoneIncoming'),
  'phone-off': () => import('./icons/IconPhoneOff'),
  'phone-outgoing': () => import('./icons/IconPhoneOutgoing'),
  'phone-pause': () => import('./icons/IconPhonePause'),
  'phone-plus': () => import('./icons/IconPhonePlus'),
  'phone-ringing': () => import('./icons/IconPhoneRinging'),
  'phone-spark': () => import('./icons/IconPhoneSpark'),
  'phone-x': () => import('./icons/IconPhoneX'),
  'phone': () => import('./icons/IconPhone'),
  'photo-ai': () => import('./icons/IconPhotoAi'),
  'photo-bitcoin': () => import('./icons/IconPhotoBitcoin'),
  'photo-bolt': () => import('./icons/IconPhotoBolt'),
  'photo-cancel': () => import('./icons/IconPhotoCancel'),
  'photo-check': () => import('./icons/IconPhotoCheck'),
  'photo-circle-minus': () => import('./icons/IconPhotoCircleMinus'),
  'photo-circle-plus': () => import('./icons/IconPhotoCirclePlus'),
  'photo-circle': () => import('./icons/IconPhotoCircle'),
  'photo-code': () => import('./icons/IconPhotoCode'),
  'photo-cog': () => import('./icons/IconPhotoCog'),
  'photo-dollar': () => import('./icons/IconPhotoDollar'),
  'photo-down': () => import('./icons/IconPhotoDown'),
  'photo-edit': () => import('./icons/IconPhotoEdit'),
  'photo-exclamation': () => import('./icons/IconPhotoExclamation'),
  'photo-heart': () => import('./icons/IconPhotoHeart'),
  'photo-hexagon': () => import('./icons/IconPhotoHexagon'),
  'photo-minus': () => import('./icons/IconPhotoMinus'),
  'photo-off': () => import('./icons/IconPhotoOff'),
  'photo-pause': () => import('./icons/IconPhotoPause'),
  'photo-pentagon': () => import('./icons/IconPhotoPentagon'),
  'photo-pin': () => import('./icons/IconPhotoPin'),
  'photo-plus': () => import('./icons/IconPhotoPlus'),
  'photo-question': () => import('./icons/IconPhotoQuestion'),
  'photo-scan': () => import('./icons/IconPhotoScan'),
  'photo-search': () => import('./icons/IconPhotoSearch'),
  'photo-sensor-2': () => import('./icons/IconPhotoSensor2'),
  'photo-sensor-3': () => import('./icons/IconPhotoSensor3'),
  'photo-sensor': () => import('./icons/IconPhotoSensor'),
  'photo-share': () => import('./icons/IconPhotoShare'),
  'photo-shield': () => import('./icons/IconPhotoShield'),
  'photo-spark': () => import('./icons/IconPhotoSpark'),
  'photo-square-rounded': () => import('./icons/IconPhotoSquareRounded'),
  'photo-star': () => import('./icons/IconPhotoStar'),
  'photo-up': () => import('./icons/IconPhotoUp'),
  'photo-video': () => import('./icons/IconPhotoVideo'),
  'photo-x': () => import('./icons/IconPhotoX'),
  'photo': () => import('./icons/IconPhoto'),
  'physotherapist': () => import('./icons/IconPhysotherapist'),
  'piano': () => import('./icons/IconPiano'),
  'pick': () => import('./icons/IconPick'),
  'picnic-table': () => import('./icons/IconPicnicTable'),
  'picture-in-picture-off': () => import('./icons/IconPictureInPictureOff'),
  'picture-in-picture-on': () => import('./icons/IconPictureInPictureOn'),
  'picture-in-picture-top': () => import('./icons/IconPictureInPictureTop'),
  'picture-in-picture': () => import('./icons/IconPictureInPicture'),
  'pig-money': () => import('./icons/IconPigMoney'),
  'pig-off': () => import('./icons/IconPigOff'),
  'pig': () => import('./icons/IconPig'),
  'pilcrow-left': () => import('./icons/IconPilcrowLeft'),
  'pilcrow-right': () => import('./icons/IconPilcrowRight'),
  'pilcrow': () => import('./icons/IconPilcrow'),
  'pill-off': () => import('./icons/IconPillOff'),
  'pill': () => import('./icons/IconPill'),
  'pills': () => import('./icons/IconPills'),
  'pin-end': () => import('./icons/IconPinEnd'),
  'pin-invoke': () => import('./icons/IconPinInvoke'),
  'pin': () => import('./icons/IconPin'),
  'ping-pong': () => import('./icons/IconPingPong'),
  'pinned-off': () => import('./icons/IconPinnedOff'),
  'pinned': () => import('./icons/IconPinned'),
  'pizza-off': () => import('./icons/IconPizzaOff'),
  'pizza': () => import('./icons/IconPizza'),
  'placeholder': () => import('./icons/IconPlaceholder'),
  'plane-arrival': () => import('./icons/IconPlaneArrival'),
  'plane-departure': () => import('./icons/IconPlaneDeparture'),
  'plane-inflight': () => import('./icons/IconPlaneInflight'),
  'plane-off': () => import('./icons/IconPlaneOff'),
  'plane-tilt': () => import('./icons/IconPlaneTilt'),
  'plane': () => import('./icons/IconPlane'),
  'planet-off': () => import('./icons/IconPlanetOff'),
  'planet': () => import('./icons/IconPlanet'),
  'plant-2-off': () => import('./icons/IconPlant2Off'),
  'plant-2': () => import('./icons/IconPlant2'),
  'plant-off': () => import('./icons/IconPlantOff'),
  'plant': () => import('./icons/IconPlant'),
  'play-basketball': () => import('./icons/IconPlayBasketball'),
  'play-card-1': () => import('./icons/IconPlayCard1'),
  'play-card-10': () => import('./icons/IconPlayCard10'),
  'play-card-2': () => import('./icons/IconPlayCard2'),
  'play-card-3': () => import('./icons/IconPlayCard3'),
  'play-card-4': () => import('./icons/IconPlayCard4'),
  'play-card-5': () => import('./icons/IconPlayCard5'),
  'play-card-6': () => import('./icons/IconPlayCard6'),
  'play-card-7': () => import('./icons/IconPlayCard7'),
  'play-card-8': () => import('./icons/IconPlayCard8'),
  'play-card-9': () => import('./icons/IconPlayCard9'),
  'play-card-a': () => import('./icons/IconPlayCardA'),
  'play-card-j': () => import('./icons/IconPlayCardJ'),
  'play-card-k': () => import('./icons/IconPlayCardK'),
  'play-card-off': () => import('./icons/IconPlayCardOff'),
  'play-card-q': () => import('./icons/IconPlayCardQ'),
  'play-card-star': () => import('./icons/IconPlayCardStar'),
  'play-card': () => import('./icons/IconPlayCard'),
  'play-football': () => import('./icons/IconPlayFootball'),
  'play-handball': () => import('./icons/IconPlayHandball'),
  'play-volleyball': () => import('./icons/IconPlayVolleyball'),
  'player-eject': () => import('./icons/IconPlayerEject'),
  'player-pause': () => import('./icons/IconPlayerPause'),
  'player-play': () => import('./icons/IconPlayerPlay'),
  'player-record': () => import('./icons/IconPlayerRecord'),
  'player-skip-back': () => import('./icons/IconPlayerSkipBack'),
  'player-skip-forward': () => import('./icons/IconPlayerSkipForward'),
  'player-stop': () => import('./icons/IconPlayerStop'),
  'player-track-next': () => import('./icons/IconPlayerTrackNext'),
  'player-track-prev': () => import('./icons/IconPlayerTrackPrev'),
  'playlist-add': () => import('./icons/IconPlaylistAdd'),
  'playlist-off': () => import('./icons/IconPlaylistOff'),
  'playlist-x': () => import('./icons/IconPlaylistX'),
  'playlist': () => import('./icons/IconPlaylist'),
  'playstation-circle': () => import('./icons/IconPlaystationCircle'),
  'playstation-square': () => import('./icons/IconPlaystationSquare'),
  'playstation-triangle': () => import('./icons/IconPlaystationTriangle'),
  'playstation-x': () => import('./icons/IconPlaystationX'),
  'plug-connected-x': () => import('./icons/IconPlugConnectedX'),
  'plug-connected': () => import('./icons/IconPlugConnected'),
  'plug-off': () => import('./icons/IconPlugOff'),
  'plug-x': () => import('./icons/IconPlugX'),
  'plug': () => import('./icons/IconPlug'),
  'plus-equal': () => import('./icons/IconPlusEqual'),
  'plus-minus': () => import('./icons/IconPlusMinus'),
  'plus': () => import('./icons/IconPlus'),
  'png': () => import('./icons/IconPng'),
  'podium-off': () => import('./icons/IconPodiumOff'),
  'podium': () => import('./icons/IconPodium'),
  'point-off': () => import('./icons/IconPointOff'),
  'point': () => import('./icons/IconPoint'),
  'pointer-bolt': () => import('./icons/IconPointerBolt'),
  'pointer-cancel': () => import('./icons/IconPointerCancel'),
  'pointer-check': () => import('./icons/IconPointerCheck'),
  'pointer-code': () => import('./icons/IconPointerCode'),
  'pointer-cog': () => import('./icons/IconPointerCog'),
  'pointer-dollar': () => import('./icons/IconPointerDollar'),
  'pointer-down': () => import('./icons/IconPointerDown'),
  'pointer-exclamation': () => import('./icons/IconPointerExclamation'),
  'pointer-heart': () => import('./icons/IconPointerHeart'),
  'pointer-minus': () => import('./icons/IconPointerMinus'),
  'pointer-off': () => import('./icons/IconPointerOff'),
  'pointer-pause': () => import('./icons/IconPointerPause'),
  'pointer-pin': () => import('./icons/IconPointerPin'),
  'pointer-plus': () => import('./icons/IconPointerPlus'),
  'pointer-question': () => import('./icons/IconPointerQuestion'),
  'pointer-search': () => import('./icons/IconPointerSearch'),
  'pointer-share': () => import('./icons/IconPointerShare'),
  'pointer-star': () => import('./icons/IconPointerStar'),
  'pointer-up': () => import('./icons/IconPointerUp'),
  'pointer-x': () => import('./icons/IconPointerX'),
  'pointer': () => import('./icons/IconPointer'),
  'pokeball-off': () => import('./icons/IconPokeballOff'),
  'pokeball': () => import('./icons/IconPokeball'),
  'poker-chip': () => import('./icons/IconPokerChip'),
  'polaroid': () => import('./icons/IconPolaroid'),
  'polygon-off': () => import('./icons/IconPolygonOff'),
  'polygon': () => import('./icons/IconPolygon'),
  'poo': () => import('./icons/IconPoo'),
  'pool-off': () => import('./icons/IconPoolOff'),
  'pool': () => import('./icons/IconPool'),
  'power': () => import('./icons/IconPower'),
  'pray': () => import('./icons/IconPray'),
  'premium-rights': () => import('./icons/IconPremiumRights'),
  'prescription': () => import('./icons/IconPrescription'),
  'presentation-analytics': () => import('./icons/IconPresentationAnalytics'),
  'presentation-off': () => import('./icons/IconPresentationOff'),
  'presentation': () => import('./icons/IconPresentation'),
  'printer-off': () => import('./icons/IconPrinterOff'),
  'printer': () => import('./icons/IconPrinter'),
  'prism-light': () => import('./icons/IconPrismLight'),
  'prism-off': () => import('./icons/IconPrismOff'),
  'prism-plus': () => import('./icons/IconPrismPlus'),
  'prism': () => import('./icons/IconPrism'),
  'prison': () => import('./icons/IconPrison'),
  'progress-alert': () => import('./icons/IconProgressAlert'),
  'progress-bolt': () => import('./icons/IconProgressBolt'),
  'progress-check': () => import('./icons/IconProgressCheck'),
  'progress-down': () => import('./icons/IconProgressDown'),
  'progress-help': () => import('./icons/IconProgressHelp'),
  'progress-x': () => import('./icons/IconProgressX'),
  'progress': () => import('./icons/IconProgress'),
  'prompt': () => import('./icons/IconPrompt'),
  'prong': () => import('./icons/IconProng'),
  'propeller-off': () => import('./icons/IconPropellerOff'),
  'propeller': () => import('./icons/IconPropeller'),
  'protocol': () => import('./icons/IconProtocol'),
  'pumpkin-scary': () => import('./icons/IconPumpkinScary'),
  'puzzle-2': () => import('./icons/IconPuzzle2'),
  'puzzle-off': () => import('./icons/IconPuzzleOff'),
  'puzzle': () => import('./icons/IconPuzzle'),
  'pyramid-off': () => import('./icons/IconPyramidOff'),
  'pyramid-plus': () => import('./icons/IconPyramidPlus'),
  'pyramid': () => import('./icons/IconPyramid'),
  'qrcode-off': () => import('./icons/IconQrcodeOff'),
  'qrcode': () => import('./icons/IconQrcode'),
  'question-mark': () => import('./icons/IconQuestionMark'),
  'quote-off': () => import('./icons/IconQuoteOff'),
  'quote': () => import('./icons/IconQuote'),
  'quotes': () => import('./icons/IconQuotes'),
  'radar-2': () => import('./icons/IconRadar2'),
  'radar-off': () => import('./icons/IconRadarOff'),
  'radar': () => import('./icons/IconRadar'),
  'radio-off': () => import('./icons/IconRadioOff'),
  'radio': () => import('./icons/IconRadio'),
  'radioactive-off': () => import('./icons/IconRadioactiveOff'),
  'radioactive': () => import('./icons/IconRadioactive'),
  'radius-bottom-left': () => import('./icons/IconRadiusBottomLeft'),
  'radius-bottom-right': () => import('./icons/IconRadiusBottomRight'),
  'radius-top-left': () => import('./icons/IconRadiusTopLeft'),
  'radius-top-right': () => import('./icons/IconRadiusTopRight'),
  'rainbow-off': () => import('./icons/IconRainbowOff'),
  'rainbow': () => import('./icons/IconRainbow'),
  'rating-12-plus': () => import('./icons/IconRating12Plus'),
  'rating-14-plus': () => import('./icons/IconRating14Plus'),
  'rating-16-plus': () => import('./icons/IconRating16Plus'),
  'rating-18-plus': () => import('./icons/IconRating18Plus'),
  'rating-21-plus': () => import('./icons/IconRating21Plus'),
  'razor-electric': () => import('./icons/IconRazorElectric'),
  'razor': () => import('./icons/IconRazor'),
  'receipt-2': () => import('./icons/IconReceipt2'),
  'receipt-bitcoin': () => import('./icons/IconReceiptBitcoin'),
  'receipt-dollar': () => import('./icons/IconReceiptDollar'),
  'receipt-euro': () => import('./icons/IconReceiptEuro'),
  'receipt-off': () => import('./icons/IconReceiptOff'),
  'receipt-pound': () => import('./icons/IconReceiptPound'),
  'receipt-refund': () => import('./icons/IconReceiptRefund'),
  'receipt-rupee': () => import('./icons/IconReceiptRupee'),
  'receipt-tax': () => import('./icons/IconReceiptTax'),
  'receipt-yen': () => import('./icons/IconReceiptYen'),
  'receipt-yuan': () => import('./icons/IconReceiptYuan'),
  'receipt': () => import('./icons/IconReceipt'),
  'recharging': () => import('./icons/IconRecharging'),
  'record-mail-off': () => import('./icons/IconRecordMailOff'),
  'record-mail': () => import('./icons/IconRecordMail'),
  'rectangle-rounded-bottom': () => import('./icons/IconRectangleRoundedBottom'),
  'rectangle-rounded-top': () => import('./icons/IconRectangleRoundedTop'),
  'rectangle-vertical': () => import('./icons/IconRectangleVertical'),
  'rectangle': () => import('./icons/IconRectangle'),
  'rectangular-prism-off': () => import('./icons/IconRectangularPrismOff'),
  'rectangular-prism-plus': () => import('./icons/IconRectangularPrismPlus'),
  'rectangular-prism': () => import('./icons/IconRectangularPrism'),
  'recycle-off': () => import('./icons/IconRecycleOff'),
  'recycle': () => import('./icons/IconRecycle'),
  'refresh-alert': () => import('./icons/IconRefreshAlert'),
  'refresh-dot': () => import('./icons/IconRefreshDot'),
  'refresh-off': () => import('./icons/IconRefreshOff'),
  'refresh': () => import('./icons/IconRefresh'),
  'regex-off': () => import('./icons/IconRegexOff'),
  'regex': () => import('./icons/IconRegex'),
  'registered': () => import('./icons/IconRegistered'),
  'relation-many-to-many': () => import('./icons/IconRelationManyToMany'),
  'relation-one-to-many': () => import('./icons/IconRelationOneToMany'),
  'relation-one-to-one': () => import('./icons/IconRelationOneToOne'),
  'reload': () => import('./icons/IconReload'),
  'reorder': () => import('./icons/IconReorder'),
  'repeat-off': () => import('./icons/IconRepeatOff'),
  'repeat-once': () => import('./icons/IconRepeatOnce'),
  'repeat': () => import('./icons/IconRepeat'),
  'replace-off': () => import('./icons/IconReplaceOff'),
  'replace': () => import('./icons/IconReplace'),
  'report-analytics': () => import('./icons/IconReportAnalytics'),
  'report-medical': () => import('./icons/IconReportMedical'),
  'report-money': () => import('./icons/IconReportMoney'),
  'report-off': () => import('./icons/IconReportOff'),
  'report-search': () => import('./icons/IconReportSearch'),
  'report': () => import('./icons/IconReport'),
  'reserved-line': () => import('./icons/IconReservedLine'),
  'resize': () => import('./icons/IconResize'),
  'restore': () => import('./icons/IconRestore'),
  'rewind-backward-10': () => import('./icons/IconRewindBackward10'),
  'rewind-backward-15': () => import('./icons/IconRewindBackward15'),
  'rewind-backward-20': () => import('./icons/IconRewindBackward20'),
  'rewind-backward-30': () => import('./icons/IconRewindBackward30'),
  'rewind-backward-40': () => import('./icons/IconRewindBackward40'),
  'rewind-backward-5': () => import('./icons/IconRewindBackward5'),
  'rewind-backward-50': () => import('./icons/IconRewindBackward50'),
  'rewind-backward-60': () => import('./icons/IconRewindBackward60'),
  'rewind-forward-10': () => import('./icons/IconRewindForward10'),
  'rewind-forward-15': () => import('./icons/IconRewindForward15'),
  'rewind-forward-20': () => import('./icons/IconRewindForward20'),
  'rewind-forward-30': () => import('./icons/IconRewindForward30'),
  'rewind-forward-40': () => import('./icons/IconRewindForward40'),
  'rewind-forward-5': () => import('./icons/IconRewindForward5'),
  'rewind-forward-50': () => import('./icons/IconRewindForward50'),
  'rewind-forward-60': () => import('./icons/IconRewindForward60'),
  'ribbon-health': () => import('./icons/IconRibbonHealth'),
  'rings': () => import('./icons/IconRings'),
  'ripple-off': () => import('./icons/IconRippleOff'),
  'ripple': () => import('./icons/IconRipple'),
  'road-off': () => import('./icons/IconRoadOff'),
  'road-sign': () => import('./icons/IconRoadSign'),
  'road': () => import('./icons/IconRoad'),
  'robot-face': () => import('./icons/IconRobotFace'),
  'robot-off': () => import('./icons/IconRobotOff'),
  'robot': () => import('./icons/IconRobot'),
  'rocket-off': () => import('./icons/IconRocketOff'),
  'rocket': () => import('./icons/IconRocket'),
  'roller-skating': () => import('./icons/IconRollerSkating'),
  'rollercoaster-off': () => import('./icons/IconRollercoasterOff'),
  'rollercoaster': () => import('./icons/IconRollercoaster'),
  'rosette-discount-check-off': () => import('./icons/IconRosetteDiscountCheckOff'),
  'rosette-discount-check': () => import('./icons/IconRosetteDiscountCheck'),
  'rosette-discount-off': () => import('./icons/IconRosetteDiscountOff'),
  'rosette-discount': () => import('./icons/IconRosetteDiscount'),
  'rosette-number-0': () => import('./icons/IconRosetteNumber0'),
  'rosette-number-1': () => import('./icons/IconRosetteNumber1'),
  'rosette-number-2': () => import('./icons/IconRosetteNumber2'),
  'rosette-number-3': () => import('./icons/IconRosetteNumber3'),
  'rosette-number-4': () => import('./icons/IconRosetteNumber4'),
  'rosette-number-5': () => import('./icons/IconRosetteNumber5'),
  'rosette-number-6': () => import('./icons/IconRosetteNumber6'),
  'rosette-number-7': () => import('./icons/IconRosetteNumber7'),
  'rosette-number-8': () => import('./icons/IconRosetteNumber8'),
  'rosette-number-9': () => import('./icons/IconRosetteNumber9'),
  'rosette': () => import('./icons/IconRosette'),
  'rotate-2': () => import('./icons/IconRotate2'),
  'rotate-360': () => import('./icons/IconRotate360'),
  'rotate-3d': () => import('./icons/IconRotate3d'),
  'rotate-clockwise-2': () => import('./icons/IconRotateClockwise2'),
  'rotate-clockwise': () => import('./icons/IconRotateClockwise'),
  'rotate-dot': () => import('./icons/IconRotateDot'),
  'rotate-rectangle': () => import('./icons/IconRotateRectangle'),
  'rotate': () => import('./icons/IconRotate'),
  'route-2': () => import('./icons/IconRoute2'),
  'route-alt-left': () => import('./icons/IconRouteAltLeft'),
  'route-alt-right': () => import('./icons/IconRouteAltRight'),
  'route-off': () => import('./icons/IconRouteOff'),
  'route-scan': () => import('./icons/IconRouteScan'),
  'route-square-2': () => import('./icons/IconRouteSquare2'),
  'route-square': () => import('./icons/IconRouteSquare'),
  'route-x-2': () => import('./icons/IconRouteX2'),
  'route-x': () => import('./icons/IconRouteX'),
  'route': () => import('./icons/IconRoute'),
  'router-off': () => import('./icons/IconRouterOff'),
  'router': () => import('./icons/IconRouter'),
  'row-insert-bottom': () => import('./icons/IconRowInsertBottom'),
  'row-insert-top': () => import('./icons/IconRowInsertTop'),
  'row-remove': () => import('./icons/IconRowRemove'),
  'rss': () => import('./icons/IconRss'),
  'rubber-stamp-off': () => import('./icons/IconRubberStampOff'),
  'rubber-stamp': () => import('./icons/IconRubberStamp'),
  'ruler-2-off': () => import('./icons/IconRuler2Off'),
  'ruler-2': () => import('./icons/IconRuler2'),
  'ruler-3': () => import('./icons/IconRuler3'),
  'ruler-measure-2': () => import('./icons/IconRulerMeasure2'),
  'ruler-measure': () => import('./icons/IconRulerMeasure'),
  'ruler-off': () => import('./icons/IconRulerOff'),
  'ruler': () => import('./icons/IconRuler'),
  'run': () => import('./icons/IconRun'),
  'rv-truck': () => import('./icons/IconRvTruck'),
  's-turn-down': () => import('./icons/IconSTurnDown'),
  's-turn-left': () => import('./icons/IconSTurnLeft'),
  's-turn-right': () => import('./icons/IconSTurnRight'),
  's-turn-up': () => import('./icons/IconSTurnUp'),
  'sailboat-2': () => import('./icons/IconSailboat2'),
  'sailboat-off': () => import('./icons/IconSailboatOff'),
  'sailboat': () => import('./icons/IconSailboat'),
  'salad': () => import('./icons/IconSalad'),
  'salt': () => import('./icons/IconSalt'),
  'sandbox': () => import('./icons/IconSandbox'),
  'satellite-off': () => import('./icons/IconSatelliteOff'),
  'satellite': () => import('./icons/IconSatellite'),
  'sausage': () => import('./icons/IconSausage'),
  'scale-off': () => import('./icons/IconScaleOff'),
  'scale-outline-off': () => import('./icons/IconScaleOutlineOff'),
  'scale-outline': () => import('./icons/IconScaleOutline'),
  'scale': () => import('./icons/IconScale'),
  'scan-eye': () => import('./icons/IconScanEye'),
  'scan-position': () => import('./icons/IconScanPosition'),
  'scan': () => import('./icons/IconScan'),
  'schema-off': () => import('./icons/IconSchemaOff'),
  'schema': () => import('./icons/IconSchema'),
  'school-bell': () => import('./icons/IconSchoolBell'),
  'school-off': () => import('./icons/IconSchoolOff'),
  'school': () => import('./icons/IconSchool'),
  'scissors-off': () => import('./icons/IconScissorsOff'),
  'scissors': () => import('./icons/IconScissors'),
  'scooter-electric': () => import('./icons/IconScooterElectric'),
  'scooter': () => import('./icons/IconScooter'),
  'scoreboard': () => import('./icons/IconScoreboard'),
  'screen-share-off': () => import('./icons/IconScreenShareOff'),
  'screen-share': () => import('./icons/IconScreenShare'),
  'screenshot': () => import('./icons/IconScreenshot'),
  'scribble-off': () => import('./icons/IconScribbleOff'),
  'scribble': () => import('./icons/IconScribble'),
  'script-minus': () => import('./icons/IconScriptMinus'),
  'script-plus': () => import('./icons/IconScriptPlus'),
  'script-x': () => import('./icons/IconScriptX'),
  'script': () => import('./icons/IconScript'),
  'scuba-diving-tank': () => import('./icons/IconScubaDivingTank'),
  'scuba-diving': () => import('./icons/IconScubaDiving'),
  'scuba-mask-off': () => import('./icons/IconScubaMaskOff'),
  'scuba-mask': () => import('./icons/IconScubaMask'),
  'sdk': () => import('./icons/IconSdk'),
  'search-off': () => import('./icons/IconSearchOff'),
  'search': () => import('./icons/IconSearch'),
  'section-sign': () => import('./icons/IconSectionSign'),
  'section': () => import('./icons/IconSection'),
  'seeding-off': () => import('./icons/IconSeedingOff'),
  'seeding': () => import('./icons/IconSeeding'),
  'select-all': () => import('./icons/IconSelectAll'),
  'select': () => import('./icons/IconSelect'),
  'selector': () => import('./icons/IconSelector'),
  'send-2': () => import('./icons/IconSend2'),
  'send-off': () => import('./icons/IconSendOff'),
  'send': () => import('./icons/IconSend'),
  'seo': () => import('./icons/IconSeo'),
  'separator-horizontal': () => import('./icons/IconSeparatorHorizontal'),
  'separator-vertical': () => import('./icons/IconSeparatorVertical'),
  'separator': () => import('./icons/IconSeparator'),
  'server-2': () => import('./icons/IconServer2'),
  'server-bolt': () => import('./icons/IconServerBolt'),
  'server-cog': () => import('./icons/IconServerCog'),
  'server-off': () => import('./icons/IconServerOff'),
  'server-spark': () => import('./icons/IconServerSpark'),
  'server': () => import('./icons/IconServer'),
  'servicemark': () => import('./icons/IconServicemark'),
  'settings-2': () => import('./icons/IconSettings2'),
  'settings-automation': () => import('./icons/IconSettingsAutomation'),
  'settings-bolt': () => import('./icons/IconSettingsBolt'),
  'settings-cancel': () => import('./icons/IconSettingsCancel'),
  'settings-check': () => import('./icons/IconSettingsCheck'),
  'settings-code': () => import('./icons/IconSettingsCode'),
  'settings-cog': () => import('./icons/IconSettingsCog'),
  'settings-dollar': () => import('./icons/IconSettingsDollar'),
  'settings-down': () => import('./icons/IconSettingsDown'),
  'settings-exclamation': () => import('./icons/IconSettingsExclamation'),
  'settings-heart': () => import('./icons/IconSettingsHeart'),
  'settings-minus': () => import('./icons/IconSettingsMinus'),
  'settings-off': () => import('./icons/IconSettingsOff'),
  'settings-pause': () => import('./icons/IconSettingsPause'),
  'settings-pin': () => import('./icons/IconSettingsPin'),
  'settings-plus': () => import('./icons/IconSettingsPlus'),
  'settings-question': () => import('./icons/IconSettingsQuestion'),
  'settings-search': () => import('./icons/IconSettingsSearch'),
  'settings-share': () => import('./icons/IconSettingsShare'),
  'settings-spark': () => import('./icons/IconSettingsSpark'),
  'settings-star': () => import('./icons/IconSettingsStar'),
  'settings-up': () => import('./icons/IconSettingsUp'),
  'settings-x': () => import('./icons/IconSettingsX'),
  'settings': () => import('./icons/IconSettings'),
  'shadow-off': () => import('./icons/IconShadowOff'),
  'shadow': () => import('./icons/IconShadow'),
  'shape-2': () => import('./icons/IconShape2'),
  'shape-3': () => import('./icons/IconShape3'),
  'shape-off': () => import('./icons/IconShapeOff'),
  'shape': () => import('./icons/IconShape'),
  'share-2': () => import('./icons/IconShare2'),
  'share-3': () => import('./icons/IconShare3'),
  'share-off': () => import('./icons/IconShareOff'),
  'share': () => import('./icons/IconShare'),
  'shareplay': () => import('./icons/IconShareplay'),
  'shield-bolt': () => import('./icons/IconShieldBolt'),
  'shield-cancel': () => import('./icons/IconShieldCancel'),
  'shield-check': () => import('./icons/IconShieldCheck'),
  'shield-checkered': () => import('./icons/IconShieldCheckered'),
  'shield-chevron': () => import('./icons/IconShieldChevron'),
  'shield-code': () => import('./icons/IconShieldCode'),
  'shield-cog': () => import('./icons/IconShieldCog'),
  'shield-dollar': () => import('./icons/IconShieldDollar'),
  'shield-down': () => import('./icons/IconShieldDown'),
  'shield-exclamation': () => import('./icons/IconShieldExclamation'),
  'shield-half': () => import('./icons/IconShieldHalf'),
  'shield-heart': () => import('./icons/IconShieldHeart'),
  'shield-lock': () => import('./icons/IconShieldLock'),
  'shield-minus': () => import('./icons/IconShieldMinus'),
  'shield-off': () => import('./icons/IconShieldOff'),
  'shield-pause': () => import('./icons/IconShieldPause'),
  'shield-pin': () => import('./icons/IconShieldPin'),
  'shield-plus': () => import('./icons/IconShieldPlus'),
  'shield-question': () => import('./icons/IconShieldQuestion'),
  'shield-search': () => import('./icons/IconShieldSearch'),
  'shield-share': () => import('./icons/IconShieldShare'),
  'shield-star': () => import('./icons/IconShieldStar'),
  'shield-up': () => import('./icons/IconShieldUp'),
  'shield-x': () => import('./icons/IconShieldX'),
  'shield': () => import('./icons/IconShield'),
  'ship-off': () => import('./icons/IconShipOff'),
  'ship': () => import('./icons/IconShip'),
  'shirt-off': () => import('./icons/IconShirtOff'),
  'shirt-sport': () => import('./icons/IconShirtSport'),
  'shirt': () => import('./icons/IconShirt'),
  'shoe-off': () => import('./icons/IconShoeOff'),
  'shoe': () => import('./icons/IconShoe'),
  'shopping-bag-check': () => import('./icons/IconShoppingBagCheck'),
  'shopping-bag-discount': () => import('./icons/IconShoppingBagDiscount'),
  'shopping-bag-edit': () => import('./icons/IconShoppingBagEdit'),
  'shopping-bag-exclamation': () => import('./icons/IconShoppingBagExclamation'),
  'shopping-bag-heart': () => import('./icons/IconShoppingBagHeart'),
  'shopping-bag-minus': () => import('./icons/IconShoppingBagMinus'),
  'shopping-bag-plus': () => import('./icons/IconShoppingBagPlus'),
  'shopping-bag-search': () => import('./icons/IconShoppingBagSearch'),
  'shopping-bag-x': () => import('./icons/IconShoppingBagX'),
  'shopping-bag': () => import('./icons/IconShoppingBag'),
  'shopping-cart-bolt': () => import('./icons/IconShoppingCartBolt'),
  'shopping-cart-cancel': () => import('./icons/IconShoppingCartCancel'),
  'shopping-cart-check': () => import('./icons/IconShoppingCartCheck'),
  'shopping-cart-code': () => import('./icons/IconShoppingCartCode'),
  'shopping-cart-cog': () => import('./icons/IconShoppingCartCog'),
  'shopping-cart-copy': () => import('./icons/IconShoppingCartCopy'),
  'shopping-cart-discount': () => import('./icons/IconShoppingCartDiscount'),
  'shopping-cart-dollar': () => import('./icons/IconShoppingCartDollar'),
  'shopping-cart-down': () => import('./icons/IconShoppingCartDown'),
  'shopping-cart-exclamation': () => import('./icons/IconShoppingCartExclamation'),
  'shopping-cart-heart': () => import('./icons/IconShoppingCartHeart'),
  'shopping-cart-minus': () => import('./icons/IconShoppingCartMinus'),
  'shopping-cart-off': () => import('./icons/IconShoppingCartOff'),
  'shopping-cart-pause': () => import('./icons/IconShoppingCartPause'),
  'shopping-cart-pin': () => import('./icons/IconShoppingCartPin'),
  'shopping-cart-plus': () => import('./icons/IconShoppingCartPlus'),
  'shopping-cart-question': () => import('./icons/IconShoppingCartQuestion'),
  'shopping-cart-search': () => import('./icons/IconShoppingCartSearch'),
  'shopping-cart-share': () => import('./icons/IconShoppingCartShare'),
  'shopping-cart-star': () => import('./icons/IconShoppingCartStar'),
  'shopping-cart-up': () => import('./icons/IconShoppingCartUp'),
  'shopping-cart-x': () => import('./icons/IconShoppingCartX'),
  'shopping-cart': () => import('./icons/IconShoppingCart'),
  'shovel-pitchforks': () => import('./icons/IconShovelPitchforks'),
  'shovel': () => import('./icons/IconShovel'),
  'shredder': () => import('./icons/IconShredder'),
  'sign-left': () => import('./icons/IconSignLeft'),
  'sign-right': () => import('./icons/IconSignRight'),
  'signal-2g': () => import('./icons/IconSignal2g'),
  'signal-3g': () => import('./icons/IconSignal3g'),
  'signal-4g-plus': () => import('./icons/IconSignal4gPlus'),
  'signal-4g': () => import('./icons/IconSignal4g'),
  'signal-5g': () => import('./icons/IconSignal5g'),
  'signal-6g': () => import('./icons/IconSignal6g'),
  'signal-e': () => import('./icons/IconSignalE'),
  'signal-g': () => import('./icons/IconSignalG'),
  'signal-h-plus': () => import('./icons/IconSignalHPlus'),
  'signal-h': () => import('./icons/IconSignalH'),
  'signal-lte': () => import('./icons/IconSignalLte'),
  'signature-off': () => import('./icons/IconSignatureOff'),
  'signature': () => import('./icons/IconSignature'),
  'sitemap-off': () => import('./icons/IconSitemapOff'),
  'sitemap': () => import('./icons/IconSitemap'),
  'skateboard-off': () => import('./icons/IconSkateboardOff'),
  'skateboard': () => import('./icons/IconSkateboard'),
  'skateboarding': () => import('./icons/IconSkateboarding'),
  'skew-x': () => import('./icons/IconSkewX'),
  'skew-y': () => import('./icons/IconSkewY'),
  'ski-jumping': () => import('./icons/IconSkiJumping'),
  'skull': () => import('./icons/IconSkull'),
  'slash': () => import('./icons/IconSlash'),
  'slashes': () => import('./icons/IconSlashes'),
  'sleigh': () => import('./icons/IconSleigh'),
  'slice': () => import('./icons/IconSlice'),
  'slideshow': () => import('./icons/IconSlideshow'),
  'smart-home-off': () => import('./icons/IconSmartHomeOff'),
  'smart-home': () => import('./icons/IconSmartHome'),
  'smoking-no': () => import('./icons/IconSmokingNo'),
  'smoking': () => import('./icons/IconSmoking'),
  'snowboarding': () => import('./icons/IconSnowboarding'),
  'snowflake-off': () => import('./icons/IconSnowflakeOff'),
  'snowflake': () => import('./icons/IconSnowflake'),
  'snowman': () => import('./icons/IconSnowman'),
  'soccer-field': () => import('./icons/IconSoccerField'),
  'social-off': () => import('./icons/IconSocialOff'),
  'social': () => import('./icons/IconSocial'),
  'sock': () => import('./icons/IconSock'),
  'sofa-off': () => import('./icons/IconSofaOff'),
  'sofa': () => import('./icons/IconSofa'),
  'solar-electricity': () => import('./icons/IconSolarElectricity'),
  'solar-panel-2': () => import('./icons/IconSolarPanel2'),
  'solar-panel': () => import('./icons/IconSolarPanel'),
  'sort-0-9': () => import('./icons/IconSort09'),
  'sort-9-0': () => import('./icons/IconSort90'),
  'sort-a-z': () => import('./icons/IconSortAZ'),
  'sort-ascending-2': () => import('./icons/IconSortAscending2'),
  'sort-ascending-letters': () => import('./icons/IconSortAscendingLetters'),
  'sort-ascending-numbers': () => import('./icons/IconSortAscendingNumbers'),
  'sort-ascending-shapes': () => import('./icons/IconSortAscendingShapes'),
  'sort-ascending-small-big': () => import('./icons/IconSortAscendingSmallBig'),
  'sort-ascending': () => import('./icons/IconSortAscending'),
  'sort-descending-2': () => import('./icons/IconSortDescending2'),
  'sort-descending-letters': () => import('./icons/IconSortDescendingLetters'),
  'sort-descending-numbers': () => import('./icons/IconSortDescendingNumbers'),
  'sort-descending-shapes': () => import('./icons/IconSortDescendingShapes'),
  'sort-descending-small-big': () => import('./icons/IconSortDescendingSmallBig'),
  'sort-descending': () => import('./icons/IconSortDescending'),
  'sort-z-a': () => import('./icons/IconSortZA'),
  'sos': () => import('./icons/IconSos'),
  'soup-off': () => import('./icons/IconSoupOff'),
  'soup': () => import('./icons/IconSoup'),
  'source-code': () => import('./icons/IconSourceCode'),
  'space-off': () => import('./icons/IconSpaceOff'),
  'space': () => import('./icons/IconSpace'),
  'spaces': () => import('./icons/IconSpaces'),
  'spacing-horizontal': () => import('./icons/IconSpacingHorizontal'),
  'spacing-vertical': () => import('./icons/IconSpacingVertical'),
  'spade': () => import('./icons/IconSpade'),
  'sparkles': () => import('./icons/IconSparkles'),
  'speakerphone': () => import('./icons/IconSpeakerphone'),
  'speedboat': () => import('./icons/IconSpeedboat'),
  'sphere-off': () => import('./icons/IconSphereOff'),
  'sphere-plus': () => import('./icons/IconSpherePlus'),
  'sphere': () => import('./icons/IconSphere'),
  'spider': () => import('./icons/IconSpider'),
  'spiral-off': () => import('./icons/IconSpiralOff'),
  'spiral': () => import('./icons/IconSpiral'),
  'sport-billard': () => import('./icons/IconSportBillard'),
  'spray': () => import('./icons/IconSpray'),
  'spy-off': () => import('./icons/IconSpyOff'),
  'spy': () => import('./icons/IconSpy'),
  'sql': () => import('./icons/IconSql'),
  'square-arrow-down': () => import('./icons/IconSquareArrowDown'),
  'square-arrow-left': () => import('./icons/IconSquareArrowLeft'),
  'square-arrow-right': () => import('./icons/IconSquareArrowRight'),
  'square-arrow-up': () => import('./icons/IconSquareArrowUp'),
  'square-asterisk': () => import('./icons/IconSquareAsterisk'),
  'square-check': () => import('./icons/IconSquareCheck'),
  'square-chevron-down': () => import('./icons/IconSquareChevronDown'),
  'square-chevron-left': () => import('./icons/IconSquareChevronLeft'),
  'square-chevron-right': () => import('./icons/IconSquareChevronRight'),
  'square-chevron-up': () => import('./icons/IconSquareChevronUp'),
  'square-chevrons-down': () => import('./icons/IconSquareChevronsDown'),
  'square-chevrons-left': () => import('./icons/IconSquareChevronsLeft'),
  'square-chevrons-right': () => import('./icons/IconSquareChevronsRight'),
  'square-chevrons-up': () => import('./icons/IconSquareChevronsUp'),
  'square-dot': () => import('./icons/IconSquareDot'),
  'square-f0': () => import('./icons/IconSquareF0'),
  'square-f1': () => import('./icons/IconSquareF1'),
  'square-f2': () => import('./icons/IconSquareF2'),
  'square-f3': () => import('./icons/IconSquareF3'),
  'square-f4': () => import('./icons/IconSquareF4'),
  'square-f5': () => import('./icons/IconSquareF5'),
  'square-f6': () => import('./icons/IconSquareF6'),
  'square-f7': () => import('./icons/IconSquareF7'),
  'square-f8': () => import('./icons/IconSquareF8'),
  'square-f9': () => import('./icons/IconSquareF9'),
  'square-forbid-2': () => import('./icons/IconSquareForbid2'),
  'square-forbid': () => import('./icons/IconSquareForbid'),
  'square-half': () => import('./icons/IconSquareHalf'),
  'square-key': () => import('./icons/IconSquareKey'),
  'square-letter-a': () => import('./icons/IconSquareLetterA'),
  'square-letter-b': () => import('./icons/IconSquareLetterB'),
  'square-letter-c': () => import('./icons/IconSquareLetterC'),
  'square-letter-d': () => import('./icons/IconSquareLetterD'),
  'square-letter-e': () => import('./icons/IconSquareLetterE'),
  'square-letter-f': () => import('./icons/IconSquareLetterF'),
  'square-letter-g': () => import('./icons/IconSquareLetterG'),
  'square-letter-h': () => import('./icons/IconSquareLetterH'),
  'square-letter-i': () => import('./icons/IconSquareLetterI'),
  'square-letter-j': () => import('./icons/IconSquareLetterJ'),
  'square-letter-k': () => import('./icons/IconSquareLetterK'),
  'square-letter-l': () => import('./icons/IconSquareLetterL'),
  'square-letter-m': () => import('./icons/IconSquareLetterM'),
  'square-letter-n': () => import('./icons/IconSquareLetterN'),
  'square-letter-o': () => import('./icons/IconSquareLetterO'),
  'square-letter-p': () => import('./icons/IconSquareLetterP'),
  'square-letter-q': () => import('./icons/IconSquareLetterQ'),
  'square-letter-r': () => import('./icons/IconSquareLetterR'),
  'square-letter-s': () => import('./icons/IconSquareLetterS'),
  'square-letter-t': () => import('./icons/IconSquareLetterT'),
  'square-letter-u': () => import('./icons/IconSquareLetterU'),
  'square-letter-v': () => import('./icons/IconSquareLetterV'),
  'square-letter-w': () => import('./icons/IconSquareLetterW'),
  'square-letter-x': () => import('./icons/IconSquareLetterX'),
  'square-letter-y': () => import('./icons/IconSquareLetterY'),
  'square-letter-z': () => import('./icons/IconSquareLetterZ'),
  'square-minus': () => import('./icons/IconSquareMinus'),
  'square-number-0': () => import('./icons/IconSquareNumber0'),
  'square-number-1': () => import('./icons/IconSquareNumber1'),
  'square-number-2': () => import('./icons/IconSquareNumber2'),
  'square-number-3': () => import('./icons/IconSquareNumber3'),
  'square-number-4': () => import('./icons/IconSquareNumber4'),
  'square-number-5': () => import('./icons/IconSquareNumber5'),
  'square-number-6': () => import('./icons/IconSquareNumber6'),
  'square-number-7': () => import('./icons/IconSquareNumber7'),
  'square-number-8': () => import('./icons/IconSquareNumber8'),
  'square-number-9': () => import('./icons/IconSquareNumber9'),
  'square-off': () => import('./icons/IconSquareOff'),
  'square-percentage': () => import('./icons/IconSquarePercentage'),
  'square-plus-2': () => import('./icons/IconSquarePlus2'),
  'square-plus': () => import('./icons/IconSquarePlus'),
  'square-root-2': () => import('./icons/IconSquareRoot2'),
  'square-root': () => import('./icons/IconSquareRoot'),
  'square-rotated-forbid-2': () => import('./icons/IconSquareRotatedForbid2'),
  'square-rotated-forbid': () => import('./icons/IconSquareRotatedForbid'),
  'square-rotated-off': () => import('./icons/IconSquareRotatedOff'),
  'square-rotated': () => import('./icons/IconSquareRotated'),
  'square-rounded-arrow-down': () => import('./icons/IconSquareRoundedArrowDown'),
  'square-rounded-arrow-left': () => import('./icons/IconSquareRoundedArrowLeft'),
  'square-rounded-arrow-right': () => import('./icons/IconSquareRoundedArrowRight'),
  'square-rounded-arrow-up': () => import('./icons/IconSquareRoundedArrowUp'),
  'square-rounded-check': () => import('./icons/IconSquareRoundedCheck'),
  'square-rounded-chevron-down': () => import('./icons/IconSquareRoundedChevronDown'),
  'square-rounded-chevron-left': () => import('./icons/IconSquareRoundedChevronLeft'),
  'square-rounded-chevron-right': () => import('./icons/IconSquareRoundedChevronRight'),
  'square-rounded-chevron-up': () => import('./icons/IconSquareRoundedChevronUp'),
  'square-rounded-chevrons-down': () => import('./icons/IconSquareRoundedChevronsDown'),
  'square-rounded-chevrons-left': () => import('./icons/IconSquareRoundedChevronsLeft'),
  'square-rounded-chevrons-right': () => import('./icons/IconSquareRoundedChevronsRight'),
  'square-rounded-chevrons-up': () => import('./icons/IconSquareRoundedChevronsUp'),
  'square-rounded-letter-a': () => import('./icons/IconSquareRoundedLetterA'),
  'square-rounded-letter-b': () => import('./icons/IconSquareRoundedLetterB'),
  'square-rounded-letter-c': () => import('./icons/IconSquareRoundedLetterC'),
  'square-rounded-letter-d': () => import('./icons/IconSquareRoundedLetterD'),
  'square-rounded-letter-e': () => import('./icons/IconSquareRoundedLetterE'),
  'square-rounded-letter-f': () => import('./icons/IconSquareRoundedLetterF'),
  'square-rounded-letter-g': () => import('./icons/IconSquareRoundedLetterG'),
  'square-rounded-letter-h': () => import('./icons/IconSquareRoundedLetterH'),
  'square-rounded-letter-i': () => import('./icons/IconSquareRoundedLetterI'),
  'square-rounded-letter-j': () => import('./icons/IconSquareRoundedLetterJ'),
  'square-rounded-letter-k': () => import('./icons/IconSquareRoundedLetterK'),
  'square-rounded-letter-l': () => import('./icons/IconSquareRoundedLetterL'),
  'square-rounded-letter-m': () => import('./icons/IconSquareRoundedLetterM'),
  'square-rounded-letter-n': () => import('./icons/IconSquareRoundedLetterN'),
  'square-rounded-letter-o': () => import('./icons/IconSquareRoundedLetterO'),
  'square-rounded-letter-p': () => import('./icons/IconSquareRoundedLetterP'),
  'square-rounded-letter-q': () => import('./icons/IconSquareRoundedLetterQ'),
  'square-rounded-letter-r': () => import('./icons/IconSquareRoundedLetterR'),
  'square-rounded-letter-s': () => import('./icons/IconSquareRoundedLetterS'),
  'square-rounded-letter-t': () => import('./icons/IconSquareRoundedLetterT'),
  'square-rounded-letter-u': () => import('./icons/IconSquareRoundedLetterU'),
  'square-rounded-letter-v': () => import('./icons/IconSquareRoundedLetterV'),
  'square-rounded-letter-w': () => import('./icons/IconSquareRoundedLetterW'),
  'square-rounded-letter-x': () => import('./icons/IconSquareRoundedLetterX'),
  'square-rounded-letter-y': () => import('./icons/IconSquareRoundedLetterY'),
  'square-rounded-letter-z': () => import('./icons/IconSquareRoundedLetterZ'),
  'square-rounded-minus-2': () => import('./icons/IconSquareRoundedMinus2'),
  'square-rounded-minus': () => import('./icons/IconSquareRoundedMinus'),
  'square-rounded-number-0': () => import('./icons/IconSquareRoundedNumber0'),
  'square-rounded-number-1': () => import('./icons/IconSquareRoundedNumber1'),
  'square-rounded-number-2': () => import('./icons/IconSquareRoundedNumber2'),
  'square-rounded-number-3': () => import('./icons/IconSquareRoundedNumber3'),
  'square-rounded-number-4': () => import('./icons/IconSquareRoundedNumber4'),
  'square-rounded-number-5': () => import('./icons/IconSquareRoundedNumber5'),
  'square-rounded-number-6': () => import('./icons/IconSquareRoundedNumber6'),
  'square-rounded-number-7': () => import('./icons/IconSquareRoundedNumber7'),
  'square-rounded-number-8': () => import('./icons/IconSquareRoundedNumber8'),
  'square-rounded-number-9': () => import('./icons/IconSquareRoundedNumber9'),
  'square-rounded-percentage': () => import('./icons/IconSquareRoundedPercentage'),
  'square-rounded-plus-2': () => import('./icons/IconSquareRoundedPlus2'),
  'square-rounded-plus': () => import('./icons/IconSquareRoundedPlus'),
  'square-rounded-x': () => import('./icons/IconSquareRoundedX'),
  'square-rounded': () => import('./icons/IconSquareRounded'),
  'square-toggle-horizontal': () => import('./icons/IconSquareToggleHorizontal'),
  'square-toggle': () => import('./icons/IconSquareToggle'),
  'square-x': () => import('./icons/IconSquareX'),
  'square': () => import('./icons/IconSquare'),
  'squares-diagonal': () => import('./icons/IconSquaresDiagonal'),
  'squares-selected': () => import('./icons/IconSquaresSelected'),
  'squares': () => import('./icons/IconSquares'),
  'stack-2': () => import('./icons/IconStack2'),
  'stack-3': () => import('./icons/IconStack3'),
  'stack-back': () => import('./icons/IconStackBack'),
  'stack-backward': () => import('./icons/IconStackBackward'),
  'stack-forward': () => import('./icons/IconStackForward'),
  'stack-front': () => import('./icons/IconStackFront'),
  'stack-middle': () => import('./icons/IconStackMiddle'),
  'stack-pop': () => import('./icons/IconStackPop'),
  'stack-push': () => import('./icons/IconStackPush'),
  'stack': () => import('./icons/IconStack'),
  'stairs-down': () => import('./icons/IconStairsDown'),
  'stairs-up': () => import('./icons/IconStairsUp'),
  'stairs': () => import('./icons/IconStairs'),
  'star-half': () => import('./icons/IconStarHalf'),
  'star-off': () => import('./icons/IconStarOff'),
  'star': () => import('./icons/IconStar'),
  'stars-off': () => import('./icons/IconStarsOff'),
  'stars': () => import('./icons/IconStars'),
  'status-change': () => import('./icons/IconStatusChange'),
  'steam': () => import('./icons/IconSteam'),
  'steering-wheel-off': () => import('./icons/IconSteeringWheelOff'),
  'steering-wheel': () => import('./icons/IconSteeringWheel'),
  'step-into': () => import('./icons/IconStepInto'),
  'step-out': () => import('./icons/IconStepOut'),
  'stereo-glasses': () => import('./icons/IconStereoGlasses'),
  'stethoscope-off': () => import('./icons/IconStethoscopeOff'),
  'stethoscope': () => import('./icons/IconStethoscope'),
  'sticker-2': () => import('./icons/IconSticker2'),
  'sticker': () => import('./icons/IconSticker'),
  'stopwatch': () => import('./icons/IconStopwatch'),
  'storm-off': () => import('./icons/IconStormOff'),
  'storm': () => import('./icons/IconStorm'),
  'stretching-2': () => import('./icons/IconStretching2'),
  'stretching': () => import('./icons/IconStretching'),
  'strikethrough': () => import('./icons/IconStrikethrough'),
  'submarine': () => import('./icons/IconSubmarine'),
  'subscript': () => import('./icons/IconSubscript'),
  'subtask': () => import('./icons/IconSubtask'),
  'sum-off': () => import('./icons/IconSumOff'),
  'sum': () => import('./icons/IconSum'),
  'sun-electricity': () => import('./icons/IconSunElectricity'),
  'sun-high': () => import('./icons/IconSunHigh'),
  'sun-low': () => import('./icons/IconSunLow'),
  'sun-moon': () => import('./icons/IconSunMoon'),
  'sun-off': () => import('./icons/IconSunOff'),
  'sun-wind': () => import('./icons/IconSunWind'),
  'sun': () => import('./icons/IconSun'),
  'sunglasses': () => import('./icons/IconSunglasses'),
  'sunrise': () => import('./icons/IconSunrise'),
  'sunset-2': () => import('./icons/IconSunset2'),
  'sunset': () => import('./icons/IconSunset'),
  'superscript': () => import('./icons/IconSuperscript'),
  'svg': () => import('./icons/IconSvg'),
  'swimming': () => import('./icons/IconSwimming'),
  'swipe-down': () => import('./icons/IconSwipeDown'),
  'swipe-left': () => import('./icons/IconSwipeLeft'),
  'swipe-right': () => import('./icons/IconSwipeRight'),
  'swipe-up': () => import('./icons/IconSwipeUp'),
  'swipe': () => import('./icons/IconSwipe'),
  'switch-2': () => import('./icons/IconSwitch2'),
  'switch-3': () => import('./icons/IconSwitch3'),
  'switch-horizontal': () => import('./icons/IconSwitchHorizontal'),
  'switch-vertical': () => import('./icons/IconSwitchVertical'),
  'switch': () => import('./icons/IconSwitch'),
  'sword-off': () => import('./icons/IconSwordOff'),
  'sword': () => import('./icons/IconSword'),
  'swords': () => import('./icons/IconSwords'),
  'table-alias': () => import('./icons/IconTableAlias'),
  'table-column': () => import('./icons/IconTableColumn'),
  'table-down': () => import('./icons/IconTableDown'),
  'table-export': () => import('./icons/IconTableExport'),
  'table-heart': () => import('./icons/IconTableHeart'),
  'table-import': () => import('./icons/IconTableImport'),
  'table-minus': () => import('./icons/IconTableMinus'),
  'table-off': () => import('./icons/IconTableOff'),
  'table-options': () => import('./icons/IconTableOptions'),
  'table-plus': () => import('./icons/IconTablePlus'),
  'table-row': () => import('./icons/IconTableRow'),
  'table-share': () => import('./icons/IconTableShare'),
  'table-shortcut': () => import('./icons/IconTableShortcut'),
  'table-spark': () => import('./icons/IconTableSpark'),
  'table': () => import('./icons/IconTable'),
  'tag-off': () => import('./icons/IconTagOff'),
  'tag-starred': () => import('./icons/IconTagStarred'),
  'tag': () => import('./icons/IconTag'),
  'tags-off': () => import('./icons/IconTagsOff'),
  'tags': () => import('./icons/IconTags'),
  'tallymark-1': () => import('./icons/IconTallymark1'),
  'tallymark-2': () => import('./icons/IconTallymark2'),
  'tallymark-3': () => import('./icons/IconTallymark3'),
  'tallymark-4': () => import('./icons/IconTallymark4'),
  'tallymarks': () => import('./icons/IconTallymarks'),
  'tank': () => import('./icons/IconTank'),
  'target-arrow': () => import('./icons/IconTargetArrow'),
  'target-off': () => import('./icons/IconTargetOff'),
  'target': () => import('./icons/IconTarget'),
  'tax-euro': () => import('./icons/IconTaxEuro'),
  'tax-pound': () => import('./icons/IconTaxPound'),
  'tax': () => import('./icons/IconTax'),
  'teapot': () => import('./icons/IconTeapot'),
  'telescope-off': () => import('./icons/IconTelescopeOff'),
  'telescope': () => import('./icons/IconTelescope'),
  'temperature-celsius': () => import('./icons/IconTemperatureCelsius'),
  'temperature-fahrenheit': () => import('./icons/IconTemperatureFahrenheit'),
  'temperature-minus': () => import('./icons/IconTemperatureMinus'),
  'temperature-off': () => import('./icons/IconTemperatureOff'),
  'temperature-plus': () => import('./icons/IconTemperaturePlus'),
  'temperature-snow': () => import('./icons/IconTemperatureSnow'),
  'temperature-sun': () => import('./icons/IconTemperatureSun'),
  'temperature': () => import('./icons/IconTemperature'),
  'template-off': () => import('./icons/IconTemplateOff'),
  'template': () => import('./icons/IconTemplate'),
  'tent-off': () => import('./icons/IconTentOff'),
  'tent': () => import('./icons/IconTent'),
  'terminal-2': () => import('./icons/IconTerminal2'),
  'terminal': () => import('./icons/IconTerminal'),
  'test-pipe-2': () => import('./icons/IconTestPipe2'),
  'test-pipe-off': () => import('./icons/IconTestPipeOff'),
  'test-pipe': () => import('./icons/IconTestPipe'),
  'tex': () => import('./icons/IconTex'),
  'text-caption': () => import('./icons/IconTextCaption'),
  'text-color': () => import('./icons/IconTextColor'),
  'text-decrease': () => import('./icons/IconTextDecrease'),
  'text-direction-ltr': () => import('./icons/IconTextDirectionLtr'),
  'text-direction-rtl': () => import('./icons/IconTextDirectionRtl'),
  'text-grammar': () => import('./icons/IconTextGrammar'),
  'text-increase': () => import('./icons/IconTextIncrease'),
  'text-orientation': () => import('./icons/IconTextOrientation'),
  'text-plus': () => import('./icons/IconTextPlus'),
  'text-recognition': () => import('./icons/IconTextRecognition'),
  'text-resize': () => import('./icons/IconTextResize'),
  'text-scan-2': () => import('./icons/IconTextScan2'),
  'text-size': () => import('./icons/IconTextSize'),
  'text-spellcheck': () => import('./icons/IconTextSpellcheck'),
  'text-wrap-column': () => import('./icons/IconTextWrapColumn'),
  'text-wrap-disabled': () => import('./icons/IconTextWrapDisabled'),
  'text-wrap': () => import('./icons/IconTextWrap'),
  'texture': () => import('./icons/IconTexture'),
  'theater': () => import('./icons/IconTheater'),
  'thermometer': () => import('./icons/IconThermometer'),
  'thumb-down-off': () => import('./icons/IconThumbDownOff'),
  'thumb-down': () => import('./icons/IconThumbDown'),
  'thumb-up-off': () => import('./icons/IconThumbUpOff'),
  'thumb-up': () => import('./icons/IconThumbUp'),
  'tic-tac': () => import('./icons/IconTicTac'),
  'ticket-off': () => import('./icons/IconTicketOff'),
  'ticket': () => import('./icons/IconTicket'),
  'tie': () => import('./icons/IconTie'),
  'tilde': () => import('./icons/IconTilde'),
  'tilt-shift-off': () => import('./icons/IconTiltShiftOff'),
  'tilt-shift': () => import('./icons/IconTiltShift'),
  'time-duration-0': () => import('./icons/IconTimeDuration0'),
  'time-duration-10': () => import('./icons/IconTimeDuration10'),
  'time-duration-15': () => import('./icons/IconTimeDuration15'),
  'time-duration-30': () => import('./icons/IconTimeDuration30'),
  'time-duration-45': () => import('./icons/IconTimeDuration45'),
  'time-duration-5': () => import('./icons/IconTimeDuration5'),
  'time-duration-60': () => import('./icons/IconTimeDuration60'),
  'time-duration-90': () => import('./icons/IconTimeDuration90'),
  'time-duration-off': () => import('./icons/IconTimeDurationOff'),
  'timeline-event-exclamation': () => import('./icons/IconTimelineEventExclamation'),
  'timeline-event-minus': () => import('./icons/IconTimelineEventMinus'),
  'timeline-event-plus': () => import('./icons/IconTimelineEventPlus'),
  'timeline-event-text': () => import('./icons/IconTimelineEventText'),
  'timeline-event-x': () => import('./icons/IconTimelineEventX'),
  'timeline-event': () => import('./icons/IconTimelineEvent'),
  'timeline': () => import('./icons/IconTimeline'),
  'timezone': () => import('./icons/IconTimezone'),
  'tip-jar-euro': () => import('./icons/IconTipJarEuro'),
  'tip-jar-pound': () => import('./icons/IconTipJarPound'),
  'tip-jar': () => import('./icons/IconTipJar'),
  'tir': () => import('./icons/IconTir'),
  'toggle-left': () => import('./icons/IconToggleLeft'),
  'toggle-right': () => import('./icons/IconToggleRight'),
  'toilet-paper-off': () => import('./icons/IconToiletPaperOff'),
  'toilet-paper': () => import('./icons/IconToiletPaper'),
  'toml': () => import('./icons/IconToml'),
  'tool': () => import('./icons/IconTool'),
  'tools-kitchen-2-off': () => import('./icons/IconToolsKitchen2Off'),
  'tools-kitchen-2': () => import('./icons/IconToolsKitchen2'),
  'tools-kitchen-3': () => import('./icons/IconToolsKitchen3'),
  'tools-kitchen-off': () => import('./icons/IconToolsKitchenOff'),
  'tools-kitchen': () => import('./icons/IconToolsKitchen'),
  'tools-off': () => import('./icons/IconToolsOff'),
  'tools': () => import('./icons/IconTools'),
  'tooltip': () => import('./icons/IconTooltip'),
  'topology-bus': () => import('./icons/IconTopologyBus'),
  'topology-complex': () => import('./icons/IconTopologyComplex'),
  'topology-full-hierarchy': () => import('./icons/IconTopologyFullHierarchy'),
  'topology-full': () => import('./icons/IconTopologyFull'),
  'topology-ring-2': () => import('./icons/IconTopologyRing2'),
  'topology-ring-3': () => import('./icons/IconTopologyRing3'),
  'topology-ring': () => import('./icons/IconTopologyRing'),
  'topology-star-2': () => import('./icons/IconTopologyStar2'),
  'topology-star-3': () => import('./icons/IconTopologyStar3'),
  'topology-star-ring-2': () => import('./icons/IconTopologyStarRing2'),
  'topology-star-ring-3': () => import('./icons/IconTopologyStarRing3'),
  'topology-star-ring': () => import('./icons/IconTopologyStarRing'),
  'topology-star': () => import('./icons/IconTopologyStar'),
  'torii': () => import('./icons/IconTorii'),
  'tornado': () => import('./icons/IconTornado'),
  'tournament': () => import('./icons/IconTournament'),
  'tower-off': () => import('./icons/IconTowerOff'),
  'tower': () => import('./icons/IconTower'),
  'track': () => import('./icons/IconTrack'),
  'tractor': () => import('./icons/IconTractor'),
  'trademark': () => import('./icons/IconTrademark'),
  'traffic-cone-off': () => import('./icons/IconTrafficConeOff'),
  'traffic-cone': () => import('./icons/IconTrafficCone'),
  'traffic-lights-off': () => import('./icons/IconTrafficLightsOff'),
  'traffic-lights': () => import('./icons/IconTrafficLights'),
  'train': () => import('./icons/IconTrain'),
  'transaction-bitcoin': () => import('./icons/IconTransactionBitcoin'),
  'transaction-dollar': () => import('./icons/IconTransactionDollar'),
  'transaction-euro': () => import('./icons/IconTransactionEuro'),
  'transaction-pound': () => import('./icons/IconTransactionPound'),
  'transaction-rupee': () => import('./icons/IconTransactionRupee'),
  'transaction-yen': () => import('./icons/IconTransactionYen'),
  'transaction-yuan': () => import('./icons/IconTransactionYuan'),
  'transfer-in': () => import('./icons/IconTransferIn'),
  'transfer-out': () => import('./icons/IconTransferOut'),
  'transfer-vertical': () => import('./icons/IconTransferVertical'),
  'transfer': () => import('./icons/IconTransfer'),
  'transform-point-bottom-left': () => import('./icons/IconTransformPointBottomLeft'),
  'transform-point-bottom-right': () => import('./icons/IconTransformPointBottomRight'),
  'transform-point-top-left': () => import('./icons/IconTransformPointTopLeft'),
  'transform-point-top-right': () => import('./icons/IconTransformPointTopRight'),
  'transform-point': () => import('./icons/IconTransformPoint'),
  'transform': () => import('./icons/IconTransform'),
  'transition-bottom': () => import('./icons/IconTransitionBottom'),
  'transition-left': () => import('./icons/IconTransitionLeft'),
  'transition-right': () => import('./icons/IconTransitionRight'),
  'transition-top': () => import('./icons/IconTransitionTop'),
  'trash-off': () => import('./icons/IconTrashOff'),
  'trash-x': () => import('./icons/IconTrashX'),
  'trash': () => import('./icons/IconTrash'),
  'treadmill': () => import('./icons/IconTreadmill'),
  'tree': () => import('./icons/IconTree'),
  'trees': () => import('./icons/IconTrees'),
  'trekking': () => import('./icons/IconTrekking'),
  'trending-down-2': () => import('./icons/IconTrendingDown2'),
  'trending-down-3': () => import('./icons/IconTrendingDown3'),
  'trending-down': () => import('./icons/IconTrendingDown'),
  'trending-up-2': () => import('./icons/IconTrendingUp2'),
  'trending-up-3': () => import('./icons/IconTrendingUp3'),
  'trending-up': () => import('./icons/IconTrendingUp'),
  'triangle-inverted': () => import('./icons/IconTriangleInverted'),
  'triangle-minus-2': () => import('./icons/IconTriangleMinus2'),
  'triangle-minus': () => import('./icons/IconTriangleMinus'),
  'triangle-off': () => import('./icons/IconTriangleOff'),
  'triangle-plus-2': () => import('./icons/IconTrianglePlus2'),
  'triangle-plus': () => import('./icons/IconTrianglePlus'),
  'triangle-square-circle': () => import('./icons/IconTriangleSquareCircle'),
  'triangle': () => import('./icons/IconTriangle'),
  'triangles': () => import('./icons/IconTriangles'),
  'trident': () => import('./icons/IconTrident'),
  'trolley': () => import('./icons/IconTrolley'),
  'trophy-off': () => import('./icons/IconTrophyOff'),
  'trophy': () => import('./icons/IconTrophy'),
  'trowel': () => import('./icons/IconTrowel'),
  'truck-delivery': () => import('./icons/IconTruckDelivery'),
  'truck-loading': () => import('./icons/IconTruckLoading'),
  'truck-off': () => import('./icons/IconTruckOff'),
  'truck-return': () => import('./icons/IconTruckReturn'),
  'truck': () => import('./icons/IconTruck'),
  'txt': () => import('./icons/IconTxt'),
  'typeface': () => import('./icons/IconTypeface'),
  'typography-off': () => import('./icons/IconTypographyOff'),
  'typography': () => import('./icons/IconTypography'),
  'u-turn-left': () => import('./icons/IconUTurnLeft'),
  'u-turn-right': () => import('./icons/IconUTurnRight'),
  'ufo-off': () => import('./icons/IconUfoOff'),
  'ufo': () => import('./icons/IconUfo'),
  'umbrella-2': () => import('./icons/IconUmbrella2'),
  'umbrella-closed-2': () => import('./icons/IconUmbrellaClosed2'),
  'umbrella-closed': () => import('./icons/IconUmbrellaClosed'),
  'umbrella-off': () => import('./icons/IconUmbrellaOff'),
  'umbrella': () => import('./icons/IconUmbrella'),
  'underline': () => import('./icons/IconUnderline'),
  'universe': () => import('./icons/IconUniverse'),
  'unlink': () => import('./icons/IconUnlink'),
  'upload': () => import('./icons/IconUpload'),
  'urgent': () => import('./icons/IconUrgent'),
  'usb': () => import('./icons/IconUsb'),
  'user-bitcoin': () => import('./icons/IconUserBitcoin'),
  'user-bolt': () => import('./icons/IconUserBolt'),
  'user-cancel': () => import('./icons/IconUserCancel'),
  'user-check': () => import('./icons/IconUserCheck'),
  'user-circle': () => import('./icons/IconUserCircle'),
  'user-code': () => import('./icons/IconUserCode'),
  'user-cog': () => import('./icons/IconUserCog'),
  'user-dollar': () => import('./icons/IconUserDollar'),
  'user-down': () => import('./icons/IconUserDown'),
  'user-edit': () => import('./icons/IconUserEdit'),
  'user-exclamation': () => import('./icons/IconUserExclamation'),
  'user-heart': () => import('./icons/IconUserHeart'),
  'user-hexagon': () => import('./icons/IconUserHexagon'),
  'user-minus': () => import('./icons/IconUserMinus'),
  'user-off': () => import('./icons/IconUserOff'),
  'user-pause': () => import('./icons/IconUserPause'),
  'user-pentagon': () => import('./icons/IconUserPentagon'),
  'user-pin': () => import('./icons/IconUserPin'),
  'user-plus': () => import('./icons/IconUserPlus'),
  'user-question': () => import('./icons/IconUserQuestion'),
  'user-scan': () => import('./icons/IconUserScan'),
  'user-screen': () => import('./icons/IconUserScreen'),
  'user-search': () => import('./icons/IconUserSearch'),
  'user-share': () => import('./icons/IconUserShare'),
  'user-shield': () => import('./icons/IconUserShield'),
  'user-square-rounded': () => import('./icons/IconUserSquareRounded'),
  'user-square': () => import('./icons/IconUserSquare'),
  'user-star': () => import('./icons/IconUserStar'),
  'user-up': () => import('./icons/IconUserUp'),
  'user-x': () => import('./icons/IconUserX'),
  'user': () => import('./icons/IconUser'),
  'users-group': () => import('./icons/IconUsersGroup'),
  'users-minus': () => import('./icons/IconUsersMinus'),
  'users-plus': () => import('./icons/IconUsersPlus'),
  'users': () => import('./icons/IconUsers'),
  'uv-index': () => import('./icons/IconUvIndex'),
  'ux-circle': () => import('./icons/IconUxCircle'),
  'vaccine-bottle-off': () => import('./icons/IconVaccineBottleOff'),
  'vaccine-bottle': () => import('./icons/IconVaccineBottle'),
  'vaccine-off': () => import('./icons/IconVaccineOff'),
  'vaccine': () => import('./icons/IconVaccine'),
  'vacuum-cleaner': () => import('./icons/IconVacuumCleaner'),
  'variable-minus': () => import('./icons/IconVariableMinus'),
  'variable-off': () => import('./icons/IconVariableOff'),
  'variable-plus': () => import('./icons/IconVariablePlus'),
  'variable': () => import('./icons/IconVariable'),
  'vector-bezier-2': () => import('./icons/IconVectorBezier2'),
  'vector-bezier-arc': () => import('./icons/IconVectorBezierArc'),
  'vector-bezier-circle': () => import('./icons/IconVectorBezierCircle'),
  'vector-bezier': () => import('./icons/IconVectorBezier'),
  'vector-off': () => import('./icons/IconVectorOff'),
  'vector-spline': () => import('./icons/IconVectorSpline'),
  'vector-triangle-off': () => import('./icons/IconVectorTriangleOff'),
  'vector-triangle': () => import('./icons/IconVectorTriangle'),
  'vector': () => import('./icons/IconVector'),
  'venus': () => import('./icons/IconVenus'),
  'versions-off': () => import('./icons/IconVersionsOff'),
  'versions': () => import('./icons/IconVersions'),
  'video-minus': () => import('./icons/IconVideoMinus'),
  'video-off': () => import('./icons/IconVideoOff'),
  'video-plus': () => import('./icons/IconVideoPlus'),
  'video': () => import('./icons/IconVideo'),
  'view-360-arrow': () => import('./icons/IconView360Arrow'),
  'view-360-number': () => import('./icons/IconView360Number'),
  'view-360-off': () => import('./icons/IconView360Off'),
  'view-360': () => import('./icons/IconView360'),
  'viewfinder-off': () => import('./icons/IconViewfinderOff'),
  'viewfinder': () => import('./icons/IconViewfinder'),
  'viewport-narrow': () => import('./icons/IconViewportNarrow'),
  'viewport-short': () => import('./icons/IconViewportShort'),
  'viewport-tall': () => import('./icons/IconViewportTall'),
  'viewport-wide': () => import('./icons/IconViewportWide'),
  'vinyl': () => import('./icons/IconVinyl'),
  'vip-off': () => import('./icons/IconVipOff'),
  'vip': () => import('./icons/IconVip'),
  'virus-off': () => import('./icons/IconVirusOff'),
  'virus-search': () => import('./icons/IconVirusSearch'),
  'virus': () => import('./icons/IconVirus'),
  'vocabulary-off': () => import('./icons/IconVocabularyOff'),
  'vocabulary': () => import('./icons/IconVocabulary'),
  'volcano': () => import('./icons/IconVolcano'),
  'volume-2': () => import('./icons/IconVolume2'),
  'volume-3': () => import('./icons/IconVolume3'),
  'volume-off': () => import('./icons/IconVolumeOff'),
  'volume': () => import('./icons/IconVolume'),
  'vs': () => import('./icons/IconVs'),
  'walk': () => import('./icons/IconWalk'),
  'wall-off': () => import('./icons/IconWallOff'),
  'wall': () => import('./icons/IconWall'),
  'wallet-off': () => import('./icons/IconWalletOff'),
  'wallet': () => import('./icons/IconWallet'),
  'wallpaper-off': () => import('./icons/IconWallpaperOff'),
  'wallpaper': () => import('./icons/IconWallpaper'),
  'wand-off': () => import('./icons/IconWandOff'),
  'wand': () => import('./icons/IconWand'),
  'wash-dry-1': () => import('./icons/IconWashDry1'),
  'wash-dry-2': () => import('./icons/IconWashDry2'),
  'wash-dry-3': () => import('./icons/IconWashDry3'),
  'wash-dry-a': () => import('./icons/IconWashDryA'),
  'wash-dry-dip': () => import('./icons/IconWashDryDip'),
  'wash-dry-f': () => import('./icons/IconWashDryF'),
  'wash-dry-flat': () => import('./icons/IconWashDryFlat'),
  'wash-dry-hang': () => import('./icons/IconWashDryHang'),
  'wash-dry-off': () => import('./icons/IconWashDryOff'),
  'wash-dry-p': () => import('./icons/IconWashDryP'),
  'wash-dry-shade': () => import('./icons/IconWashDryShade'),
  'wash-dry-w': () => import('./icons/IconWashDryW'),
  'wash-dry': () => import('./icons/IconWashDry'),
  'wash-dryclean-off': () => import('./icons/IconWashDrycleanOff'),
  'wash-dryclean': () => import('./icons/IconWashDryclean'),
  'wash-eco': () => import('./icons/IconWashEco'),
  'wash-gentle': () => import('./icons/IconWashGentle'),
  'wash-hand': () => import('./icons/IconWashHand'),
  'wash-machine': () => import('./icons/IconWashMachine'),
  'wash-off': () => import('./icons/IconWashOff'),
  'wash-press': () => import('./icons/IconWashPress'),
  'wash-temperature-1': () => import('./icons/IconWashTemperature1'),
  'wash-temperature-2': () => import('./icons/IconWashTemperature2'),
  'wash-temperature-3': () => import('./icons/IconWashTemperature3'),
  'wash-temperature-4': () => import('./icons/IconWashTemperature4'),
  'wash-temperature-5': () => import('./icons/IconWashTemperature5'),
  'wash-temperature-6': () => import('./icons/IconWashTemperature6'),
  'wash-tumble-dry': () => import('./icons/IconWashTumbleDry'),
  'wash-tumble-off': () => import('./icons/IconWashTumbleOff'),
  'wash': () => import('./icons/IconWash'),
  'waterpolo': () => import('./icons/IconWaterpolo'),
  'wave-saw-tool': () => import('./icons/IconWaveSawTool'),
  'wave-sine': () => import('./icons/IconWaveSine'),
  'wave-square': () => import('./icons/IconWaveSquare'),
  'waves-electricity': () => import('./icons/IconWavesElectricity'),
  'webhook-off': () => import('./icons/IconWebhookOff'),
  'webhook': () => import('./icons/IconWebhook'),
  'weight': () => import('./icons/IconWeight'),
  'wheel': () => import('./icons/IconWheel'),
  'wheelchair-off': () => import('./icons/IconWheelchairOff'),
  'wheelchair': () => import('./icons/IconWheelchair'),
  'whirl': () => import('./icons/IconWhirl'),
  'wifi-0': () => import('./icons/IconWifi0'),
  'wifi-1': () => import('./icons/IconWifi1'),
  'wifi-2': () => import('./icons/IconWifi2'),
  'wifi-off': () => import('./icons/IconWifiOff'),
  'wifi': () => import('./icons/IconWifi'),
  'wind-electricity': () => import('./icons/IconWindElectricity'),
  'wind-off': () => import('./icons/IconWindOff'),
  'wind': () => import('./icons/IconWind'),
  'windmill-off': () => import('./icons/IconWindmillOff'),
  'windmill': () => import('./icons/IconWindmill'),
  'window-maximize': () => import('./icons/IconWindowMaximize'),
  'window-minimize': () => import('./icons/IconWindowMinimize'),
  'window-off': () => import('./icons/IconWindowOff'),
  'window': () => import('./icons/IconWindow'),
  'windsock': () => import('./icons/IconWindsock'),
  'wiper-wash': () => import('./icons/IconWiperWash'),
  'wiper': () => import('./icons/IconWiper'),
  'woman': () => import('./icons/IconWoman'),
  'wood': () => import('./icons/IconWood'),
  'world-bolt': () => import('./icons/IconWorldBolt'),
  'world-cancel': () => import('./icons/IconWorldCancel'),
  'world-check': () => import('./icons/IconWorldCheck'),
  'world-code': () => import('./icons/IconWorldCode'),
  'world-cog': () => import('./icons/IconWorldCog'),
  'world-dollar': () => import('./icons/IconWorldDollar'),
  'world-down': () => import('./icons/IconWorldDown'),
  'world-download': () => import('./icons/IconWorldDownload'),
  'world-exclamation': () => import('./icons/IconWorldExclamation'),
  'world-heart': () => import('./icons/IconWorldHeart'),
  'world-latitude': () => import('./icons/IconWorldLatitude'),
  'world-longitude': () => import('./icons/IconWorldLongitude'),
  'world-minus': () => import('./icons/IconWorldMinus'),
  'world-off': () => import('./icons/IconWorldOff'),
  'world-pause': () => import('./icons/IconWorldPause'),
  'world-pin': () => import('./icons/IconWorldPin'),
  'world-plus': () => import('./icons/IconWorldPlus'),
  'world-question': () => import('./icons/IconWorldQuestion'),
  'world-search': () => import('./icons/IconWorldSearch'),
  'world-share': () => import('./icons/IconWorldShare'),
  'world-star': () => import('./icons/IconWorldStar'),
  'world-up': () => import('./icons/IconWorldUp'),
  'world-upload': () => import('./icons/IconWorldUpload'),
  'world-www': () => import('./icons/IconWorldWww'),
  'world-x': () => import('./icons/IconWorldX'),
  'world': () => import('./icons/IconWorld'),
  'wrecking-ball': () => import('./icons/IconWreckingBall'),
  'writing-off': () => import('./icons/IconWritingOff'),
  'writing-sign-off': () => import('./icons/IconWritingSignOff'),
  'writing-sign': () => import('./icons/IconWritingSign'),
  'writing': () => import('./icons/IconWriting'),
  'x-power-y': () => import('./icons/IconXPowerY'),
  'x': () => import('./icons/IconX'),
  'xbox-a': () => import('./icons/IconXboxA'),
  'xbox-b': () => import('./icons/IconXboxB'),
  'xbox-x': () => import('./icons/IconXboxX'),
  'xbox-y': () => import('./icons/IconXboxY'),
  'xd': () => import('./icons/IconXd'),
  'xxx': () => import('./icons/IconXxx'),
  'yin-yang': () => import('./icons/IconYinYang'),
  'yoga': () => import('./icons/IconYoga'),
  'zeppelin-off': () => import('./icons/IconZeppelinOff'),
  'zeppelin': () => import('./icons/IconZeppelin'),
  'zip': () => import('./icons/IconZip'),
  'zodiac-aquarius': () => import('./icons/IconZodiacAquarius'),
  'zodiac-aries': () => import('./icons/IconZodiacAries'),
  'zodiac-cancer': () => import('./icons/IconZodiacCancer'),
  'zodiac-capricorn': () => import('./icons/IconZodiacCapricorn'),
  'zodiac-gemini': () => import('./icons/IconZodiacGemini'),
  'zodiac-leo': () => import('./icons/IconZodiacLeo'),
  'zodiac-libra': () => import('./icons/IconZodiacLibra'),
  'zodiac-pisces': () => import('./icons/IconZodiacPisces'),
  'zodiac-sagittarius': () => import('./icons/IconZodiacSagittarius'),
  'zodiac-scorpio': () => import('./icons/IconZodiacScorpio'),
  'zodiac-taurus': () => import('./icons/IconZodiacTaurus'),
  'zodiac-virgo': () => import('./icons/IconZodiacVirgo'),
  'zoom-cancel': () => import('./icons/IconZoomCancel'),
  'zoom-check': () => import('./icons/IconZoomCheck'),
  'zoom-code': () => import('./icons/IconZoomCode'),
  'zoom-exclamation': () => import('./icons/IconZoomExclamation'),
  'zoom-in-area': () => import('./icons/IconZoomInArea'),
  'zoom-in': () => import('./icons/IconZoomIn'),
  'zoom-money': () => import('./icons/IconZoomMoney'),
  'zoom-out-area': () => import('./icons/IconZoomOutArea'),
  'zoom-out': () => import('./icons/IconZoomOut'),
  'zoom-pan': () => import('./icons/IconZoomPan'),
  'zoom-question': () => import('./icons/IconZoomQuestion'),
  'zoom-replace': () => import('./icons/IconZoomReplace'),
  'zoom-reset': () => import('./icons/IconZoomReset'),
  'zoom-scan': () => import('./icons/IconZoomScan'),
  'zoom': () => import('./icons/IconZoom'),
  'zzz-off': () => import('./icons/IconZzzOff'),
  'zzz': () => import('./icons/IconZzz'),
  'accessible-filled': () => import('./icons/IconAccessibleFilled'),
  'ad-circle-filled': () => import('./icons/IconAdCircleFilled'),
  'ad-filled': () => import('./icons/IconAdFilled'),
  'adjustments-filled': () => import('./icons/IconAdjustmentsFilled'),
  'affiliate-filled': () => import('./icons/IconAffiliateFilled'),
  'alarm-minus-filled': () => import('./icons/IconAlarmMinusFilled'),
  'alarm-plus-filled': () => import('./icons/IconAlarmPlusFilled'),
  'alarm-snooze-filled': () => import('./icons/IconAlarmSnoozeFilled'),
  'alarm-filled': () => import('./icons/IconAlarmFilled'),
  'alert-circle-filled': () => import('./icons/IconAlertCircleFilled'),
  'alert-hexagon-filled': () => import('./icons/IconAlertHexagonFilled'),
  'alert-octagon-filled': () => import('./icons/IconAlertOctagonFilled'),
  'alert-square-rounded-filled': () => import('./icons/IconAlertSquareRoundedFilled'),
  'alert-square-filled': () => import('./icons/IconAlertSquareFilled'),
  'alert-triangle-filled': () => import('./icons/IconAlertTriangleFilled'),
  'alien-filled': () => import('./icons/IconAlienFilled'),
  'align-box-bottom-center-filled': () => import('./icons/IconAlignBoxBottomCenterFilled'),
  'align-box-bottom-left-filled': () => import('./icons/IconAlignBoxBottomLeftFilled'),
  'align-box-bottom-right-filled': () => import('./icons/IconAlignBoxBottomRightFilled'),
  'align-box-center-middle-filled': () => import('./icons/IconAlignBoxCenterMiddleFilled'),
  'align-box-left-bottom-filled': () => import('./icons/IconAlignBoxLeftBottomFilled'),
  'align-box-left-middle-filled': () => import('./icons/IconAlignBoxLeftMiddleFilled'),
  'align-box-left-top-filled': () => import('./icons/IconAlignBoxLeftTopFilled'),
  'align-box-right-bottom-filled': () => import('./icons/IconAlignBoxRightBottomFilled'),
  'align-box-right-middle-filled': () => import('./icons/IconAlignBoxRightMiddleFilled'),
  'align-box-right-top-filled': () => import('./icons/IconAlignBoxRightTopFilled'),
  'align-box-top-center-filled': () => import('./icons/IconAlignBoxTopCenterFilled'),
  'align-box-top-left-filled': () => import('./icons/IconAlignBoxTopLeftFilled'),
  'align-box-top-right-filled': () => import('./icons/IconAlignBoxTopRightFilled'),
  'analyze-filled': () => import('./icons/IconAnalyzeFilled'),
  'app-window-filled': () => import('./icons/IconAppWindowFilled'),
  'apple-filled': () => import('./icons/IconAppleFilled'),
  'apps-filled': () => import('./icons/IconAppsFilled'),
  'archive-filled': () => import('./icons/IconArchiveFilled'),
  'arrow-autofit-content-filled': () => import('./icons/IconArrowAutofitContentFilled'),
  'arrow-badge-down-filled': () => import('./icons/IconArrowBadgeDownFilled'),
  'arrow-badge-left-filled': () => import('./icons/IconArrowBadgeLeftFilled'),
  'arrow-badge-right-filled': () => import('./icons/IconArrowBadgeRightFilled'),
  'arrow-badge-up-filled': () => import('./icons/IconArrowBadgeUpFilled'),
  'arrow-big-down-line-filled': () => import('./icons/IconArrowBigDownLineFilled'),
  'arrow-big-down-lines-filled': () => import('./icons/IconArrowBigDownLinesFilled'),
  'arrow-big-down-filled': () => import('./icons/IconArrowBigDownFilled'),
  'arrow-big-left-line-filled': () => import('./icons/IconArrowBigLeftLineFilled'),
  'arrow-big-left-lines-filled': () => import('./icons/IconArrowBigLeftLinesFilled'),
  'arrow-big-left-filled': () => import('./icons/IconArrowBigLeftFilled'),
  'arrow-big-right-line-filled': () => import('./icons/IconArrowBigRightLineFilled'),
  'arrow-big-right-lines-filled': () => import('./icons/IconArrowBigRightLinesFilled'),
  'arrow-big-right-filled': () => import('./icons/IconArrowBigRightFilled'),
  'arrow-big-up-line-filled': () => import('./icons/IconArrowBigUpLineFilled'),
  'arrow-big-up-lines-filled': () => import('./icons/IconArrowBigUpLinesFilled'),
  'arrow-big-up-filled': () => import('./icons/IconArrowBigUpFilled'),
  'arrow-down-circle-filled': () => import('./icons/IconArrowDownCircleFilled'),
  'arrow-down-rhombus-filled': () => import('./icons/IconArrowDownRhombusFilled'),
  'arrow-down-square-filled': () => import('./icons/IconArrowDownSquareFilled'),
  'arrow-guide-filled': () => import('./icons/IconArrowGuideFilled'),
  'arrow-left-circle-filled': () => import('./icons/IconArrowLeftCircleFilled'),
  'arrow-left-rhombus-filled': () => import('./icons/IconArrowLeftRhombusFilled'),
  'arrow-left-square-filled': () => import('./icons/IconArrowLeftSquareFilled'),
  'arrow-move-down-filled': () => import('./icons/IconArrowMoveDownFilled'),
  'arrow-move-left-filled': () => import('./icons/IconArrowMoveLeftFilled'),
  'arrow-move-right-filled': () => import('./icons/IconArrowMoveRightFilled'),
  'arrow-move-up-filled': () => import('./icons/IconArrowMoveUpFilled'),
  'arrow-right-circle-filled': () => import('./icons/IconArrowRightCircleFilled'),
  'arrow-right-rhombus-filled': () => import('./icons/IconArrowRightRhombusFilled'),
  'arrow-right-square-filled': () => import('./icons/IconArrowRightSquareFilled'),
  'arrow-up-circle-filled': () => import('./icons/IconArrowUpCircleFilled'),
  'arrow-up-rhombus-filled': () => import('./icons/IconArrowUpRhombusFilled'),
  'arrow-up-square-filled': () => import('./icons/IconArrowUpSquareFilled'),
  'artboard-filled': () => import('./icons/IconArtboardFilled'),
  'article-filled': () => import('./icons/IconArticleFilled'),
  'aspect-ratio-filled': () => import('./icons/IconAspectRatioFilled'),
  'assembly-filled': () => import('./icons/IconAssemblyFilled'),
  'asset-filled': () => import('./icons/IconAssetFilled'),
  'atom-2-filled': () => import('./icons/IconAtom2Filled'),
  'automatic-gearbox-filled': () => import('./icons/IconAutomaticGearboxFilled'),
  'award-filled': () => import('./icons/IconAwardFilled'),
  'baby-carriage-filled': () => import('./icons/IconBabyCarriageFilled'),
  'backspace-filled': () => import('./icons/IconBackspaceFilled'),
  'badge-3d-filled': () => import('./icons/IconBadge3dFilled'),
  'badge-4k-filled': () => import('./icons/IconBadge4kFilled'),
  'badge-8k-filled': () => import('./icons/IconBadge8kFilled'),
  'badge-ad-filled': () => import('./icons/IconBadgeAdFilled'),
  'badge-ar-filled': () => import('./icons/IconBadgeArFilled'),
  'badge-cc-filled': () => import('./icons/IconBadgeCcFilled'),
  'badge-hd-filled': () => import('./icons/IconBadgeHdFilled'),
  'badge-sd-filled': () => import('./icons/IconBadgeSdFilled'),
  'badge-tm-filled': () => import('./icons/IconBadgeTmFilled'),
  'badge-vo-filled': () => import('./icons/IconBadgeVoFilled'),
  'badge-vr-filled': () => import('./icons/IconBadgeVrFilled'),
  'badge-wc-filled': () => import('./icons/IconBadgeWcFilled'),
  'badge-filled': () => import('./icons/IconBadgeFilled'),
  'badges-filled': () => import('./icons/IconBadgesFilled'),
  'balloon-filled': () => import('./icons/IconBalloonFilled'),
  'ballpen-filled': () => import('./icons/IconBallpenFilled'),
  'bandage-filled': () => import('./icons/IconBandageFilled'),
  'barbell-filled': () => import('./icons/IconBarbellFilled'),
  'barrier-block-filled': () => import('./icons/IconBarrierBlockFilled'),
  'basket-filled': () => import('./icons/IconBasketFilled'),
  'bath-filled': () => import('./icons/IconBathFilled'),
  'battery-1-filled': () => import('./icons/IconBattery1Filled'),
  'battery-2-filled': () => import('./icons/IconBattery2Filled'),
  'battery-3-filled': () => import('./icons/IconBattery3Filled'),
  'battery-4-filled': () => import('./icons/IconBattery4Filled'),
  'battery-automotive-filled': () => import('./icons/IconBatteryAutomotiveFilled'),
  'battery-vertical-1-filled': () => import('./icons/IconBatteryVertical1Filled'),
  'battery-vertical-2-filled': () => import('./icons/IconBatteryVertical2Filled'),
  'battery-vertical-3-filled': () => import('./icons/IconBatteryVertical3Filled'),
  'battery-vertical-4-filled': () => import('./icons/IconBatteryVertical4Filled'),
  'battery-vertical-filled': () => import('./icons/IconBatteryVerticalFilled'),
  'battery-filled': () => import('./icons/IconBatteryFilled'),
  'bed-flat-filled': () => import('./icons/IconBedFlatFilled'),
  'bed-filled': () => import('./icons/IconBedFilled'),
  'beer-filled': () => import('./icons/IconBeerFilled'),
  'bell-minus-filled': () => import('./icons/IconBellMinusFilled'),
  'bell-plus-filled': () => import('./icons/IconBellPlusFilled'),
  'bell-ringing-2-filled': () => import('./icons/IconBellRinging2Filled'),
  'bell-ringing-filled': () => import('./icons/IconBellRingingFilled'),
  'bell-x-filled': () => import('./icons/IconBellXFilled'),
  'bell-z-filled': () => import('./icons/IconBellZFilled'),
  'bell-filled': () => import('./icons/IconBellFilled'),
  'bike-filled': () => import('./icons/IconBikeFilled'),
  'binary-tree-2-filled': () => import('./icons/IconBinaryTree2Filled'),
  'binary-tree-filled': () => import('./icons/IconBinaryTreeFilled'),
  'binoculars-filled': () => import('./icons/IconBinocularsFilled'),
  'biohazard-filled': () => import('./icons/IconBiohazardFilled'),
  'blade-filled': () => import('./icons/IconBladeFilled'),
  'blender-filled': () => import('./icons/IconBlenderFilled'),
  'blob-filled': () => import('./icons/IconBlobFilled'),
  'bolt-filled': () => import('./icons/IconBoltFilled'),
  'bomb-filled': () => import('./icons/IconBombFilled'),
  'bone-filled': () => import('./icons/IconBoneFilled'),
  'bong-filled': () => import('./icons/IconBongFilled'),
  'book-filled': () => import('./icons/IconBookFilled'),
  'bookmark-filled': () => import('./icons/IconBookmarkFilled'),
  'bookmarks-filled': () => import('./icons/IconBookmarksFilled'),
  'boom-filled': () => import('./icons/IconBoomFilled'),
  'bottle-filled': () => import('./icons/IconBottleFilled'),
  'bounce-left-filled': () => import('./icons/IconBounceLeftFilled'),
  'bounce-right-filled': () => import('./icons/IconBounceRightFilled'),
  'bow-filled': () => import('./icons/IconBowFilled'),
  'bowl-chopsticks-filled': () => import('./icons/IconBowlChopsticksFilled'),
  'bowl-spoon-filled': () => import('./icons/IconBowlSpoonFilled'),
  'bowl-filled': () => import('./icons/IconBowlFilled'),
  'box-align-bottom-left-filled': () => import('./icons/IconBoxAlignBottomLeftFilled'),
  'box-align-bottom-right-filled': () => import('./icons/IconBoxAlignBottomRightFilled'),
  'box-align-bottom-filled': () => import('./icons/IconBoxAlignBottomFilled'),
  'box-align-left-filled': () => import('./icons/IconBoxAlignLeftFilled'),
  'box-align-right-filled': () => import('./icons/IconBoxAlignRightFilled'),
  'box-align-top-left-filled': () => import('./icons/IconBoxAlignTopLeftFilled'),
  'box-align-top-right-filled': () => import('./icons/IconBoxAlignTopRightFilled'),
  'box-align-top-filled': () => import('./icons/IconBoxAlignTopFilled'),
  'box-multiple-filled': () => import('./icons/IconBoxMultipleFilled'),
  'brand-apple-filled': () => import('./icons/IconBrandAppleFilled'),
  'brand-discord-filled': () => import('./icons/IconBrandDiscordFilled'),
  'brand-dribbble-filled': () => import('./icons/IconBrandDribbbleFilled'),
  'brand-facebook-filled': () => import('./icons/IconBrandFacebookFilled'),
  'brand-github-filled': () => import('./icons/IconBrandGithubFilled'),
  'brand-google-filled': () => import('./icons/IconBrandGoogleFilled'),
  'brand-patreon-filled': () => import('./icons/IconBrandPatreonFilled'),
  'brand-paypal-filled': () => import('./icons/IconBrandPaypalFilled'),
  'brand-spotify-filled': () => import('./icons/IconBrandSpotifyFilled'),
  'brand-tiktok-filled': () => import('./icons/IconBrandTiktokFilled'),
  'brand-twitter-filled': () => import('./icons/IconBrandTwitterFilled'),
  'brand-x-filled': () => import('./icons/IconBrandXFilled'),
  'brand-youtube-filled': () => import('./icons/IconBrandYoutubeFilled'),
  'bread-filled': () => import('./icons/IconBreadFilled'),
  'briefcase-2-filled': () => import('./icons/IconBriefcase2Filled'),
  'briefcase-filled': () => import('./icons/IconBriefcaseFilled'),
  'brightness-auto-filled': () => import('./icons/IconBrightnessAutoFilled'),
  'brightness-down-filled': () => import('./icons/IconBrightnessDownFilled'),
  'brightness-up-filled': () => import('./icons/IconBrightnessUpFilled'),
  'brightness-filled': () => import('./icons/IconBrightnessFilled'),
  'bubble-filled': () => import('./icons/IconBubbleFilled'),
  'bug-filled': () => import('./icons/IconBugFilled'),
  'building-broadcast-tower-filled': () => import('./icons/IconBuildingBroadcastTowerFilled'),
  'bulb-filled': () => import('./icons/IconBulbFilled'),
  'butterfly-filled': () => import('./icons/IconButterflyFilled'),
  'cactus-filled': () => import('./icons/IconCactusFilled'),
  'calculator-filled': () => import('./icons/IconCalculatorFilled'),
  'calendar-filled': () => import('./icons/IconCalendarFilled'),
  'camera-filled': () => import('./icons/IconCameraFilled'),
  'campfire-filled': () => import('./icons/IconCampfireFilled'),
  'candle-filled': () => import('./icons/IconCandleFilled'),
  'cannabis-filled': () => import('./icons/IconCannabisFilled'),
  'capsule-horizontal-filled': () => import('./icons/IconCapsuleHorizontalFilled'),
  'capsule-filled': () => import('./icons/IconCapsuleFilled'),
  'capture-filled': () => import('./icons/IconCaptureFilled'),
  'car-4wd-filled': () => import('./icons/IconCar4wdFilled'),
  'car-fan-filled': () => import('./icons/IconCarFanFilled'),
  'car-suv-filled': () => import('./icons/IconCarSuvFilled'),
  'car-filled': () => import('./icons/IconCarFilled'),
  'carambola-filled': () => import('./icons/IconCarambolaFilled'),
  'cardboards-filled': () => import('./icons/IconCardboardsFilled'),
  'cards-filled': () => import('./icons/IconCardsFilled'),
  'caret-down-filled': () => import('./icons/IconCaretDownFilled'),
  'caret-left-right-filled': () => import('./icons/IconCaretLeftRightFilled'),
  'caret-left-filled': () => import('./icons/IconCaretLeftFilled'),
  'caret-right-filled': () => import('./icons/IconCaretRightFilled'),
  'caret-up-down-filled': () => import('./icons/IconCaretUpDownFilled'),
  'caret-up-filled': () => import('./icons/IconCaretUpFilled'),
  'carousel-horizontal-filled': () => import('./icons/IconCarouselHorizontalFilled'),
  'carousel-vertical-filled': () => import('./icons/IconCarouselVerticalFilled'),
  'cash-banknote-filled': () => import('./icons/IconCashBanknoteFilled'),
  'category-filled': () => import('./icons/IconCategoryFilled'),
  'charging-pile-filled': () => import('./icons/IconChargingPileFilled'),
  'chart-area-line-filled': () => import('./icons/IconChartAreaLineFilled'),
  'chart-area-filled': () => import('./icons/IconChartAreaFilled'),
  'chart-bubble-filled': () => import('./icons/IconChartBubbleFilled'),
  'chart-candle-filled': () => import('./icons/IconChartCandleFilled'),
  'chart-donut-filled': () => import('./icons/IconChartDonutFilled'),
  'chart-dots-filled': () => import('./icons/IconChartDotsFilled'),
  'chart-grid-dots-filled': () => import('./icons/IconChartGridDotsFilled'),
  'chart-pie-filled': () => import('./icons/IconChartPieFilled'),
  'cherry-filled': () => import('./icons/IconCherryFilled'),
  'chess-bishop-filled': () => import('./icons/IconChessBishopFilled'),
  'chess-king-filled': () => import('./icons/IconChessKingFilled'),
  'chess-knight-filled': () => import('./icons/IconChessKnightFilled'),
  'chess-queen-filled': () => import('./icons/IconChessQueenFilled'),
  'chess-rook-filled': () => import('./icons/IconChessRookFilled'),
  'chess-filled': () => import('./icons/IconChessFilled'),
  'christmas-tree-filled': () => import('./icons/IconChristmasTreeFilled'),
  'circle-arrow-down-left-filled': () => import('./icons/IconCircleArrowDownLeftFilled'),
  'circle-arrow-down-right-filled': () => import('./icons/IconCircleArrowDownRightFilled'),
  'circle-arrow-down-filled': () => import('./icons/IconCircleArrowDownFilled'),
  'circle-arrow-left-filled': () => import('./icons/IconCircleArrowLeftFilled'),
  'circle-arrow-right-filled': () => import('./icons/IconCircleArrowRightFilled'),
  'circle-arrow-up-left-filled': () => import('./icons/IconCircleArrowUpLeftFilled'),
  'circle-arrow-up-right-filled': () => import('./icons/IconCircleArrowUpRightFilled'),
  'circle-arrow-up-filled': () => import('./icons/IconCircleArrowUpFilled'),
  'circle-check-filled': () => import('./icons/IconCircleCheckFilled'),
  'circle-dot-filled': () => import('./icons/IconCircleDotFilled'),
  'circle-key-filled': () => import('./icons/IconCircleKeyFilled'),
  'circle-letter-a-filled': () => import('./icons/IconCircleLetterAFilled'),
  'circle-letter-b-filled': () => import('./icons/IconCircleLetterBFilled'),
  'circle-letter-c-filled': () => import('./icons/IconCircleLetterCFilled'),
  'circle-letter-d-filled': () => import('./icons/IconCircleLetterDFilled'),
  'circle-letter-e-filled': () => import('./icons/IconCircleLetterEFilled'),
  'circle-letter-f-filled': () => import('./icons/IconCircleLetterFFilled'),
  'circle-letter-g-filled': () => import('./icons/IconCircleLetterGFilled'),
  'circle-letter-h-filled': () => import('./icons/IconCircleLetterHFilled'),
  'circle-letter-i-filled': () => import('./icons/IconCircleLetterIFilled'),
  'circle-letter-j-filled': () => import('./icons/IconCircleLetterJFilled'),
  'circle-letter-k-filled': () => import('./icons/IconCircleLetterKFilled'),
  'circle-letter-l-filled': () => import('./icons/IconCircleLetterLFilled'),
  'circle-letter-m-filled': () => import('./icons/IconCircleLetterMFilled'),
  'circle-letter-n-filled': () => import('./icons/IconCircleLetterNFilled'),
  'circle-letter-o-filled': () => import('./icons/IconCircleLetterOFilled'),
  'circle-letter-p-filled': () => import('./icons/IconCircleLetterPFilled'),
  'circle-letter-q-filled': () => import('./icons/IconCircleLetterQFilled'),
  'circle-letter-r-filled': () => import('./icons/IconCircleLetterRFilled'),
  'circle-letter-s-filled': () => import('./icons/IconCircleLetterSFilled'),
  'circle-letter-t-filled': () => import('./icons/IconCircleLetterTFilled'),
  'circle-letter-u-filled': () => import('./icons/IconCircleLetterUFilled'),
  'circle-letter-v-filled': () => import('./icons/IconCircleLetterVFilled'),
  'circle-letter-w-filled': () => import('./icons/IconCircleLetterWFilled'),
  'circle-letter-x-filled': () => import('./icons/IconCircleLetterXFilled'),
  'circle-letter-y-filled': () => import('./icons/IconCircleLetterYFilled'),
  'circle-letter-z-filled': () => import('./icons/IconCircleLetterZFilled'),
  'circle-number-0-filled': () => import('./icons/IconCircleNumber0Filled'),
  'circle-number-1-filled': () => import('./icons/IconCircleNumber1Filled'),
  'circle-number-2-filled': () => import('./icons/IconCircleNumber2Filled'),
  'circle-number-3-filled': () => import('./icons/IconCircleNumber3Filled'),
  'circle-number-4-filled': () => import('./icons/IconCircleNumber4Filled'),
  'circle-number-5-filled': () => import('./icons/IconCircleNumber5Filled'),
  'circle-number-6-filled': () => import('./icons/IconCircleNumber6Filled'),
  'circle-number-7-filled': () => import('./icons/IconCircleNumber7Filled'),
  'circle-number-8-filled': () => import('./icons/IconCircleNumber8Filled'),
  'circle-number-9-filled': () => import('./icons/IconCircleNumber9Filled'),
  'circle-percentage-filled': () => import('./icons/IconCirclePercentageFilled'),
  'circle-plus-filled': () => import('./icons/IconCirclePlusFilled'),
  'circle-rectangle-filled': () => import('./icons/IconCircleRectangleFilled'),
  'circle-x-filled': () => import('./icons/IconCircleXFilled'),
  'circle-filled': () => import('./icons/IconCircleFilled'),
  'circles-filled': () => import('./icons/IconCirclesFilled'),
  'clock-hour-1-filled': () => import('./icons/IconClockHour1Filled'),
  'clock-hour-10-filled': () => import('./icons/IconClockHour10Filled'),
  'clock-hour-11-filled': () => import('./icons/IconClockHour11Filled'),
  'clock-hour-12-filled': () => import('./icons/IconClockHour12Filled'),
  'clock-hour-2-filled': () => import('./icons/IconClockHour2Filled'),
  'clock-hour-3-filled': () => import('./icons/IconClockHour3Filled'),
  'clock-hour-4-filled': () => import('./icons/IconClockHour4Filled'),
  'clock-hour-5-filled': () => import('./icons/IconClockHour5Filled'),
  'clock-hour-6-filled': () => import('./icons/IconClockHour6Filled'),
  'clock-hour-7-filled': () => import('./icons/IconClockHour7Filled'),
  'clock-hour-8-filled': () => import('./icons/IconClockHour8Filled'),
  'clock-hour-9-filled': () => import('./icons/IconClockHour9Filled'),
  'clock-filled': () => import('./icons/IconClockFilled'),
  'cloud-filled': () => import('./icons/IconCloudFilled'),
  'clover-filled': () => import('./icons/IconCloverFilled'),
  'clubs-filled': () => import('./icons/IconClubsFilled'),
  'code-circle-2-filled': () => import('./icons/IconCodeCircle2Filled'),
  'code-circle-filled': () => import('./icons/IconCodeCircleFilled'),
  'coin-bitcoin-filled': () => import('./icons/IconCoinBitcoinFilled'),
  'coin-euro-filled': () => import('./icons/IconCoinEuroFilled'),
  'coin-monero-filled': () => import('./icons/IconCoinMoneroFilled'),
  'coin-pound-filled': () => import('./icons/IconCoinPoundFilled'),
  'coin-rupee-filled': () => import('./icons/IconCoinRupeeFilled'),
  'coin-taka-filled': () => import('./icons/IconCoinTakaFilled'),
  'coin-yen-filled': () => import('./icons/IconCoinYenFilled'),
  'coin-yuan-filled': () => import('./icons/IconCoinYuanFilled'),
  'coin-filled': () => import('./icons/IconCoinFilled'),
  'compass-filled': () => import('./icons/IconCompassFilled'),
  'cone-2-filled': () => import('./icons/IconCone2Filled'),
  'cone-filled': () => import('./icons/IconConeFilled'),
  'contrast-2-filled': () => import('./icons/IconContrast2Filled'),
  'contrast-filled': () => import('./icons/IconContrastFilled'),
  'cookie-man-filled': () => import('./icons/IconCookieManFilled'),
  'cookie-filled': () => import('./icons/IconCookieFilled'),
  'copy-check-filled': () => import('./icons/IconCopyCheckFilled'),
  'copy-minus-filled': () => import('./icons/IconCopyMinusFilled'),
  'copy-plus-filled': () => import('./icons/IconCopyPlusFilled'),
  'copy-x-filled': () => import('./icons/IconCopyXFilled'),
  'copyleft-filled': () => import('./icons/IconCopyleftFilled'),
  'copyright-filled': () => import('./icons/IconCopyrightFilled'),
  'credit-card-filled': () => import('./icons/IconCreditCardFilled'),
  'crop-1-1-filled': () => import('./icons/IconCrop11Filled'),
  'crop-16-9-filled': () => import('./icons/IconCrop169Filled'),
  'crop-3-2-filled': () => import('./icons/IconCrop32Filled'),
  'crop-5-4-filled': () => import('./icons/IconCrop54Filled'),
  'crop-7-5-filled': () => import('./icons/IconCrop75Filled'),
  'crop-landscape-filled': () => import('./icons/IconCropLandscapeFilled'),
  'crop-portrait-filled': () => import('./icons/IconCropPortraitFilled'),
  'cross-filled': () => import('./icons/IconCrossFilled'),
  'dashboard-filled': () => import('./icons/IconDashboardFilled'),
  'device-cctv-filled': () => import('./icons/IconDeviceCctvFilled'),
  'device-desktop-filled': () => import('./icons/IconDeviceDesktopFilled'),
  'device-gamepad-3-filled': () => import('./icons/IconDeviceGamepad3Filled'),
  'device-heart-monitor-filled': () => import('./icons/IconDeviceHeartMonitorFilled'),
  'device-imac-filled': () => import('./icons/IconDeviceImacFilled'),
  'device-ipad-filled': () => import('./icons/IconDeviceIpadFilled'),
  'device-mobile-filled': () => import('./icons/IconDeviceMobileFilled'),
  'device-remote-filled': () => import('./icons/IconDeviceRemoteFilled'),
  'device-speaker-filled': () => import('./icons/IconDeviceSpeakerFilled'),
  'device-tablet-filled': () => import('./icons/IconDeviceTabletFilled'),
  'device-tv-old-filled': () => import('./icons/IconDeviceTvOldFilled'),
  'device-tv-filled': () => import('./icons/IconDeviceTvFilled'),
  'device-unknown-filled': () => import('./icons/IconDeviceUnknownFilled'),
  'device-usb-filled': () => import('./icons/IconDeviceUsbFilled'),
  'device-vision-pro-filled': () => import('./icons/IconDeviceVisionProFilled'),
  'device-watch-filled': () => import('./icons/IconDeviceWatchFilled'),
  'dialpad-filled': () => import('./icons/IconDialpadFilled'),
  'diamond-filled': () => import('./icons/IconDiamondFilled'),
  'diamonds-filled': () => import('./icons/IconDiamondsFilled'),
  'dice-1-filled': () => import('./icons/IconDice1Filled'),
  'dice-2-filled': () => import('./icons/IconDice2Filled'),
  'dice-3-filled': () => import('./icons/IconDice3Filled'),
  'dice-4-filled': () => import('./icons/IconDice4Filled'),
  'dice-5-filled': () => import('./icons/IconDice5Filled'),
  'dice-6-filled': () => import('./icons/IconDice6Filled'),
  'dice-filled': () => import('./icons/IconDiceFilled'),
  'direction-sign-filled': () => import('./icons/IconDirectionSignFilled'),
  'directions-filled': () => import('./icons/IconDirectionsFilled'),
  'disc-filled': () => import('./icons/IconDiscFilled'),
  'discount-filled': () => import('./icons/IconDiscountFilled'),
  'droplet-half-2-filled': () => import('./icons/IconDropletHalf2Filled'),
  'droplet-half-filled': () => import('./icons/IconDropletHalfFilled'),
  'droplet-filled': () => import('./icons/IconDropletFilled'),
  'egg-cracked-filled': () => import('./icons/IconEggCrackedFilled'),
  'egg-filled': () => import('./icons/IconEggFilled'),
  'elevator-filled': () => import('./icons/IconElevatorFilled'),
  'exclamation-circle-filled': () => import('./icons/IconExclamationCircleFilled'),
  'eye-filled': () => import('./icons/IconEyeFilled'),
  'favicon-filled': () => import('./icons/IconFaviconFilled'),
  'feather-filled': () => import('./icons/IconFeatherFilled'),
  'file-x-filled': () => import('./icons/IconFileXFilled'),
  'file-filled': () => import('./icons/IconFileFilled'),
  'filter-filled': () => import('./icons/IconFilterFilled'),
  'flag-2-filled': () => import('./icons/IconFlag2Filled'),
  'flag-3-filled': () => import('./icons/IconFlag3Filled'),
  'flag-filled': () => import('./icons/IconFlagFilled'),
  'flask-2-filled': () => import('./icons/IconFlask2Filled'),
  'flask-filled': () => import('./icons/IconFlaskFilled'),
  'flower-filled': () => import('./icons/IconFlowerFilled'),
  'folder-filled': () => import('./icons/IconFolderFilled'),
  'forbid-2-filled': () => import('./icons/IconForbid2Filled'),
  'forbid-filled': () => import('./icons/IconForbidFilled'),
  'fountain-filled': () => import('./icons/IconFountainFilled'),
  'function-filled': () => import('./icons/IconFunctionFilled'),
  'gauge-filled': () => import('./icons/IconGaugeFilled'),
  'ghost-2-filled': () => import('./icons/IconGhost2Filled'),
  'ghost-filled': () => import('./icons/IconGhostFilled'),
  'gift-card-filled': () => import('./icons/IconGiftCardFilled'),
  'gift-filled': () => import('./icons/IconGiftFilled'),
  'glass-full-filled': () => import('./icons/IconGlassFullFilled'),
  'glass-filled': () => import('./icons/IconGlassFilled'),
  'globe-filled': () => import('./icons/IconGlobeFilled'),
  'gps-filled': () => import('./icons/IconGpsFilled'),
  'graph-filled': () => import('./icons/IconGraphFilled'),
  'guitar-pick-filled': () => import('./icons/IconGuitarPickFilled'),
  'hanger-2-filled': () => import('./icons/IconHanger2Filled'),
  'headphones-filled': () => import('./icons/IconHeadphonesFilled'),
  'heart-filled': () => import('./icons/IconHeartFilled'),
  'help-circle-filled': () => import('./icons/IconHelpCircleFilled'),
  'help-hexagon-filled': () => import('./icons/IconHelpHexagonFilled'),
  'help-octagon-filled': () => import('./icons/IconHelpOctagonFilled'),
  'help-square-rounded-filled': () => import('./icons/IconHelpSquareRoundedFilled'),
  'help-square-filled': () => import('./icons/IconHelpSquareFilled'),
  'help-triangle-filled': () => import('./icons/IconHelpTriangleFilled'),
  'hexagon-letter-a-filled': () => import('./icons/IconHexagonLetterAFilled'),
  'hexagon-letter-b-filled': () => import('./icons/IconHexagonLetterBFilled'),
  'hexagon-letter-c-filled': () => import('./icons/IconHexagonLetterCFilled'),
  'hexagon-letter-d-filled': () => import('./icons/IconHexagonLetterDFilled'),
  'hexagon-letter-e-filled': () => import('./icons/IconHexagonLetterEFilled'),
  'hexagon-letter-f-filled': () => import('./icons/IconHexagonLetterFFilled'),
  'hexagon-letter-g-filled': () => import('./icons/IconHexagonLetterGFilled'),
  'hexagon-letter-h-filled': () => import('./icons/IconHexagonLetterHFilled'),
  'hexagon-letter-i-filled': () => import('./icons/IconHexagonLetterIFilled'),
  'hexagon-letter-j-filled': () => import('./icons/IconHexagonLetterJFilled'),
  'hexagon-letter-k-filled': () => import('./icons/IconHexagonLetterKFilled'),
  'hexagon-letter-l-filled': () => import('./icons/IconHexagonLetterLFilled'),
  'hexagon-letter-m-filled': () => import('./icons/IconHexagonLetterMFilled'),
  'hexagon-letter-n-filled': () => import('./icons/IconHexagonLetterNFilled'),
  'hexagon-letter-o-filled': () => import('./icons/IconHexagonLetterOFilled'),
  'hexagon-letter-p-filled': () => import('./icons/IconHexagonLetterPFilled'),
  'hexagon-letter-q-filled': () => import('./icons/IconHexagonLetterQFilled'),
  'hexagon-letter-r-filled': () => import('./icons/IconHexagonLetterRFilled'),
  'hexagon-letter-s-filled': () => import('./icons/IconHexagonLetterSFilled'),
  'hexagon-letter-t-filled': () => import('./icons/IconHexagonLetterTFilled'),
  'hexagon-letter-u-filled': () => import('./icons/IconHexagonLetterUFilled'),
  'hexagon-letter-v-filled': () => import('./icons/IconHexagonLetterVFilled'),
  'hexagon-letter-w-filled': () => import('./icons/IconHexagonLetterWFilled'),
  'hexagon-letter-x-filled': () => import('./icons/IconHexagonLetterXFilled'),
  'hexagon-letter-y-filled': () => import('./icons/IconHexagonLetterYFilled'),
  'hexagon-letter-z-filled': () => import('./icons/IconHexagonLetterZFilled'),
  'hexagon-minus-filled': () => import('./icons/IconHexagonMinusFilled'),
  'hexagon-number-0-filled': () => import('./icons/IconHexagonNumber0Filled'),
  'hexagon-number-1-filled': () => import('./icons/IconHexagonNumber1Filled'),
  'hexagon-number-2-filled': () => import('./icons/IconHexagonNumber2Filled'),
  'hexagon-number-3-filled': () => import('./icons/IconHexagonNumber3Filled'),
  'hexagon-number-4-filled': () => import('./icons/IconHexagonNumber4Filled'),
  'hexagon-number-5-filled': () => import('./icons/IconHexagonNumber5Filled'),
  'hexagon-number-6-filled': () => import('./icons/IconHexagonNumber6Filled'),
  'hexagon-number-7-filled': () => import('./icons/IconHexagonNumber7Filled'),
  'hexagon-number-8-filled': () => import('./icons/IconHexagonNumber8Filled'),
  'hexagon-number-9-filled': () => import('./icons/IconHexagonNumber9Filled'),
  'hexagon-plus-filled': () => import('./icons/IconHexagonPlusFilled'),
  'hexagon-filled': () => import('./icons/IconHexagonFilled'),
  'home-filled': () => import('./icons/IconHomeFilled'),
  'hospital-circle-filled': () => import('./icons/IconHospitalCircleFilled'),
  'hourglass-filled': () => import('./icons/IconHourglassFilled'),
  'icons-filled': () => import('./icons/IconIconsFilled'),
  'info-circle-filled': () => import('./icons/IconInfoCircleFilled'),
  'info-hexagon-filled': () => import('./icons/IconInfoHexagonFilled'),
  'info-octagon-filled': () => import('./icons/IconInfoOctagonFilled'),
  'info-square-rounded-filled': () => import('./icons/IconInfoSquareRoundedFilled'),
  'info-square-filled': () => import('./icons/IconInfoSquareFilled'),
  'info-triangle-filled': () => import('./icons/IconInfoTriangleFilled'),
  'inner-shadow-bottom-left-filled': () => import('./icons/IconInnerShadowBottomLeftFilled'),
  'inner-shadow-bottom-right-filled': () => import('./icons/IconInnerShadowBottomRightFilled'),
  'inner-shadow-bottom-filled': () => import('./icons/IconInnerShadowBottomFilled'),
  'inner-shadow-left-filled': () => import('./icons/IconInnerShadowLeftFilled'),
  'inner-shadow-right-filled': () => import('./icons/IconInnerShadowRightFilled'),
  'inner-shadow-top-left-filled': () => import('./icons/IconInnerShadowTopLeftFilled'),
  'inner-shadow-top-right-filled': () => import('./icons/IconInnerShadowTopRightFilled'),
  'inner-shadow-top-filled': () => import('./icons/IconInnerShadowTopFilled'),
  'ironing-1-filled': () => import('./icons/IconIroning1Filled'),
  'ironing-2-filled': () => import('./icons/IconIroning2Filled'),
  'ironing-3-filled': () => import('./icons/IconIroning3Filled'),
  'ironing-steam-filled': () => import('./icons/IconIroningSteamFilled'),
  'ironing-filled': () => import('./icons/IconIroningFilled'),
  'jetpack-filled': () => import('./icons/IconJetpackFilled'),
  'jewish-star-filled': () => import('./icons/IconJewishStarFilled'),
  'key-filled': () => import('./icons/IconKeyFilled'),
  'keyframe-align-center-filled': () => import('./icons/IconKeyframeAlignCenterFilled'),
  'keyframe-align-horizontal-filled': () => import('./icons/IconKeyframeAlignHorizontalFilled'),
  'keyframe-align-vertical-filled': () => import('./icons/IconKeyframeAlignVerticalFilled'),
  'keyframe-filled': () => import('./icons/IconKeyframeFilled'),
  'keyframes-filled': () => import('./icons/IconKeyframesFilled'),
  'label-important-filled': () => import('./icons/IconLabelImportantFilled'),
  'label-filled': () => import('./icons/IconLabelFilled'),
  'lasso-polygon-filled': () => import('./icons/IconLassoPolygonFilled'),
  'layout-2-filled': () => import('./icons/IconLayout2Filled'),
  'layout-align-bottom-filled': () => import('./icons/IconLayoutAlignBottomFilled'),
  'layout-align-center-filled': () => import('./icons/IconLayoutAlignCenterFilled'),
  'layout-align-left-filled': () => import('./icons/IconLayoutAlignLeftFilled'),
  'layout-align-middle-filled': () => import('./icons/IconLayoutAlignMiddleFilled'),
  'layout-align-right-filled': () => import('./icons/IconLayoutAlignRightFilled'),
  'layout-align-top-filled': () => import('./icons/IconLayoutAlignTopFilled'),
  'layout-bottombar-collapse-filled': () => import('./icons/IconLayoutBottombarCollapseFilled'),
  'layout-bottombar-expand-filled': () => import('./icons/IconLayoutBottombarExpandFilled'),
  'layout-bottombar-filled': () => import('./icons/IconLayoutBottombarFilled'),
  'layout-cards-filled': () => import('./icons/IconLayoutCardsFilled'),
  'layout-dashboard-filled': () => import('./icons/IconLayoutDashboardFilled'),
  'layout-distribute-horizontal-filled': () => import('./icons/IconLayoutDistributeHorizontalFilled'),
  'layout-distribute-vertical-filled': () => import('./icons/IconLayoutDistributeVerticalFilled'),
  'layout-grid-filled': () => import('./icons/IconLayoutGridFilled'),
  'layout-kanban-filled': () => import('./icons/IconLayoutKanbanFilled'),
  'layout-list-filled': () => import('./icons/IconLayoutListFilled'),
  'layout-navbar-collapse-filled': () => import('./icons/IconLayoutNavbarCollapseFilled'),
  'layout-navbar-expand-filled': () => import('./icons/IconLayoutNavbarExpandFilled'),
  'layout-navbar-filled': () => import('./icons/IconLayoutNavbarFilled'),
  'layout-sidebar-left-collapse-filled': () => import('./icons/IconLayoutSidebarLeftCollapseFilled'),
  'layout-sidebar-left-expand-filled': () => import('./icons/IconLayoutSidebarLeftExpandFilled'),
  'layout-sidebar-right-collapse-filled': () => import('./icons/IconLayoutSidebarRightCollapseFilled'),
  'layout-sidebar-right-expand-filled': () => import('./icons/IconLayoutSidebarRightExpandFilled'),
  'layout-sidebar-right-filled': () => import('./icons/IconLayoutSidebarRightFilled'),
  'layout-sidebar-filled': () => import('./icons/IconLayoutSidebarFilled'),
  'layout-filled': () => import('./icons/IconLayoutFilled'),
  'lego-filled': () => import('./icons/IconLegoFilled'),
  'live-photo-filled': () => import('./icons/IconLivePhotoFilled'),
  'location-filled': () => import('./icons/IconLocationFilled'),
  'lock-square-rounded-filled': () => import('./icons/IconLockSquareRoundedFilled'),
  'lock-filled': () => import('./icons/IconLockFilled'),
  'lungs-filled': () => import('./icons/IconLungsFilled'),
  'macro-filled': () => import('./icons/IconMacroFilled'),
  'magnet-filled': () => import('./icons/IconMagnetFilled'),
  'mail-opened-filled': () => import('./icons/IconMailOpenedFilled'),
  'mail-filled': () => import('./icons/IconMailFilled'),
  'man-filled': () => import('./icons/IconManFilled'),
  'manual-gearbox-filled': () => import('./icons/IconManualGearboxFilled'),
  'map-pin-filled': () => import('./icons/IconMapPinFilled'),
  'medical-cross-filled': () => import('./icons/IconMedicalCrossFilled'),
  'melon-filled': () => import('./icons/IconMelonFilled'),
  'message-chatbot-filled': () => import('./icons/IconMessageChatbotFilled'),
  'message-circle-filled': () => import('./icons/IconMessageCircleFilled'),
  'message-report-filled': () => import('./icons/IconMessageReportFilled'),
  'message-filled': () => import('./icons/IconMessageFilled'),
  'meteor-filled': () => import('./icons/IconMeteorFilled'),
  'michelin-star-filled': () => import('./icons/IconMichelinStarFilled'),
  'mickey-filled': () => import('./icons/IconMickeyFilled'),
  'microphone-filled': () => import('./icons/IconMicrophoneFilled'),
  'microwave-filled': () => import('./icons/IconMicrowaveFilled'),
  'military-rank-filled': () => import('./icons/IconMilitaryRankFilled'),
  'milk-filled': () => import('./icons/IconMilkFilled'),
  'mood-angry-filled': () => import('./icons/IconMoodAngryFilled'),
  'mood-confuzed-filled': () => import('./icons/IconMoodConfuzedFilled'),
  'mood-crazy-happy-filled': () => import('./icons/IconMoodCrazyHappyFilled'),
  'mood-empty-filled': () => import('./icons/IconMoodEmptyFilled'),
  'mood-happy-filled': () => import('./icons/IconMoodHappyFilled'),
  'mood-kid-filled': () => import('./icons/IconMoodKidFilled'),
  'mood-neutral-filled': () => import('./icons/IconMoodNeutralFilled'),
  'mood-sad-filled': () => import('./icons/IconMoodSadFilled'),
  'mood-smile-filled': () => import('./icons/IconMoodSmileFilled'),
  'mood-wrrr-filled': () => import('./icons/IconMoodWrrrFilled'),
  'moon-filled': () => import('./icons/IconMoonFilled'),
  'mountain-filled': () => import('./icons/IconMountainFilled'),
  'mouse-filled': () => import('./icons/IconMouseFilled'),
  'mug-filled': () => import('./icons/IconMugFilled'),
  'mushroom-filled': () => import('./icons/IconMushroomFilled'),
  'navigation-filled': () => import('./icons/IconNavigationFilled'),
  'octagon-filled': () => import('./icons/IconOctagonFilled'),
  'oval-vertical-filled': () => import('./icons/IconOvalVerticalFilled'),
  'oval-filled': () => import('./icons/IconOvalFilled'),
  'paint-filled': () => import('./icons/IconPaintFilled'),
  'panorama-horizontal-filled': () => import('./icons/IconPanoramaHorizontalFilled'),
  'panorama-vertical-filled': () => import('./icons/IconPanoramaVerticalFilled'),
  'parking-circle-filled': () => import('./icons/IconParkingCircleFilled'),
  'paw-filled': () => import('./icons/IconPawFilled'),
  'pennant-2-filled': () => import('./icons/IconPennant2Filled'),
  'pennant-filled': () => import('./icons/IconPennantFilled'),
  'pentagon-filled': () => import('./icons/IconPentagonFilled'),
  'phone-filled': () => import('./icons/IconPhoneFilled'),
  'photo-filled': () => import('./icons/IconPhotoFilled'),
  'picture-in-picture-top-filled': () => import('./icons/IconPictureInPictureTopFilled'),
  'picture-in-picture-filled': () => import('./icons/IconPictureInPictureFilled'),
  'pill-filled': () => import('./icons/IconPillFilled'),
  'pin-filled': () => import('./icons/IconPinFilled'),
  'pinned-filled': () => import('./icons/IconPinnedFilled'),
  'pizza-filled': () => import('./icons/IconPizzaFilled'),
  'play-card-1-filled': () => import('./icons/IconPlayCard1Filled'),
  'play-card-10-filled': () => import('./icons/IconPlayCard10Filled'),
  'play-card-2-filled': () => import('./icons/IconPlayCard2Filled'),
  'play-card-3-filled': () => import('./icons/IconPlayCard3Filled'),
  'play-card-4-filled': () => import('./icons/IconPlayCard4Filled'),
  'play-card-5-filled': () => import('./icons/IconPlayCard5Filled'),
  'play-card-6-filled': () => import('./icons/IconPlayCard6Filled'),
  'play-card-7-filled': () => import('./icons/IconPlayCard7Filled'),
  'play-card-8-filled': () => import('./icons/IconPlayCard8Filled'),
  'play-card-9-filled': () => import('./icons/IconPlayCard9Filled'),
  'play-card-a-filled': () => import('./icons/IconPlayCardAFilled'),
  'play-card-j-filled': () => import('./icons/IconPlayCardJFilled'),
  'play-card-k-filled': () => import('./icons/IconPlayCardKFilled'),
  'play-card-q-filled': () => import('./icons/IconPlayCardQFilled'),
  'play-card-star-filled': () => import('./icons/IconPlayCardStarFilled'),
  'player-eject-filled': () => import('./icons/IconPlayerEjectFilled'),
  'player-pause-filled': () => import('./icons/IconPlayerPauseFilled'),
  'player-play-filled': () => import('./icons/IconPlayerPlayFilled'),
  'player-record-filled': () => import('./icons/IconPlayerRecordFilled'),
  'player-skip-back-filled': () => import('./icons/IconPlayerSkipBackFilled'),
  'player-skip-forward-filled': () => import('./icons/IconPlayerSkipForwardFilled'),
  'player-stop-filled': () => import('./icons/IconPlayerStopFilled'),
  'player-track-next-filled': () => import('./icons/IconPlayerTrackNextFilled'),
  'player-track-prev-filled': () => import('./icons/IconPlayerTrackPrevFilled'),
  'point-filled': () => import('./icons/IconPointFilled'),
  'pointer-filled': () => import('./icons/IconPointerFilled'),
  'polaroid-filled': () => import('./icons/IconPolaroidFilled'),
  'poo-filled': () => import('./icons/IconPooFilled'),
  'presentation-analytics-filled': () => import('./icons/IconPresentationAnalyticsFilled'),
  'presentation-filled': () => import('./icons/IconPresentationFilled'),
  'puzzle-filled': () => import('./icons/IconPuzzleFilled'),
  'radar-filled': () => import('./icons/IconRadarFilled'),
  'radioactive-filled': () => import('./icons/IconRadioactiveFilled'),
  'receipt-filled': () => import('./icons/IconReceiptFilled'),
  'rectangle-vertical-filled': () => import('./icons/IconRectangleVerticalFilled'),
  'rectangle-filled': () => import('./icons/IconRectangleFilled'),
  'relation-many-to-many-filled': () => import('./icons/IconRelationManyToManyFilled'),
  'relation-one-to-many-filled': () => import('./icons/IconRelationOneToManyFilled'),
  'relation-one-to-one-filled': () => import('./icons/IconRelationOneToOneFilled'),
  'replace-filled': () => import('./icons/IconReplaceFilled'),
  'rosette-discount-check-filled': () => import('./icons/IconRosetteDiscountCheckFilled'),
  'rosette-discount-filled': () => import('./icons/IconRosetteDiscountFilled'),
  'rosette-filled': () => import('./icons/IconRosetteFilled'),
  'salad-filled': () => import('./icons/IconSaladFilled'),
  'scuba-diving-tank-filled': () => import('./icons/IconScubaDivingTankFilled'),
  'section-filled': () => import('./icons/IconSectionFilled'),
  'seeding-filled': () => import('./icons/IconSeedingFilled'),
  'settings-filled': () => import('./icons/IconSettingsFilled'),
  'shield-check-filled': () => import('./icons/IconShieldCheckFilled'),
  'shield-checkered-filled': () => import('./icons/IconShieldCheckeredFilled'),
  'shield-half-filled': () => import('./icons/IconShieldHalfFilled'),
  'shield-lock-filled': () => import('./icons/IconShieldLockFilled'),
  'shield-filled': () => import('./icons/IconShieldFilled'),
  'shirt-filled': () => import('./icons/IconShirtFilled'),
  'shopping-cart-filled': () => import('./icons/IconShoppingCartFilled'),
  'sign-left-filled': () => import('./icons/IconSignLeftFilled'),
  'sign-right-filled': () => import('./icons/IconSignRightFilled'),
  'sitemap-filled': () => import('./icons/IconSitemapFilled'),
  'sort-ascending-2-filled': () => import('./icons/IconSortAscending2Filled'),
  'sort-ascending-shapes-filled': () => import('./icons/IconSortAscendingShapesFilled'),
  'sort-descending-2-filled': () => import('./icons/IconSortDescending2Filled'),
  'sort-descending-shapes-filled': () => import('./icons/IconSortDescendingShapesFilled'),
  'soup-filled': () => import('./icons/IconSoupFilled'),
  'spade-filled': () => import('./icons/IconSpadeFilled'),
  'square-arrow-down-filled': () => import('./icons/IconSquareArrowDownFilled'),
  'square-arrow-left-filled': () => import('./icons/IconSquareArrowLeftFilled'),
  'square-arrow-right-filled': () => import('./icons/IconSquareArrowRightFilled'),
  'square-arrow-up-filled': () => import('./icons/IconSquareArrowUpFilled'),
  'square-asterisk-filled': () => import('./icons/IconSquareAsteriskFilled'),
  'square-check-filled': () => import('./icons/IconSquareCheckFilled'),
  'square-chevron-down-filled': () => import('./icons/IconSquareChevronDownFilled'),
  'square-chevron-left-filled': () => import('./icons/IconSquareChevronLeftFilled'),
  'square-chevron-right-filled': () => import('./icons/IconSquareChevronRightFilled'),
  'square-chevron-up-filled': () => import('./icons/IconSquareChevronUpFilled'),
  'square-chevrons-down-filled': () => import('./icons/IconSquareChevronsDownFilled'),
  'square-chevrons-left-filled': () => import('./icons/IconSquareChevronsLeftFilled'),
  'square-chevrons-right-filled': () => import('./icons/IconSquareChevronsRightFilled'),
  'square-chevrons-up-filled': () => import('./icons/IconSquareChevronsUpFilled'),
  'square-dot-filled': () => import('./icons/IconSquareDotFilled'),
  'square-f0-filled': () => import('./icons/IconSquareF0Filled'),
  'square-f1-filled': () => import('./icons/IconSquareF1Filled'),
  'square-f2-filled': () => import('./icons/IconSquareF2Filled'),
  'square-f3-filled': () => import('./icons/IconSquareF3Filled'),
  'square-f4-filled': () => import('./icons/IconSquareF4Filled'),
  'square-f5-filled': () => import('./icons/IconSquareF5Filled'),
  'square-f6-filled': () => import('./icons/IconSquareF6Filled'),
  'square-f7-filled': () => import('./icons/IconSquareF7Filled'),
  'square-f8-filled': () => import('./icons/IconSquareF8Filled'),
  'square-f9-filled': () => import('./icons/IconSquareF9Filled'),
  'square-letter-a-filled': () => import('./icons/IconSquareLetterAFilled'),
  'square-letter-b-filled': () => import('./icons/IconSquareLetterBFilled'),
  'square-letter-c-filled': () => import('./icons/IconSquareLetterCFilled'),
  'square-letter-d-filled': () => import('./icons/IconSquareLetterDFilled'),
  'square-letter-e-filled': () => import('./icons/IconSquareLetterEFilled'),
  'square-letter-f-filled': () => import('./icons/IconSquareLetterFFilled'),
  'square-letter-g-filled': () => import('./icons/IconSquareLetterGFilled'),
  'square-letter-h-filled': () => import('./icons/IconSquareLetterHFilled'),
  'square-letter-i-filled': () => import('./icons/IconSquareLetterIFilled'),
  'square-letter-j-filled': () => import('./icons/IconSquareLetterJFilled'),
  'square-letter-k-filled': () => import('./icons/IconSquareLetterKFilled'),
  'square-letter-l-filled': () => import('./icons/IconSquareLetterLFilled'),
  'square-letter-m-filled': () => import('./icons/IconSquareLetterMFilled'),
  'square-letter-n-filled': () => import('./icons/IconSquareLetterNFilled'),
  'square-letter-o-filled': () => import('./icons/IconSquareLetterOFilled'),
  'square-letter-p-filled': () => import('./icons/IconSquareLetterPFilled'),
  'square-letter-q-filled': () => import('./icons/IconSquareLetterQFilled'),
  'square-letter-r-filled': () => import('./icons/IconSquareLetterRFilled'),
  'square-letter-s-filled': () => import('./icons/IconSquareLetterSFilled'),
  'square-letter-t-filled': () => import('./icons/IconSquareLetterTFilled'),
  'square-letter-u-filled': () => import('./icons/IconSquareLetterUFilled'),
  'square-letter-v-filled': () => import('./icons/IconSquareLetterVFilled'),
  'square-letter-w-filled': () => import('./icons/IconSquareLetterWFilled'),
  'square-letter-x-filled': () => import('./icons/IconSquareLetterXFilled'),
  'square-letter-y-filled': () => import('./icons/IconSquareLetterYFilled'),
  'square-letter-z-filled': () => import('./icons/IconSquareLetterZFilled'),
  'square-minus-filled': () => import('./icons/IconSquareMinusFilled'),
  'square-number-0-filled': () => import('./icons/IconSquareNumber0Filled'),
  'square-number-1-filled': () => import('./icons/IconSquareNumber1Filled'),
  'square-number-2-filled': () => import('./icons/IconSquareNumber2Filled'),
  'square-number-3-filled': () => import('./icons/IconSquareNumber3Filled'),
  'square-number-4-filled': () => import('./icons/IconSquareNumber4Filled'),
  'square-number-5-filled': () => import('./icons/IconSquareNumber5Filled'),
  'square-number-6-filled': () => import('./icons/IconSquareNumber6Filled'),
  'square-number-7-filled': () => import('./icons/IconSquareNumber7Filled'),
  'square-number-8-filled': () => import('./icons/IconSquareNumber8Filled'),
  'square-number-9-filled': () => import('./icons/IconSquareNumber9Filled'),
  'square-rotated-filled': () => import('./icons/IconSquareRotatedFilled'),
  'square-rounded-arrow-down-filled': () => import('./icons/IconSquareRoundedArrowDownFilled'),
  'square-rounded-arrow-left-filled': () => import('./icons/IconSquareRoundedArrowLeftFilled'),
  'square-rounded-arrow-right-filled': () => import('./icons/IconSquareRoundedArrowRightFilled'),
  'square-rounded-arrow-up-filled': () => import('./icons/IconSquareRoundedArrowUpFilled'),
  'square-rounded-check-filled': () => import('./icons/IconSquareRoundedCheckFilled'),
  'square-rounded-chevron-down-filled': () => import('./icons/IconSquareRoundedChevronDownFilled'),
  'square-rounded-chevron-left-filled': () => import('./icons/IconSquareRoundedChevronLeftFilled'),
  'square-rounded-chevron-right-filled': () => import('./icons/IconSquareRoundedChevronRightFilled'),
  'square-rounded-chevron-up-filled': () => import('./icons/IconSquareRoundedChevronUpFilled'),
  'square-rounded-chevrons-down-filled': () => import('./icons/IconSquareRoundedChevronsDownFilled'),
  'square-rounded-chevrons-left-filled': () => import('./icons/IconSquareRoundedChevronsLeftFilled'),
  'square-rounded-chevrons-right-filled': () => import('./icons/IconSquareRoundedChevronsRightFilled'),
  'square-rounded-chevrons-up-filled': () => import('./icons/IconSquareRoundedChevronsUpFilled'),
  'square-rounded-letter-a-filled': () => import('./icons/IconSquareRoundedLetterAFilled'),
  'square-rounded-letter-b-filled': () => import('./icons/IconSquareRoundedLetterBFilled'),
  'square-rounded-letter-c-filled': () => import('./icons/IconSquareRoundedLetterCFilled'),
  'square-rounded-letter-d-filled': () => import('./icons/IconSquareRoundedLetterDFilled'),
  'square-rounded-letter-e-filled': () => import('./icons/IconSquareRoundedLetterEFilled'),
  'square-rounded-letter-f-filled': () => import('./icons/IconSquareRoundedLetterFFilled'),
  'square-rounded-letter-g-filled': () => import('./icons/IconSquareRoundedLetterGFilled'),
  'square-rounded-letter-h-filled': () => import('./icons/IconSquareRoundedLetterHFilled'),
  'square-rounded-letter-i-filled': () => import('./icons/IconSquareRoundedLetterIFilled'),
  'square-rounded-letter-j-filled': () => import('./icons/IconSquareRoundedLetterJFilled'),
  'square-rounded-letter-k-filled': () => import('./icons/IconSquareRoundedLetterKFilled'),
  'square-rounded-letter-l-filled': () => import('./icons/IconSquareRoundedLetterLFilled'),
  'square-rounded-letter-m-filled': () => import('./icons/IconSquareRoundedLetterMFilled'),
  'square-rounded-letter-n-filled': () => import('./icons/IconSquareRoundedLetterNFilled'),
  'square-rounded-letter-o-filled': () => import('./icons/IconSquareRoundedLetterOFilled'),
  'square-rounded-letter-p-filled': () => import('./icons/IconSquareRoundedLetterPFilled'),
  'square-rounded-letter-q-filled': () => import('./icons/IconSquareRoundedLetterQFilled'),
  'square-rounded-letter-r-filled': () => import('./icons/IconSquareRoundedLetterRFilled'),
  'square-rounded-letter-s-filled': () => import('./icons/IconSquareRoundedLetterSFilled'),
  'square-rounded-letter-t-filled': () => import('./icons/IconSquareRoundedLetterTFilled'),
  'square-rounded-letter-u-filled': () => import('./icons/IconSquareRoundedLetterUFilled'),
  'square-rounded-letter-v-filled': () => import('./icons/IconSquareRoundedLetterVFilled'),
  'square-rounded-letter-w-filled': () => import('./icons/IconSquareRoundedLetterWFilled'),
  'square-rounded-letter-x-filled': () => import('./icons/IconSquareRoundedLetterXFilled'),
  'square-rounded-letter-y-filled': () => import('./icons/IconSquareRoundedLetterYFilled'),
  'square-rounded-letter-z-filled': () => import('./icons/IconSquareRoundedLetterZFilled'),
  'square-rounded-minus-filled': () => import('./icons/IconSquareRoundedMinusFilled'),
  'square-rounded-number-0-filled': () => import('./icons/IconSquareRoundedNumber0Filled'),
  'square-rounded-number-1-filled': () => import('./icons/IconSquareRoundedNumber1Filled'),
  'square-rounded-number-2-filled': () => import('./icons/IconSquareRoundedNumber2Filled'),
  'square-rounded-number-3-filled': () => import('./icons/IconSquareRoundedNumber3Filled'),
  'square-rounded-number-4-filled': () => import('./icons/IconSquareRoundedNumber4Filled'),
  'square-rounded-number-5-filled': () => import('./icons/IconSquareRoundedNumber5Filled'),
  'square-rounded-number-6-filled': () => import('./icons/IconSquareRoundedNumber6Filled'),
  'square-rounded-number-7-filled': () => import('./icons/IconSquareRoundedNumber7Filled'),
  'square-rounded-number-8-filled': () => import('./icons/IconSquareRoundedNumber8Filled'),
  'square-rounded-number-9-filled': () => import('./icons/IconSquareRoundedNumber9Filled'),
  'square-rounded-plus-filled': () => import('./icons/IconSquareRoundedPlusFilled'),
  'square-rounded-x-filled': () => import('./icons/IconSquareRoundedXFilled'),
  'square-rounded-filled': () => import('./icons/IconSquareRoundedFilled'),
  'square-x-filled': () => import('./icons/IconSquareXFilled'),
  'square-filled': () => import('./icons/IconSquareFilled'),
  'squares-filled': () => import('./icons/IconSquaresFilled'),
  'stack-2-filled': () => import('./icons/IconStack2Filled'),
  'stack-3-filled': () => import('./icons/IconStack3Filled'),
  'stack-filled': () => import('./icons/IconStackFilled'),
  'star-half-filled': () => import('./icons/IconStarHalfFilled'),
  'star-filled': () => import('./icons/IconStarFilled'),
  'stars-filled': () => import('./icons/IconStarsFilled'),
  'steering-wheel-filled': () => import('./icons/IconSteeringWheelFilled'),
  'sun-filled': () => import('./icons/IconSunFilled'),
  'sunglasses-filled': () => import('./icons/IconSunglassesFilled'),
  'swipe-down-filled': () => import('./icons/IconSwipeDownFilled'),
  'swipe-left-filled': () => import('./icons/IconSwipeLeftFilled'),
  'swipe-right-filled': () => import('./icons/IconSwipeRightFilled'),
  'swipe-up-filled': () => import('./icons/IconSwipeUpFilled'),
  'table-filled': () => import('./icons/IconTableFilled'),
  'tag-filled': () => import('./icons/IconTagFilled'),
  'tags-filled': () => import('./icons/IconTagsFilled'),
  'test-pipe-2-filled': () => import('./icons/IconTestPipe2Filled'),
  'thumb-down-filled': () => import('./icons/IconThumbDownFilled'),
  'thumb-up-filled': () => import('./icons/IconThumbUpFilled'),
  'tilt-shift-filled': () => import('./icons/IconTiltShiftFilled'),
  'timeline-event-filled': () => import('./icons/IconTimelineEventFilled'),
  'toggle-left-filled': () => import('./icons/IconToggleLeftFilled'),
  'toggle-right-filled': () => import('./icons/IconToggleRightFilled'),
  'transform-filled': () => import('./icons/IconTransformFilled'),
  'transition-bottom-filled': () => import('./icons/IconTransitionBottomFilled'),
  'transition-left-filled': () => import('./icons/IconTransitionLeftFilled'),
  'transition-right-filled': () => import('./icons/IconTransitionRightFilled'),
  'transition-top-filled': () => import('./icons/IconTransitionTopFilled'),
  'trash-x-filled': () => import('./icons/IconTrashXFilled'),
  'trash-filled': () => import('./icons/IconTrashFilled'),
  'triangle-inverted-filled': () => import('./icons/IconTriangleInvertedFilled'),
  'triangle-square-circle-filled': () => import('./icons/IconTriangleSquareCircleFilled'),
  'triangle-filled': () => import('./icons/IconTriangleFilled'),
  'trophy-filled': () => import('./icons/IconTrophyFilled'),
  'umbrella-filled': () => import('./icons/IconUmbrellaFilled'),
  'user-filled': () => import('./icons/IconUserFilled'),
  'versions-filled': () => import('./icons/IconVersionsFilled'),
  'windmill-filled': () => import('./icons/IconWindmillFilled'),
  'woman-filled': () => import('./icons/IconWomanFilled'),
  'xbox-a-filled': () => import('./icons/IconXboxAFilled'),
  'xbox-b-filled': () => import('./icons/IconXboxBFilled'),
  'xbox-x-filled': () => import('./icons/IconXboxXFilled'),
  'xbox-y-filled': () => import('./icons/IconXboxYFilled'),
  'yin-yang-filled': () => import('./icons/IconYinYangFilled'),
  'zeppelin-filled': () => import('./icons/IconZeppelinFilled'),
  'zoom-cancel-filled': () => import('./icons/IconZoomCancelFilled'),
  'zoom-check-filled': () => import('./icons/IconZoomCheckFilled'),
  'zoom-code-filled': () => import('./icons/IconZoomCodeFilled'),
  'zoom-exclamation-filled': () => import('./icons/IconZoomExclamationFilled'),
  'zoom-in-area-filled': () => import('./icons/IconZoomInAreaFilled'),
  'zoom-in-filled': () => import('./icons/IconZoomInFilled'),
  'zoom-money-filled': () => import('./icons/IconZoomMoneyFilled'),
  'zoom-out-area-filled': () => import('./icons/IconZoomOutAreaFilled'),
  'zoom-out-filled': () => import('./icons/IconZoomOutFilled'),
  'zoom-pan-filled': () => import('./icons/IconZoomPanFilled'),
  'zoom-question-filled': () => import('./icons/IconZoomQuestionFilled'),
  'zoom-scan-filled': () => import('./icons/IconZoomScanFilled'),
  'zoom-filled': () => import('./icons/IconZoomFilled'),
};
